import React, { useEffect, useState, Fragment} from 'react';
import { consultarModelos } from '../../servicios/modelos/modelo.srv';
import { Grid, Container, Header, Button, Icon, Card } from 'semantic-ui-react';
import SeleccionModelo from '../../components/tarjetas/presentacionModelos/seleccionModelo';
import Preloader from '../../components/utilidades/preloader';

const PresentacionModeloIC = () => {

    const [ modelos, setModelos ] = useState([])
    const [ cargando, setCargando ] = useState(true)
    const consultar = async(activo) => { 
        const respuesta = await consultarModelos({activo})
        if(respuesta.estatus){
            setModelos(respuesta.data)
            setCargando(false)
        } else {
            setCargando(false)
        }
        
    }
    useEffect(() => {
        const activo = true
        consultar(activo)
    }, [])
 
    const [ modeloSeleccionado, setModeloSeleccionado ] = useState(null)

    if( cargando ){
        return <Preloader/>
    }
    return(
        <Grid style={{marginTop:100, marginBottom:50, marginLeft:50, marginRight:50, minHeight:'70vh'}}>
            <Container fluid>
                <Header 
                    size="huge" 
                    as="h1" 
                    style={{ 
                        fontSize:50,
                        spacing:'-0.04em',
                        fontWeight:'400', 
                        fontFamily:'kiaBold', 
                        marginBottom:20, 
                        marginTop:30 }}>
                    Paquetes Prepagados
                    <span>
                        {modeloSeleccionado ? <Button onClick={() => setModeloSeleccionado(null)} size="mini" style={{backgroundColor:'#BB162B', color:'#F6F6F6', borderRadius:25}} floated='right'><Icon name="arrow left"/> Volver </Button> : null }
                    </span>
                </Header>
                {modelos !== undefined && modelos.length> 0 ?
                    <Fragment>
                        <br></br>
                        <Grid columns={3} stackable >
                            {modelos.map((i, index) => (
                                <SeleccionModelo key={index} modelo={i} />
                            ))}
                        </Grid>
                    </Fragment>
                :
                    <Card.Group textAlign="center">
                        <Card
                            style={{minHeight:'70vh'}}
                            fluid
                            color="red"
                        >
                            <Header textAlign="center" as="h1" style={{fontWeight:'bold', fontFamily:'kiaBold', marginBottom:20, marginTop:65}}>
                                No hay modelos disponibles
                            </Header>
                            <Header as="h1" textAlign="center">
                                <Icon size="huge" name="car"/>
                            </Header>
                        </Card>
                    </Card.Group>
                }
            </Container>
        </Grid>
    );
}
 
export default PresentacionModeloIC;