export const estadoInicialTerminosCondiciones = {
    activo: false,
    version: '',
    vigencia: '',
    titulo: '',
    subtitulo: '',
    articulos: []  
}

export const estadoArticulosInicial = {
    titulo: '',
    subtitulo: '',
    contenido: ''
}