import React, { useState, useEffect, Fragment } from 'react'
import { 
    EVENTO_CREAR
} from '../../enum/eventos/tipo-evento'
import { 
    crearUsuario, 
    actualizarUsuario, 
    consultaUsuario,
    eliminarUsuario
} from '../../servicios/usuarios/usuario.srv';
import { consultaConcesionarioPorGrupo } from '../../servicios/concesion/concesiones.srv';
import FiltroUsuarios from '../../components/filtros/filtro.usuarios';
import ItemUsuario from '../../components/itemsListados/item-usuario';
import FormularioUsuarios from '../../components/formularios/formulario.usuarios';
import {Grid, Container, Header, Button, Table, Modal, Loader, Pagination } from 'semantic-ui-react';
import { estadoInicialFiltroUsuarios } from '../../estadosIniciales/usuarios/estadoInicial.usuario';
import ModalMensaje from '../../components/modales/modalMensaje';
import ModalEliminar from '../../components/modales/modalEliminar';
import NoItems from '../../components/utilidades/noItems';
import Preloader from '../../components/utilidades/preloader';


const UsuariosIC = () => {
    const modalEliminarContenido = {
        titulo: 'Eliminar Usuario',
        mensaje: '¿Esta seguro que quiere eliminar este usuario?'
    }
    const [ modalActivo, setModalActivo] = useState(false)
    const [ cargandoLoader, setCargandoLoader ] = useState(true)
    const [ cargando, setCargando ] = useState(false)
    const [ modalEliminar, setModalEliminar ] = useState(false)
    const [ usuarioEliminar, setUsuarioEliminar ] = useState('')
    const [ evento, setEvento ] = useState('')
    const [ usuarios, setUsuarios ] = useState([])
    const [ usuarioSeleccionado, setUsuarioSeleccionado ] = useState({})
    const [ listaConcesionarios, setListaConcesionarios ] = useState([])
    const [ listaGrupos, setListaGrupos ] = useState([])
    const [ modalMensajeEstatus, setModalMensajeEstatus ] = useState({
        activo: false,
        mensaje: ''
    })

    // OTRAS FUNCIONES
    const abrirModal = () => {
        setModalActivo(true)
        setEvento(EVENTO_CREAR)
    }

    const onChangePaginador = (e, {activePage}) => {
        setPaginacion({
            ...paginacion,
            paginaSeleccionada:activePage
        })
        setFiltro({
            ...filtro,
            salto: (activePage - 1) * 10 
        })
    }
    // OBJETO INICIAL DEL FILTRO
    const [ filtro, setFiltro ] = useState(Object.assign({}, estadoInicialFiltroUsuarios ))
    //PETICION A LA LISTA
    const [ paginacion, setPaginacion ] = useState({
        paginasTotales: 1,
        paginaSeleccionada: 1
    })
    useEffect(() => {
        if(filtro){
            consultar(filtro)
        }
        consultaConcesiones()
    }, [filtro])

    const consultaConcesiones = async () => {
        try {
            const concesiones = await consultaConcesionarioPorGrupo({})

            if ( concesiones.estatus ) {
                const prepararLista = []
                const prepararGrupos = []
                concesiones.data.map( i => {

                    prepararGrupos.push({
                        key: i.grupo,
                        text: i.grupo,
                        value: i.grupo
                    })

                    return i.concesiones.map( j => {
                       
                        return prepararLista.push({
                            grupo: i.grupo,
                            key: `${i.grupo} ${j.nombre} ${j.cl}`,
                            text: `${j.nombre} ${j.cl}`,
                            value: j.cl
                        })
                    })
                } )

                setListaConcesionarios( prepararLista )
                setListaGrupos( prepararGrupos )
            } else {

                setModalMensajeEstatus({
                    activo: true,
                    mensaje: concesiones.resultadoOperacion
                })
            }
        } catch ( error ) {
            setModalMensajeEstatus({
                activo: true,
                mensaje: 'Error al consultar concesiones'
            })
        }
        
    }

    const consultar = async ( filtro ) => {
        
        const respuesta  = await consultaUsuario(filtro)
        if(respuesta.estatus){
            setUsuarios(respuesta.data.usuarios)
            setPaginacion({
                ...paginacion,
                paginasTotales: Math.ceil(respuesta.data.totalUsuarios / 10)
            })
            setCargandoLoader(false)
        } else {
            setModalMensajeEstatus({
                activo: true,
                mensaje: respuesta.resultadoOperacion
            })
            setCargandoLoader(false)
        }

    }

    //CRUD
    //CREAR
    const crear = async ( usuario ) => {
        
        const respuesta = await crearUsuario( usuario )
        if ( respuesta.estatus ) {
            setModalMensajeEstatus({
                activo: true,
                mensaje: respuesta.resultadoOperacion
            })
            setModalActivo(false)
            consultar( filtro )
            setEvento('')
        } else {
            setModalMensajeEstatus({
                activo: true,
                mensaje: respuesta.resultadoOperacion
            })
        }
        
    }
    //ACTUALIZAR
    const actualizar = async( usuario ) => {
        const respuesta = await actualizarUsuario(usuario)
        if ( respuesta.estatus ) {
            setModalMensajeEstatus({
                activo: true,
                mensaje: respuesta.resultadoOperacion
            })
            setModalActivo(false)
            consultar( filtro )
            setEvento('')
        } else {
            setModalMensajeEstatus({
                activo: true,
                mensaje: respuesta.resultadoOperacion
            })
        }
    }
    //ELIMINAR
    const eliminarUsuarios = async(id) => {
        const respuesta = await eliminarUsuario({id})
        if(respuesta.estatus){
            setTimeout(() => {
                setModalMensajeEstatus({
                    activo: true,
                    mensaje: respuesta.resultadoOperacion
                })
                setCargando(false)
            }, 500);
            consultar( filtro )
        } else{
            setTimeout(() => {
                setModalMensajeEstatus({
                    activo: true,
                    mensaje: respuesta.resultadoOperacion
                })
                setCargando(false)
            }, 500);
            consultar( filtro )
        }
    }

    if(cargandoLoader){
        return <Preloader/>
    }

    return ( 
        <Grid style={{marginTop:100, marginBottom:50, marginLeft:50, marginRight:50, minHeight:'70vh'}}>
            <Container fluid>
                <Header 
                    size="huge" 
                    as="h1" 
                    style={{ 
                        fontSize:50,
                        spacing:'-0.04em',
                        fontWeight:'400', 
                        fontFamily:'kiaBold', 
                        marginBottom:20, 
                        marginTop:30 }}>
                    Usuarios
                    <span>
                    <Button 
                        size="mini"
                        onClick={() => abrirModal()}
                        content="Nuevo usuario"
                        style={{
                            padding: 20,
                            borderRadius: 0, 
                            backgroundColor:'#FFFFFF',
                            borderColor: '#000000',
                            borderStyle:'solid',
                            borderWidth:'1px',
                            fontWeight: 'bold',
                            color:'#000000'
                        }} 
                        floated='right'
                    />
                    </span>
                </Header>
                <FiltroUsuarios
                    listaConcesionarios={listaConcesionarios}
                    consultarUsuarios={(filtro)=> consultar(filtro)}
                    setFiltro={setFiltro}
                /> 
                <FormularioUsuarios
                    usuarioSeleccionado={usuarioSeleccionado}
                    grupos={listaGrupos}
                    concesionarios={listaConcesionarios}
                    actualizar={(payload) => actualizar( payload )}
                    crear={(payload)=> crear( payload )}
                    modalActivo={modalActivo}
                    setEvento={setEvento}
                    evento={evento}
                    setModalActivo={setModalActivo}
                />
            {usuarios !== undefined && usuarios.length > 0 ?
            <Fragment>
                <Table singleLine>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>NOMBRES</Table.HeaderCell>
                            <Table.HeaderCell>CORREO ELECTRONICO</Table.HeaderCell>
                            <Table.HeaderCell>CARGO</Table.HeaderCell>
                            <Table.HeaderCell>ROL</Table.HeaderCell>
                            <Table.HeaderCell>TALLERES</Table.HeaderCell>
                            <Table.HeaderCell>ACCIONES</Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {usuarios.map(i => (
                            <ItemUsuario
                                setEvento={setEvento}
                                setModalActivo={setModalActivo}
                                setModalEliminar={setModalEliminar}
                                setUsuarioSeleccionado={setUsuarioSeleccionado}
                                setObjetoEliminar={setUsuarioEliminar}
                                evento={evento}
                                usuario={i}
                                key={i._id}
                            />
                        ))}
                    </Table.Body>
                </Table> 
                    <Pagination 
                        ellipsisItem={null}
                        firstItem={null}
                        lastItem={null}
                        totalPages={paginacion.paginasTotales}
                        activePage={paginacion.paginaSeleccionada}
                        onPageChange={onChangePaginador}
                    />
                </Fragment>: <NoItems/> }
                <ModalMensaje
                    activo={modalMensajeEstatus.activo}
                    mensaje={modalMensajeEstatus.mensaje}
                    setModalMensajeEstatus={setModalMensajeEstatus}
                />
                <ModalEliminar
                    modalEliminarActivo={modalEliminar}
                    objetoEliminar={usuarioEliminar}
                    contenido={modalEliminarContenido}
                    eliminar={(value) => eliminarUsuarios(value)}
                    setObjetoEliminar={setUsuarioEliminar}
                    setModalEliminar={setModalEliminar}
                    setCargando={setCargando}
                />
            </Container>
            {
            cargando 
                ? 
            <Modal open>
            <Loader size="large" inverted active/>
            </Modal>
                : 
            null
            }
        </Grid>
     );
}
 
export default UsuariosIC;