export const estadoInicialOperacion = {
    activo: true,
    codigo: '',
    nombre: '',
    horas: '',
    aplicaPara :{
        aireAcondicionado: '',
        cilindrada: '',
        tipoCombustible: '',
        transmision: ''
    },
    repuestos: []
}

export const estadoInicialFiltro = {
    activo: true,
    codigo: "",
    nombre: "",
    aplicaPara: {
        aireAcondicionado: "",
        cilindrada: "",
        tipoCombustible: "",
        transmision: ""
    },
    salto:0,
    limite:10
}