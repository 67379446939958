export const estadoInicialVehiculo = {
    placa: '',
    vin: '',            
    version: '',
    descripcionVersion: '',
    modelo: {
        codigo: '',
        nombre: '',
        origen: '',
        color: '',
        anoModelo: ''
    }
}

export const estadoInicialFiltro = {
    vin: '',
    placa: ''
}