import React, {useState} from 'react';
import { Grid, Loader, Button, Container, Header, Modal, Icon } from 'semantic-ui-react'
import {EVENTO_RETOMAR, EVENTO_ACTUALIZAR } from '../../enum/eventos/tipo-evento';
import { estadoInicialClientes } from '../../estadosIniciales/clientes/estadoInicial-clientes';
import {crearCliente, actualizarCliente, eliminarCliente, consultaIdCliente} from '../../servicios/clientes/clientes.srv';
import ModalMensaje from '../../components/modales/modalMensaje';
import ModalEliminar from '../../components/modales/modalEliminar';
import FormularioClientes from '../../components/formularios/formulario.clientes';
import { Link } from 'react-router-dom';

const ClienteIC = () => {

    const modalEliminarContenido = {
        titulo: 'Eliminar Cliente',
        mensaje: '¿Esta seguro que quiere eliminar este cliente?'
    }
    const [ modalEliminar, setModalEliminar ] = useState(false)
    const [ clienteEliminar, setClienteEliminar ] = useState('')
    const [ datosCliente, setDatosCliente ]  = useState(Object.assign({}, estadoInicialClientes))
    const [ modalActivo, setModalActivo ] = useState(false)
    const [ evento, setEvento ] = useState(EVENTO_RETOMAR)
    const [ id, setId ] = useState('')
    const [ cargando, setCargando ] = useState(false)
    const [ modalMensajeEstatus, setModalMensajeEstatus ] = useState({
        activo: false,
        mensaje: ''
    })

    const consultarporId = async() => {
        const respuesta = await consultaIdCliente({id})
        if(!respuesta.data.estatus && respuesta.data.resultadoOperacion){
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
        } else {
            setEvento(EVENTO_ACTUALIZAR)
            setDatosCliente(respuesta.data.data)
        }
    }

    //CRUD
    //CREAR
    const CrearCliente = async() => {
        const respuesta = await crearCliente(datosCliente)
        if(!respuesta.data.estatus && respuesta.data.resultadoOperacion){
            setId(respuesta.data.data._id)
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
        } else {
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
            setDatosCliente(Object.assign({}, estadoInicialClientes))
            setEvento('')
        }
    }
    //ACTUALIZAR
    const ActualizarCliente = async() => {
        const respuesta = await actualizarCliente(datosCliente)
        if(!respuesta.data.estatus){
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
        } else {
            setDatosCliente(Object.assign({}, estadoInicialClientes))
            setEvento('')
        }
    }
    //ELIMINAR
    const EliminarCliente = async(id) => {
        const respuesta = await eliminarCliente({id})
        if(respuesta.data.estatus){
            setTimeout(() => {
                setModalMensajeEstatus({
                    ...modalMensajeEstatus,
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion
                })
                setCargando(false)
            }, 500);
            setDatosCliente(Object.assign({}, estadoInicialClientes))
        } else{
            setTimeout(() => {
                setModalMensajeEstatus({
                    ...modalMensajeEstatus,
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion
                })
                setCargando(false)
            }, 500);
        }
    }
    return ( 
        <Grid style={{marginTop:100, marginBottom:50, marginLeft:50, marginRight:50, minHeight:'70vh'}}>
            <Container fluid>
                <Header 
                    size="huge" 
                    as="h1" 
                    style={{ 
                        fontSize:50,
                        spacing:'-0.04em',
                        fontWeight:'400', 
                        fontFamily:'kiaBold', 
                        marginBottom:20, 
                        marginTop:30 }}>
                    Crear cliente
                    <span>
                    <Button 
                        size="mini"
                        style={{
                            padding: 20,
                            borderRadius: 0, 
                            backgroundColor:'#FFFFFF',
                            borderColor: '#000000',
                            borderStyle:'solid',
                            borderWidth:'1px',
                            fontWeight: 'bold',
                            color:'#000000'
                        }}
                        floated='right'
                    > 
                        <Link 
                            style={{
                                textDecoration: 'none',
                                borderColor:'#666666',
                                fontFamily: 'kiaBold',
                                color:'#000000'
                            }}
                            primary
                            size="tiny"
                            to={'/nuevaSolicitud'}
                            >  
                                <Icon name="chevron left" />
                                Volver 
                        </Link>
                </Button>
                    </span>
                </Header>
                <FormularioClientes
                    setModalActivo={setModalActivo}
                    guardar={CrearCliente}
                    setObjetoEliminar={setClienteEliminar}
                    setModalEliminar={setModalEliminar}
                    setDatosCliente={setDatosCliente}
                    actualizar={ActualizarCliente}
                    datosCliente={datosCliente}
                    modalActivo={modalActivo}
                    evento={evento}
                />
                <ModalMensaje
                    activo={modalMensajeEstatus.activo}
                    mensaje={modalMensajeEstatus.mensaje}
                    evento={evento}
                    setEvento={setEvento}
                    setModalMensajeEstatus={setModalMensajeEstatus}
                    buscar={consultarporId}
                />
                <ModalEliminar
                    modalEliminarActivo={modalEliminar}
                    objetoEliminar={clienteEliminar}
                    contenido={modalEliminarContenido}
                    eliminar={(value) => EliminarCliente(value)}
                    setObjetoEliminar={setClienteEliminar}
                    setModalEliminar={setModalEliminar}
                    setCargando={setCargando}
                />
            </Container>
            {
            cargando 
                ? 
            <Modal open>
            <Loader size="large" inverted active/>
            </Modal>
                : 
            null
            }
        </Grid>
     );
}
 
export default ClienteIC;
