import React, {useState, useEffect} from 'react';
import {Grid, Container, Header, Button, Table, Loader, Modal, Checkbox } from 'semantic-ui-react';
import { EVENTO_CREAR } from '../../enum/eventos/tipo-evento'
import { consultaTerminos, crearTerminos, actualizarTerminos, eliminarTerminos } from '../../servicios/terminosCondiciones/terminosCondiciones.srv';
import ModalMensaje from '../../components/modales/modalMensaje';
import ModalEliminar from '../../components/modales/modalEliminar';
import FormularioTerminosCondiciones from '../../components/formularios/formulario.terminosCondiciones';
import ItemTerminosCondiciones from '../../components/itemsListados/item-terminosCondiciones';
import NoItems from '../../components/utilidades/noItems';
import Preloader from '../../components/utilidades/preloader';


const TerminosCondicionesIC = () => {
    const [ filtro, setFiltro ] = useState({
        activo: true
    })
    const modalEliminarContenido = {
        titulo: 'Eliminar Termino',
        mensaje: '¿Esta seguro que quiere eliminar este termino?'
    }
    const [ modalMensajeEstatus, setModalMensajeEstatus ] = useState({
        activo: false,
        mensaje: ''
    })
    const [ modalActivo, setModalActivo ] = useState(false)
    const [ cargando, setCargando ] = useState(false)
    const [ cargandoLoader, setCargandoLoader ] = useState(true)
    const [ modalEliminar, setModalEliminar ] = useState(false)
    const [ terminoEliminar, setTerminoEliminar ] = useState('')
    const [ evento, setEvento ] = useState('')
    const [ termino, setTermino ] = useState({})
    const [ terminos, setTerminos ] = useState([])
    //PETICION A LA LISTA
    const manejarFiltro = () => {
        setFiltro({
            activo: !filtro.activo
        })
    }
    const consultar = async(activo) => {
        const respuesta = await consultaTerminos({activo})
        if(respuesta.estatus){
            setTerminos(respuesta.data)
            setCargandoLoader(false)
        } else {
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.resultadoOperacion
            })
            setCargandoLoader(false)
        }
    }
    useEffect(() => {
        if(filtro){
            consultar(filtro.activo)
        }
    }, [filtro])

    //CRUD
    //CREAR
    const CrearTerminos = async(payload) => {
        const respuesta = await crearTerminos(payload)
        if(!respuesta.data.estatus){
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
            consultar(filtro.activo)
        } else {
            setModalActivo(false)
            setEvento('')
            consultar(filtro.activo)
        }
    }
    //ACTUALIZAR
    const ActualizarTerminos = async(payload) => {
        const respuesta = await actualizarTerminos(payload)
        if(!respuesta.data.estatus){
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
            consultar()
        } else{
            setModalActivo(false)
            setEvento('')
            consultar(filtro.activo)
        }
    }
    //ELIMINAR 
    const EliminarTerminos = async(id) => {
        const respuesta = await eliminarTerminos({id})
        if(respuesta.data.estatus){
            setTimeout(() => {
                setModalMensajeEstatus({
                    ...modalMensajeEstatus,
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion
                })
                setCargando(false)
            }, 500);
            consultar(filtro.activo)
        } else{
            setTimeout(() => {
                setModalMensajeEstatus({
                    ...modalMensajeEstatus,
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion
                })
                setCargando(false)
            }, 500);
            consultar(filtro.activo)
        }
    }

    if(cargandoLoader){
        return <Preloader/>
    }
    return ( 
        <Grid style={{marginTop:100, marginBottom:50, marginLeft:50, marginRight:50, minHeight:'70vh'}}>
        <Container fluid>
                <Header 
                    size="huge" 
                    as="h1" 
                    style={{ 
                        fontSize:50,
                        spacing:'-0.04em',
                        fontWeight:'400', 
                        fontFamily:'kiaBold', 
                        marginBottom:20, 
                        marginTop:30 }}>
                    Terminos & condiciones
                    <span>
                    <Button 
                        size="mini"
                        onClick={() => { setEvento(EVENTO_CREAR); setModalActivo(true)}}
                        content="Nuevo terminos & condiciones"
                        style={{
                            padding: 20,
                            borderRadius: 0, 
                            backgroundColor:'#FFFFFF',
                            borderColor: '#000000',
                            borderStyle:'solid',
                            borderWidth:'1px',
                            fontWeight: 'bold',
                            color:'#000000'
                        }} 
                        primary
                        floated='right'
                    />
                    </span>
                </Header>
                <h4>Filtrar por Activos</h4>
                <Checkbox
                    onChange={manejarFiltro}
                    checked={filtro.activo}
                    label={filtro.activo ? "Activos" : "Inactivos"}
                    style={{fontWeight: "bold"}}
                    toggle
                />
                <FormularioTerminosCondiciones
                    termino={termino}
                    evento={evento} 
                    modalActivo={modalActivo}
                    guardar={(payload) => CrearTerminos(payload)}
                    actualizar={(payload) => ActualizarTerminos(payload)}
                    setEvento={setEvento}
                    setModalActivo={setModalActivo}
                />
            {terminos !== undefined && terminos.length>0 ?
             <Table celled padded verticalAlign="top">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>TITULO</Table.HeaderCell>
                        <Table.HeaderCell>SUBTITULO</Table.HeaderCell>
                        <Table.HeaderCell>VERSION</Table.HeaderCell>
                        <Table.HeaderCell>VIGENCIA</Table.HeaderCell>
                        <Table.HeaderCell>ARTICULOS</Table.HeaderCell>
                        <Table.HeaderCell>ACCIONES</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {terminos.map(i => (
                        <ItemTerminosCondiciones
                            setObjetoEliminar={setTerminoEliminar}
                            setModalEliminar={setModalEliminar}
                            setEvento={setEvento}
                            setModalActivo={setModalActivo}
                            setTermino={setTermino}
                            item={i}
                            key={i._id}
                        />
                    ))}
                </Table.Body> 
            </Table>: <NoItems/>}
            <ModalMensaje 
                mensaje={modalMensajeEstatus.mensaje}
                activo={modalMensajeEstatus.activo}
                setModalMensajeEstatus={setModalMensajeEstatus}
            />
            <ModalEliminar
                    modalEliminarActivo={modalEliminar}
                    objetoEliminar={terminoEliminar}
                    contenido={modalEliminarContenido}
                    eliminar={(value) => EliminarTerminos(value)}
                    setObjetoEliminar={setTerminoEliminar}
                    setModalEliminar={setModalEliminar}
                    setCargando={setCargando}
            />
        </Container>
        {
        cargando 
            ? 
        <Modal open>
        <Loader size="large" inverted active/>
        </Modal>
            : 
        null
        }
    </Grid>
    );
}
 
export default TerminosCondicionesIC;