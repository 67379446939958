import React, {Fragment, useEffect, useState} from 'react';
import { useSelector } from 'react-redux'
import VistaPublica from '../vistaPublica/VistaPublica';
import VistaAutenticada from '../vistaAutenticada/VistaAutenticada';

const Main = () => {

    const token = useSelector(state => state.usuario.token)
    const [ autenticado, setAutenticado ] = useState(false)
    useEffect(() => {
        if(token){
            setAutenticado(true)
        } else {
            setAutenticado(false)
        }
    }, [token])
    
     return ( 

        <Fragment>
            <React.StrictMode>
                { autenticado ? <VistaAutenticada/> : <VistaPublica/> }
            </React.StrictMode>
        </Fragment>
        
     );
}
 
export default Main;