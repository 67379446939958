import React, {useState} from 'react'
import {Grid, Segment, Button, Form} from 'semantic-ui-react'

const FiltroConcesiones = ({setFiltro}) => {
    const [ datosFiltrado, setDatosFiltrado ] = useState({
        nombre: '',
        abreviatura: '',
        grupo: '',
        tipo: '',
        cls: [],
        salto: 0,
        limite: 10
    })
    const {nombre, abreviatura, grupo, tipo, cls, salto, limite } = datosFiltrado;
    const [ activo, setActivo ] = useState(true)
    const onChangeDatosFiltrado = e => {
        setDatosFiltrado({
            ...datosFiltrado,
            [e.target.name]: e.target.value
        })
    }
    const buttonActivo = (activo) => {
        if(activo){
            setActivo(false)
        } else {
            setActivo(true)
        }
    }

    const buscarConcesionarios = () => {
         setFiltro({
            nombre,
            abreviatura,
            grupo,
            tipo,
            cls,
            salto,
            limite,
            activo
        })
    }
            

            
    return ( 
        <Grid style={{ marginTop: 5}}>
            <Grid.Column>
                <Segment
                    size="mini"
                >
                    <Form>
                        <h3>Filtrar Por</h3>
                        <Form.Group
                            widths="equal"
                        >
                            <Form.Input
                                label="Nombre"
                                name="nombre"
                                value={datosFiltrado.nombre}
                                onChange={onChangeDatosFiltrado}
                            />
                            <Form.Input
                                label="Abreviatura"
                                name="abreviatura"
                                value={datosFiltrado.abreviatura}
                                onChange={onChangeDatosFiltrado}
                            />
                            <Form.Input
                                label="Grupo"
                                name="grupo"
                                value={datosFiltrado.grupo}
                                onChange={onChangeDatosFiltrado}
                            />
                            <Form.Input
                                label="Tipo"
                                name="tipo"
                                value={datosFiltrado.tipo}
                                onChange={onChangeDatosFiltrado}
                            />
                            <Form.Field>
                                <label>Estado</label>
                                <Form.Checkbox
                                    style={{fontWeight: "bold", marginTop: 10}}
                                    toggle
                                    label={activo ? "Activos" : "Inactivos"}
                                    name="activo"
                                    checked={activo}
                                    onChange={() => buttonActivo(activo)}
                                />
                            </Form.Field>

                        </Form.Group>
                            <span>
                                <Button
                                    style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                                    size="MINI"
                                    icon="search"
                                    content="BUSCAR"
                                    onClick={() => buscarConcesionarios()}
                                />
                            </span>
                    </Form>
                </Segment>
            </Grid.Column>
        </Grid>
     );
}
 
export default FiltroConcesiones;