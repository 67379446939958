import {clienteAxios} from '../../config/axios';
import tokenAuth from '../../config/tokenAuth';

const usuarioAutenticado = () => {
    const token = localStorage.getItem('token')
    if(token){
        tokenAuth(token)
    }
}


export const consultaOperaciones = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/operaciones/consulta', payload)
        return(respuesta.data)
    } catch (error) {
        return({
            estatus: false,
            resultadoOperacion: "Ha ocurrido un problema"
        })
    }
}

export const crearOperaciones = async(payload) => {
    
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/operaciones/crear', payload)
        return(respuesta)
    } catch (error) {
        return({
            data: {
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}

export const actualizarOperaciones = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/operaciones/actualizar', payload)
        
        return(respuesta)
    } catch (error) {
        return({
            data: {
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}

export const eliminarOperaciones = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/operaciones/eliminar', payload)
        return(respuesta)
    } catch (error) {
        return({
            data: {
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}