import React, { Fragment } from 'react';
import moment from 'moment-timezone'
import {Table, Button, Icon, Label, Checkbox} from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import { EVENTO_EDITAR_DESDE_DETALLES, VISTA_SERVICIOS_REDIMIR, VISTA_ACTIVA_REDENCION } from '../../enum/eventos/tipo-evento';

const ItemServicio = ({
    index,
    evento,
    vistaActiva,
    eliminarServicio,
    setObjetoEliminar,
    setDetallesServicio,
    setVerDetallesServicio,
    setModalEliminar,
    item
}) => {

    const botonEliminar = () => {
        setObjetoEliminar(item._id)
        setModalEliminar({
            activo: true
        })
    }
    const verDetalles = () => {
        setVerDetallesServicio(true)
        setDetallesServicio(item)
    }

    const botonCallback = () => {
        if(evento === EVENTO_EDITAR_DESDE_DETALLES) {
            return(
                <Button
                    icon="trash"
                    onClick={() => eliminarServicio(index)}
                    size="tiny"
                />
            )
        } else if ( evento === VISTA_SERVICIOS_REDIMIR) {
            return (
                <Button
                    content="Redimir"
                    style={{
                        padding: 10,
                        borderRadius: 0, 
                        backgroundColor:'#FFFFFF',
                        borderColor: '#000000',
                        borderStyle:'solid',
                        borderWidth:'1px',
                        fontWeight: 'bold',
                        color:'#000000'
                    }}
                />
            )
        } else {
            return(
                <Fragment>
                    <Button
                        style={{backgroundColor:'#BB162B', color:'#F6F6F6', borderRadius:25}}
                        size="tiny"
                    >
                        <Icon name="edit"/>
                        <Link
                            to={`/detallesServicio/${item._id}`}
                            style={{textDecoration:"none", color:'#F6F6F6', fontWeight: "bold"}}
                        >Editar</Link>
                    </Button>
                    <Button
                        icon="trash"
                        onClick={botonEliminar}
                        style={{borderRadius:25}}
                        size="tiny"
                    />
                </Fragment>
            )

        }
    }

    const itemServicio = () => {
        if ( vistaActiva === VISTA_ACTIVA_REDENCION ) {
            return (
                <Table.Row>
                    <Table.Cell> {item.nombre} </Table.Cell>
                    <Table.Cell> {item.descripcion} </Table.Cell>
                    <Table.Cell> {item.tipo} </Table.Cell>
                    <Table.Cell> {item.cantidad} </Table.Cell>
                    <Table.Cell>
                        {item.tarifaManoObraUnificada ? 'Unificada' : 'TMOG'} 
                    </Table.Cell>
                    <Table.Cell> {item.frecuencia.kilometraje} </Table.Cell>
                    <Table.Cell> {item.frecuencia.meses} </Table.Cell>
                    <Table.Cell> 
                        <Checkbox
                            toggle
                            disabled
                            checked={item.vigencia.autorizadoPorImportadora}
                        />
                    </Table.Cell>
                    <Table.Cell> {item.vigencia.kilometraje} </Table.Cell>
                    <Table.Cell> {item.vigencia.meses} </Table.Cell>
                    <Table.Cell> 
                        {
                            item.redencion.estatus ? 
                            'Redimido' : 'Pendiente'
                        }
                    </Table.Cell>
                    <Table.Cell>
                        {   moment(item.redencion.fechaRedencion).isValid() 
                            ? 
                            moment(item.redencion.fechaRedencion).format("DD/MM/YYYY hh:mm a")
                            :
                            'N/A'
                        }
                    </Table.Cell>
                    <Table.Cell>
                        <Button
                            style={{
                                padding: 10,
                                borderRadius: 0, 
                                backgroundColor:'#FFFFFF',
                                borderColor: '#000000',
                                borderStyle:'solid',
                                borderWidth:'1px',
                                fontWeight: 'bold',
                                color:'#000000'
                            }}
                            size="tiny"
                            content="Ver"
                            onClick={verDetalles}
                        />
                    </Table.Cell>
                </Table.Row>
            )
        } else if (evento === VISTA_SERVICIOS_REDIMIR) {
            return (
                <Table.Row>
                    <Table.Cell> {item.nombre} </Table.Cell>
                    <Table.Cell> {item.descripcion} </Table.Cell>
                    <Table.Cell> {item.tipo} </Table.Cell>
                    <Table.Cell> {item.frecuencia.kilometraje} </Table.Cell>
                    <Table.Cell> {item.frecuencia.meses} </Table.Cell>
                    <Table.Cell> {item.vigencia.kilometraje} </Table.Cell>
                    <Table.Cell> {item.vigencia.meses} </Table.Cell>
                    <Table.Cell> 
                        {
                            item.redencion.estatus ? 
                            'Redimido' : 'Pendiente'
                        }
                    </Table.Cell>
                    <Table.Cell>
                        {   moment(item.redencion.fechaRedencion).isValid() 
                            ? 
                            moment(item.redencion.fechaRedencion).format("DD/MM/YYYY hh:mm a")
                            :
                            'N/A'
                        }
                    </Table.Cell>
                    <Table.Cell>
                        <Button
                            style={{
                                padding: 10,
                                borderRadius: 0, 
                                backgroundColor:'#FFFFFF',
                                borderColor: '#000000',
                                borderStyle:'solid',
                                borderWidth:'1px',
                                fontWeight: 'bold',
                                color:'#000000'
                            }}
                            size="tiny"
                            content="Ver"
                            onClick={verDetalles}
                        />
                    </Table.Cell>
                </Table.Row>
            )
        } else  {
            return (
                <Table.Row>
                    <Table.Cell> {item.nombre} </Table.Cell>
                    <Table.Cell> {item.descripcion} </Table.Cell>
                    <Table.Cell> {item.tipo} </Table.Cell>
                    <Table.Cell> {item.modelo.codigo} </Table.Cell>
                    <Table.Cell> { item.modelo.version.map( (i, index) => {
                        return <Label style={{ marginBottom: 5}} key={i+index}>{i}</Label>
                    })
                        } 
                    </Table.Cell>
                    <Table.Cell> {item.frecuencia.kilometraje} </Table.Cell>
                    <Table.Cell> {item.frecuencia.meses} </Table.Cell>
                    <Table.Cell> {item.vigencia.kilometraje} </Table.Cell>
                    <Table.Cell> {item.vigencia.meses} </Table.Cell>
                    <Table.Cell>
                        {botonCallback()}
                    </Table.Cell>
                </Table.Row>
            )
        }
    }

    return(
        itemServicio()
    )
    
}

export default ItemServicio;


