import React, { Fragment } from 'react';
import { Grid, Image, Form, Segment, Button } from 'semantic-ui-react';
import imgBackGround from '../../assets/img/bg/bg.jpg';


const InicioSesionClientesIC = () => {
    return ( 
    <Fragment>
        <Grid  textAlign='left' centered verticalAlign="middle" style={{backgroundColor:"light"}}>
            <Grid.Row width={16} style={{minHeight:"82vh", backgroundImage:`url(${imgBackGround})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}>
                <Grid.Column style={{maxWidth:450}}>
                    <Form size='large'>
                        <Segment size="large" style={{backgroundColor:'transparent'}}>
                                
                                {/* <Image size="huge" src={logo2} style={{ padding: 10}} /> */}
                                <Form.Input 
                                    inverted  
                                    fluid
                                    type="text"
                                    content={'ok'}
                                    iconPosition="right" 
                                    icon="address card outline" name="in" placeholder="Cédula"  />

                                <Form.Input 
                                    fluid 
                                    iconPosition="right" 
                                    icon="lock" 
                                    type="password"
                                    name="id" placeholder="Contraseña" />
                                <Button 
                                    content="Acceder" 
                                    fluid={true}
                                    style={{
                                        padding: 20,
                                        marginBottom:10,
                                        borderRadius: 0, 
                                        backgroundColor:'#000000',
                                        borderColor: '#000000',
                                        borderStyle:'solid',
                                        borderWidth:'1px',
                                        fontWeight: 'bold',
                                        color:'#FFFFFF'
                                    }}
                                    primary />
                                <Button 
                                    content="Aún no tienes una cuenta? Regístrate" 
                                    fluid={true}
                                    style={{
                                        padding: 20,
                                        borderRadius: 0, 
                                        backgroundColor:'transparent',
                                        borderColor: '#ffffff',
                                        borderStyle:'solid',
                                        borderWidth:'1px',
                                        fontWeight: 'bold',
                                        color:'#ffffff'
                                    }}
                                    secondary={true} />
                        </Segment>  
                    </Form>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Fragment>
    );
}
 
export default InicioSesionClientesIC;