import React, {useState, useEffect} from 'react'
import { EVENTO_CREAR } from '../../enum/eventos/tipo-evento'
import {Grid, Container, Header, Button, Table, Loader, Modal, Checkbox } from 'semantic-ui-react';
import { consultarModelos, crearModelos, actualizarModelos, eliminarModelo } from '../../servicios/modelos/modelo.srv';
import FormularioModelos from '../../components/formularios/formulario.modelos'
import ItemModelo from '../../components/itemsListados/item-modelo';
import ModalMensaje from '../../components/modales/modalMensaje';
import ModalEliminar  from '../../components/modales/modalEliminar';
import NoItems from '../../components/utilidades/noItems';
import Preloader from '../../components/utilidades/preloader';


const ModelosIC = () => {

    const modalEliminarContenido = {
        titulo: 'Eliminar Modelo',
        mensaje: '¿Esta seguro que quiere eliminar este modelo?'
    }
    const [ modalMensajeEstatus, setModalMensajeEstatus ] = useState({
        activo: false,
        mensaje: ''
    })
    const [ cargandoLoader, setCargandoLoader ] = useState(true)
    const [ modalActivo, setModalActivo] = useState(false)
    const [ modeloEliminar, setModeloEliminar ] = useState('')
    const [ modelos, setModelos ] = useState([])
    const [ modalEliminar, setModalEliminar ] = useState(false)
    const [ cargando, setCargando ] = useState(false)
    const [ modelo, setModelo ] = useState({})
    const [ evento, setEvento ] = useState('')
    //PETICION A LA LISTA
    const [ filtro, setFiltro ] = useState({
        activo: true
    })
    const buttonActivo = () => {
        setFiltro({
            activo: !filtro.activo
        })
    }
    const consultar = async(activo) => { 
        const respuesta = await consultarModelos({activo})
        if(respuesta.estatus){
            setModelos(respuesta.data)
            setCargandoLoader(false)
        } else {
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
            setCargandoLoader(false)
        }
        
    }

    useEffect(() => {
        if(filtro){
            consultar(filtro.activo)
        }

    }, [filtro])
    //CRUD
    //CREAR
    const CrearModelos = async(payload) => {
        const respuesta = await crearModelos(payload)
        if(!respuesta.data.estatus){
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
            consultar(filtro.activo)
        } else {
            setModalActivo(false)
            setEvento('')
            consultar(filtro.activo)
        }
    }
    //ACTUALIZAR
    const ActualizarModelos = async(payload) => {
        const respuesta = await actualizarModelos(payload)
        if(!respuesta.data.estatus){
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
            consultar(filtro.activo)
        } else {
            setModalActivo(false)
            setEvento('')
            consultar(filtro.activo)
        }
    }
    //ELIMINAR
    const EliminarModelos = async(id) => {
        const respuesta = await eliminarModelo({id})
        if(respuesta.data.estatus){
            setTimeout(() => {
                setModalMensajeEstatus({
                    ...modalMensajeEstatus,
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion
                })
                setCargando(false)
            }, 500);
            consultar(filtro.activo)
        } else{
            setTimeout(() => {
                setModalMensajeEstatus({
                    ...modalMensajeEstatus,
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion
                })
                setCargando(false)
            }, 500);
            consultar(filtro.activo)
        }

    }

    if(cargandoLoader){
        return <Preloader/>
    }

    return ( 
        <Grid style={{marginTop:100, marginBottom:50, marginLeft:50, marginRight:50, minHeight:'70vh'}}>
            <Container fluid>
            <Header 
                    size="huge" 
                    as="h1" 
                    style={{ 
                        fontSize:50,
                        spacing:'-0.04em',
                        fontWeight:'400', 
                        fontFamily:'kiaBold', 
                        marginBottom:20, 
                        marginTop:30 }}>
                    Modelos
                    <span>
                    <Button 
                        size="mini"
                        onClick={() => { setEvento(EVENTO_CREAR); setModalActivo(true)}}
                        content="Nuevo modelo"
                        style={{
                            padding: 20,
                            borderRadius: 0, 
                            backgroundColor:'#FFFFFF',
                            borderColor: '#000000',
                            borderStyle:'solid',
                            borderWidth:'1px',
                            fontWeight: 'bold',
                            color:'#000000'
                        }} 
                        floated='right'
                    />
                    </span>
                </Header>
                <h3>Filtrar por</h3>
                <Checkbox
                    checked={filtro.activo}
                    label="Estado del Modelo"
                    toggle
                    onChange={buttonActivo}
                />
                <FormularioModelos
                    setEvento={setEvento}
                    setModalMensajeEstatus={setModalMensajeEstatus}
                    actualizar={(payload) => ActualizarModelos(payload)}
                    guardar={(payload) => CrearModelos(payload)}
                    setModalActivo={setModalActivo}
                    modelo={modelo}
                    modalActivo={modalActivo}
                    evento={evento}
                />
                {modelos !== undefined && modelos.length>0?
                <Container fluid style={{ overflowX: "scroll", marginTop:20, marginBottom:20 }}>
                 
                 <Table textAlign="center" fixed={true}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>NOMBRE</Table.HeaderCell>
                            <Table.HeaderCell>CODIGO DE MODELO</Table.HeaderCell>
                            <Table.HeaderCell>ORIGEN(s)</Table.HeaderCell>
                            <Table.HeaderCell>AÑO DEL MODELO</Table.HeaderCell>
                            <Table.HeaderCell>VERSIONES</Table.HeaderCell>
                            <Table.HeaderCell>FACTOR MANO DE OBRA</Table.HeaderCell>
                            <Table.HeaderCell>ACCIONES</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    { modelos !== undefined && modelos.length>0 ?<Table.Body>
                        {modelos.map( i => (
                            <ItemModelo
                                setModalEliminar={setModalEliminar}
                                setModalActivo={setModalActivo}
                                setEvento={setEvento}
                                setModelo={setModelo}
                                setObjetoEliminar={setModeloEliminar}
                                item={i}
                                key={i._id}
                            />
                        ))}
                    </Table.Body>: null}
                </Table>
                </Container>:
                <NoItems/>}
                <ModalMensaje
                    activo={modalMensajeEstatus.activo}
                    mensaje={modalMensajeEstatus.mensaje}
                    setModalMensajeEstatus={setModalMensajeEstatus}
                />
                <ModalEliminar
                    modalEliminarActivo={modalEliminar}
                    objetoEliminar={modeloEliminar}
                    contenido={modalEliminarContenido}
                    eliminar={(value) => EliminarModelos(value)}
                    setObjetoEliminar={setModeloEliminar}
                    setModalEliminar={setModalEliminar}
                    setCargando={setCargando}
                />
            </Container>
            {
            cargando 
                ? 
            <Modal open>
            <Loader size="large" inverted active/>
            </Modal>
                : 
            null
            }
        </Grid>
     );
}
 
export default ModelosIC;