import React, { useState, useEffect } from 'react'
import { Modal, Button, Icon, Segment, Table, Container, Progress } from 'semantic-ui-react';
import { InputFile } from 'semantic-ui-react-input-file';
import { csvAJson } from '../../utilidades';
import { tipoCombustibleOpciones } from '../../estadosIniciales/listas/combustible-lista';
import { tipoAireAcondicionadoOpciones } from '../../estadosIniciales/listas/aireAcondicionado-lista';
import { tipoTransmisionOpciones } from '../../estadosIniciales/listas/transmision-lista';
import { crearRepuestos } from '../../servicios/repuestos/repuestos.srv';

const FormularioCargaRepuestos = ({
    modalActivo,
    setModalActivo,
    repuestos,
    setRepuestos,
    setModalMensaje
}) => {

    const [ verInstrucciones, SetVerInstrucciones ] = useState( false )
    const [ totalRegistros, setTotalRegistros ] = useState(0)
    const [ totalRegistrosProcesados, setTotalRegistrosProcesados] = useState(0)
    const [ iniciarCarga, setIniciarCarga ] = useState(false)
    const [ nombreArchivo, setNombreArchivo] = useState('')

    useEffect( () => {
        if (totalRegistros > 0 && iniciarCarga ) {
            cargarRepuestos()
        }
    }, [totalRegistros, iniciarCarga] )
    
    const handleFile = e => {
        if (!e.target.files[0] || e.target.files[0].type !== 'text/csv') {
            setModalMensaje({
                activo: true,
                mensaje: 'No se eligio ningun archivo, recuerde que debe elegir un CSV separado por comas ","'
            })
            setRepuestos([])
            setNombreArchivo('')
            return;
        }
        setNombreArchivo(e.target.files[0].name)
        const lector = new FileReader()
        lector.onload = (e) => {

            let dataJson = csvAJson( lector.result )
            setRepuestos(JSON.parse(dataJson))
            setTotalRegistros(JSON.parse(dataJson).length)
        }
        lector.readAsText(e.target.files[0]);
    }

    const cargarRepuestos = async () => {

        let total = totalRegistros

        if (total === 0 ) {
            setIniciarCarga( false )
            return
        }

        let rep = Object.assign({}, repuestos[total - 1])

        rep.activo = parseInt( rep.activo ) === 1 ? true:false
        rep.iva = parseInt( rep.iva ) === 1 ? true:false
        rep.caracteristicas = {}
        rep.caracteristicas.tipoCombustible = rep.tipoCombustible;
        rep.caracteristicas.aireAcondicionado = rep.aireAcondicionado;
        rep.caracteristicas.transmision = rep.transmision;
        rep.caracteristicas.cilindrada = parseInt(rep.cilindrada);

        delete rep['tipoCombustible'];
        delete rep['aireAcondicionado'];
        delete rep['transmision'];
        delete rep['cilindrada'];

        rep.precioVenta = {}
        rep.precioVenta.publico = parseInt(rep.publico)
        rep.precioVenta.concesionario = parseInt(rep.concesionario)

        delete rep.publico
        delete rep.concesionario

        rep.margen = {}
        rep.margen.precioVentaConcesionario = rep.margenConcesionario ? rep.margenConcesionario: '0';
        rep.margen.precioVentaPublico = rep.margenPublico ? rep.margenPublico: '0';

        delete rep.margenConcesionario;
        delete rep.margenPublico;

        try {
            const respuesta = await crearRepuestos( rep )
            console.log('Respuesta: ', respuesta )
        } catch (error) {   
            console.log('Error: ', error )
        }
        

        setTotalRegistros( total - 1 )
        setTotalRegistrosProcesados( totalRegistrosProcesados + 1 )

    }
    const renderTabla = ( ) => {

        if ( !(repuestos && repuestos.length > 0)) {
            return null
        }
        const headers = Object.keys(repuestos[0])
        return <Container fluid style={{ overflowX: "scroll", marginTop:20, marginBottom:20 }}>
                <Table>
                    <Table.Header>
                        {headers.map( (i, index)=> {
                            return <Table.HeaderCell key={i+index} style={{ textTransform:'uppercase'}}>{i}</Table.HeaderCell>
                        } )}
                        
                    </Table.Header>
                    <Table.Body>
                        {repuestos.map( (i, index) => {
                            if ( index < 5 ) {
                                return <Table.Row keys={i + index}>
                                    {headers.map( (j, jndex) => {
                                        return <Table.Cell key={j+jndex}>
                                            {i[j]}
                                        </Table.Cell>
                                    })}
                                </Table.Row>
                            }
                        } )}
                        
                    </Table.Body>
                </Table>
        </Container>
    }

    const renderIndicaciones = () => {
        const indicaciones = [{
            variable: 'tipoCombustible',
            descripcion: 'Estas son las claves para el campo de tipo de combustible',
            claves: tipoCombustibleOpciones
        },
        {
            variable: 'aireAcondicionado',
            descripcion: 'Estas son las claves para el campo aire acondicionado',
            claves: tipoAireAcondicionadoOpciones 
        },
        {
            variable: 'transmision',
            descripcion: 'Estas son las claves para el campo transmision',
            claves: tipoTransmisionOpciones 
        }
        ]
        return indicaciones.map( (i) => {
            return <p>
                <strong>{i.variable}</strong> <br></br>
                <span>{i.descripcion}</span>
                <ul>
                    {i.claves.map( (j) => {
                        return <li >
                            <strong>{j.value}: </strong>
                            <span>{j.text}</span>
                        </li>
                    } )}
                </ul>
            </p>
        })
    }

    return ( 
        <Modal
            open={modalActivo}
        >
            <Modal.Header>
                <Icon color="red" name="file"/>
                Carga repuestos CSV
            </Modal.Header>
            <Modal.Content>
                <Segment>
                    
                    <p>Por favor elija un documento (CSV)</p>
                    <InputFile
                        button={{label:nombreArchivo? nombreArchivo : 'Elige .csv' , icon:'file',size:'tiny' }}
                        input={{
                            id:'input-upload-id',
                            onChange: handleFile
                        }}
                    />
                    <Button 
                    onClick={()=> SetVerInstrucciones( !verInstrucciones )}  
                    content={verInstrucciones? 'Ocultar instrucciones':'Instrucciones'} 
                    icon="info" 
                    size="tiny" />
                </Segment>
                <Progress size='small' indicating value={totalRegistrosProcesados} total={totalRegistros} />
                {verInstrucciones ? <Segment>
                    <strong>Encabezados Archivo .csv</strong>
                    <p>
                        El archivo debe ser .csv, separado por comas (,) y su estructura debe estar dada de la siguiente forma:
                    </p>
                    <Container fluid style={{ overflowX: "scroll", marginTop:20, marginBottom:20 }}>
                        <Table compact striped celled>
                            <Table.Header>
                                <Table.HeaderCell>modelo</Table.HeaderCell>
                                <Table.HeaderCell>pnc</Table.HeaderCell>
                                <Table.HeaderCell>referencia</Table.HeaderCell>
                                <Table.HeaderCell>descripcion</Table.HeaderCell>
                                <Table.HeaderCell>activo</Table.HeaderCell>
                                <Table.HeaderCell>iva</Table.HeaderCell>
                                <Table.HeaderCell>aireAcondicionado</Table.HeaderCell>
                                <Table.HeaderCell>cilindrada</Table.HeaderCell>
                                <Table.HeaderCell>tipoCombustible</Table.HeaderCell>
                                <Table.HeaderCell>transmision</Table.HeaderCell>
                                <Table.HeaderCell>publico</Table.HeaderCell>
                                <Table.HeaderCell>concesionario</Table.HeaderCell>
                                <Table.HeaderCell>margenPublico</Table.HeaderCell>
                                <Table.HeaderCell>margenConcesionario</Table.HeaderCell>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>JA</Table.Cell>
                                    <Table.Cell>198000</Table.Cell>
                                    <Table.Cell>198000123</Table.Cell>
                                    <Table.Cell>Pastilla</Table.Cell>
                                    <Table.Cell>1: Activo, 0: Inactivo</Table.Cell>
                                    <Table.Cell>1: Se le calcula IVA, 0: No se le calcula IVA</Table.Cell>
                                    <Table.Cell>Descripcion abajo</Table.Cell>
                                    <Table.Cell>Max 2 digitos</Table.Cell>
                                    <Table.Cell>Descripcion abajo</Table.Cell>
                                    <Table.Cell>Descripcion abajo</Table.Cell>
                                    <Table.Cell>Precio publico, sin (,) ni puntos (.)</Table.Cell>
                                    <Table.Cell>Precio concesionario, sin (,) ni puntos (.)</Table.Cell>
                                    <Table.Cell>Valor entero</Table.Cell>
                                    <Table.Cell>Valor entero</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Container>
                    
                    {renderIndicaciones()}
                    
                </Segment>: null}
                
                {renderTabla()}

            </Modal.Content>
            <Modal.Actions>
                    <Button 
                        content="CANCELAR" 
                        icon="times"
                        size='tiny'
                        style={{ borderRadius:25 }}
                        onClick={() => setModalActivo(false)}
                        />
                    <Button 
                        onClick={()=> setIniciarCarga(true)}
                        style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                        size="tiny" 
                        content="cargar" 
                        icon="save"
                    />
            </Modal.Actions>

        </Modal>
     );
}

export default FormularioCargaRepuestos;