import React from 'react';
import { EVENTO_ACTUALIZAR } from '../../enum/eventos/tipo-evento'
import { Table, Button, Label, Popup } from 'semantic-ui-react';

const colors = [
    'red',
    'orange',
    'yellow',
    'olive',
    'green',
    'teal',
    'blue',
    'violet',
    'purple',
    'pink',
    'brown',
    'grey',
    'black',
]

const ItemVersion = ({
    item,
    setVersion,
    setModalActivo,
    setEvento,
    setModalEliminar,
    setObjetoEliminar
}) => {

    const botonActualizar = () => {
        setVersion(item)
        setModalActivo(true)
        setEvento(EVENTO_ACTUALIZAR)
    }

    const botonEliminar = (id) => {
        setObjetoEliminar(id)
        setModalEliminar({
            activo: true
        })
    }

    return (
        <Table.Row>
            <Table.Cell>
                <Label content={item.activo ? 'ACTIVO': 'INACTIVO'} />
            </Table.Cell>
            <Table.Cell> {item.versionControl} </Table.Cell>
            <Table.Cell> 
                { item.campos.map( (i, index) => {
                    return <Popup 
                                key={index}
                                content={i.contenido} 
                                trigger={
                                    <Label color={colors[index]}>{i.caracteres}</Label>
                                } />
                } )}
            </Table.Cell>
            
            <Table.Cell>
                <Button
                    content="EDITAR"
                    icon="edit"
                    style={{backgroundColor:'#BB162B', color:'#F6F6F6', borderRadius:25}}
                    onClick={botonActualizar}
                    size="tiny"
                />
                <Button
                    content="ELIMINAR"
                    icon="trash"
                    style={{borderRadius:25}}
                    size="tiny"
                    onClick={() => botonEliminar(item._id)}
                />
            </Table.Cell>
        </Table.Row>
    )
}

export default ItemVersion