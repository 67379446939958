import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Container, Header, Table, Button, Label } from 'semantic-ui-react';
import { consultaSolicitudRedencion, consultaServicioARedimir } from '../../servicios/solicitudAdquisiciones/solicitud.srv';
import ModalMensaje from '../../components/modales/modalMensaje';
import currencyFormatter from 'currency-formatter';
import ModalDetallesServicio from '../../components/modales/modalDetallesServicios';

const RedimirServiciosIC = ({}) => {
    const [ parametro ] = useState( useParams() )
    const [ valor, setValor ] = useState()
    const [ verDetalleServicio, setVerDetalleServicio ] = useState(false)
    const [ detallesServicio, setDetallesServicio ] = useState();
    const [ idSolicitud, setIdSolicitud ] = useState();
    const [ vehiculo, setVehiculo ] = useState();
    const [ servicios, setServicios ] = useState();
    const [ modalMensajeEstatus, setModalMensajeEstatus ] = useState({
        activo: false,
        mensaje: ''
    })

    useEffect( () => {
        consultarRedencion();
    }, [valor] )

    const consultarRedencion = async() => {
        setModalMensajeEstatus({
            activo: false,
            mensaje: ''
        })
        try {
            const respuesta = await consultaSolicitudRedencion({
                vin: parametro.vin,
                kilometraje: '0'
            })
            
            if( respuesta.data.estatus ) {

                setServicios( respuesta.data.data.servicios);
                setVehiculo( respuesta.data.data.vehiculo );
            } else {
                setModalMensajeEstatus({
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion
                })
            }
        } catch (error) {
            setModalMensajeEstatus({
                activo: true,
                mensaje: "Lo sentimos, no se pudo consultar la información."
            })
        }

    }

    const consultaServicio = async ( idSolicitud, idServicio ) => {
        
        try {
            const respuesta = await consultaServicioARedimir({
                idSolicitud:idSolicitud,
                idServicio: idServicio
            })
            if( respuesta.data.estatus ) {
                setDetallesServicio( respuesta.data.data.servicio);
                setVerDetalleServicio( true );
            } else {
                setModalMensajeEstatus({
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion
                })
            }

        } catch (error) {
            console.log('Error: consultaServicioARedimir', error )
        }
    }

    const abrirRedencion = ( registro ) => {
        setIdSolicitud(registro._id)
        consultaServicio( registro._id, registro.idServicio );
        
    }

    const listadoServicios = () => {
        if (servicios && servicios.length > 0 ) {
            return <Container fluid style={{ overflowX: "scroll", marginTop:20, marginBottom:20 }}>
                    <Table size="small" textAlign="center" celled structured singleLine unstackable selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell rowSpan='2' textAlign='center'>Solicitud</Table.HeaderCell>
                                <Table.HeaderCell colSpan='2' textAlign='center'>Concesionario</Table.HeaderCell>
                                <Table.HeaderCell colSpan='2' textAlign='center'>Frecuencia</Table.HeaderCell>
                                <Table.HeaderCell colSpan='2' textAlign='center'>Vigencia</Table.HeaderCell>
                                <Table.HeaderCell rowSpan='1' colSpan='6' textAlign='center'>Valores</Table.HeaderCell>
                                <Table.HeaderCell rowSpan='2' textAlign='center'>Estado</Table.HeaderCell>
                                <Table.HeaderCell rowSpan='2' textAlign='center'>Acciones</Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                {/* <Table.HeaderCell>Solicitud</Table.HeaderCell> */}
                                <Table.HeaderCell>Grupo</Table.HeaderCell>
                                <Table.HeaderCell>Nombre</Table.HeaderCell>

                                <Table.HeaderCell>Km</Table.HeaderCell>
                                <Table.HeaderCell>Meses</Table.HeaderCell>

                                <Table.HeaderCell>Km</Table.HeaderCell>
                                <Table.HeaderCell>Meses</Table.HeaderCell>

                                <Table.HeaderCell>Insumos</Table.HeaderCell>
                                <Table.HeaderCell>Repuestos</Table.HeaderCell>
                                <Table.HeaderCell>Mano de Obra</Table.HeaderCell>
                                <Table.HeaderCell>Administración</Table.HeaderCell>
                                <Table.HeaderCell>IVA</Table.HeaderCell>
                                <Table.HeaderCell>Total</Table.HeaderCell>

                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {servicios.map( ( i, index ) => {
                                return (
                                    <Table.Row key={i._id}>
                                        <Table.Cell>{i.solicitud.numero}</Table.Cell>
                                        <Table.Cell>{i.concesionario.grupo}</Table.Cell>
                                        <Table.Cell>{i.concesionario.nombre}</Table.Cell>

                                        <Table.Cell>{i.frecuencia_kilometraje}</Table.Cell>
                                        <Table.Cell>{i.frecuencia_meses}</Table.Cell>

                                        <Table.Cell>{i.vigencia_kilometraje}</Table.Cell>
                                        <Table.Cell>{i.vigencia_meses}</Table.Cell>

                                        <Table.Cell>{currencyFormatter.format(i.precios_insumos, { precision:0, thousand:'.', symbol:'$ '})}</Table.Cell>
                                        <Table.Cell>{currencyFormatter.format(i.precios_repuestos, { precision:0, thousand:'.', symbol:'$ '})}</Table.Cell>
                                        <Table.Cell>{currencyFormatter.format(i.precios_mano_obra, { precision:0, thousand:'.', symbol:'$ '})}</Table.Cell>
                                        <Table.Cell>{currencyFormatter.format(i.precios_comision, { precision:0, thousand:'.', symbol:'$ '})}</Table.Cell>
                                        <Table.Cell>{currencyFormatter.format(i.precios_iva, { precision:0, thousand:'.', symbol:'$ '})}</Table.Cell>

                                        <Table.Cell>{currencyFormatter.format(i.precios_valor_total, { precision:0, thousand:'.', symbol:'$ '})}</Table.Cell>
                                        <Table.Cell>
                                            <Label color={ i.redencion_estatus ==='Pendiente'?'green':'blue'}>{i.redencion_estatus}</Label>
                                        </Table.Cell>
                                        <Table.Cell>
                                            { i.redencion_estatus ==='Pendiente' ? 
                                                <Button content="Redimir" onClick={()=> abrirRedencion( i )} />
                                                :
                                                <Button content="Información" onClick={()=> abrirRedencion( i )} />
                                            }
                                        </Table.Cell>
                                        {/* currencyFormatter.format(i.redencion.concesionario.tarifaManoObra, { precision:0, thousand:'.', symbol:'$ '}) */}
                                    </Table.Row>
                                )
                            } )}
                            
                        </Table.Body>
                    </Table>
            </Container>
            
            
        }
    }

    console.log('parametro: ', parametro)

    return (
        <Grid style={{marginTop:100, marginBottom:50, minHeight:'70vh'}} >
            <Container>
                <Header 
                    as="h1"
                    size="huge"
                    style={{ 
                        fontSize:50,
                        spacing:'-0.04em',
                        fontWeight:'bold', 
                        fontFamily:'kiaBold', 
                        marginBottom:20, 
                        marginTop:30 }}> 

                        <Header.Content>
                            Redención de servicios
                        </Header.Content>
                        <Header.Subheader>
                            Vehículo: {vehiculo?.descripcionVersion} <br></br>
                            VIN: {vehiculo?.vin} <br></br>
                            Placa: {vehiculo?.placa}
                        </Header.Subheader>
                </Header>
            </Container>
            <Container>
                <Grid.Row>
                            {listadoServicios()}
                </Grid.Row>
            </Container>

            
 

            <ModalMensaje 
                activo={modalMensajeEstatus.activo}
                mensaje={modalMensajeEstatus.mensaje}
            />
            <ModalDetallesServicio
                verDetallesServicio={verDetalleServicio}
                setVerDetallesServicio={setVerDetalleServicio}
                detallesServicio={detallesServicio}
                consultar={consultarRedencion}
                idSolicitud={{id: idSolicitud}}
             />
        </Grid>

    )
}

export default RedimirServiciosIC