import React from 'react';
import { EVENTO_ACTUALIZAR } from '../../enum/eventos/tipo-evento'
import { Table, Checkbox, Button } from 'semantic-ui-react';

const ItemVariable = ({
    item,
    setVariable,
    setModalActivo,
    setEvento,
    setModalEliminar,
    setObjetoEliminar
}) => {
    
    const datosInicialVariable = {
        _id: item._id,
        nombre: item.nombre,
        descripcion: item.descripcion,
        precedencia: item.precedencia,
        valor: {
            numero: item.valor.numero,
            tipo: item.valor.tipo
        },
        activo: item.activo,
        aplicaPara: item.aplicaPara
    }

    const botonActualizar = () => {
        setVariable(datosInicialVariable)
        setModalActivo(true)
        setEvento(EVENTO_ACTUALIZAR)
    }

    const botonEliminar = (id) => {
        setObjetoEliminar(id)
        setModalEliminar({
            activo: true
        })
    }
    return ( 
        <Table.Row>
            <Table.Cell> {item.nombre} </Table.Cell>
            <Table.Cell> {item.descripcion} </Table.Cell>
            <Table.Cell> {item.valor.numero} </Table.Cell>
            <Table.Cell> {item.valor.tipo} </Table.Cell>
            <Table.Cell> {item.aplicaPara} </Table.Cell>
            <Table.Cell>
                <Checkbox
                    toggle
                    disabled
                    checked={item.activo}
                />
            </Table.Cell>
            <Table.Cell>
                <Button
                    content="EDITAR"
                    icon="edit"
                    style={{backgroundColor:'#BB162B', color:'#F6F6F6', borderRadius:25}}
                    onClick={botonActualizar}
                    size="tiny"
                />
                <Button
                    content="ELIMINAR"
                    icon="trash"
                    style={{borderRadius:25}}
                    size="tiny"
                    onClick={() => botonEliminar(item._id)}
                />
            </Table.Cell>
        </Table.Row>
     );
}
 
export default ItemVariable;