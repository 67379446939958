import React, { useState }  from 'react'
import SolicitudesIC from './SolicitudesIC';
import { useParams } from 'react-router-dom';

const DetallesSolicitudIC = () => {
    // eslint-disable-next-line
    const [ parametro ] = useState( useParams() )

    return(
        <SolicitudesIC
            idUrl={parametro}
        />
    )

}
 
export default DetallesSolicitudIC;