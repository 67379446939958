import React, {useState, useEffect} from 'react';
import { Form, Button, Icon, Modal, Checkbox  } from 'semantic-ui-react';
import {estadoInicialRepuestos} from '../../estadosIniciales/repuestos/estadoInicial-repuestos';
import { tipoAireAcondicionadoOpciones } from '../../estadosIniciales/listas/aireAcondicionado-lista';
import { tipoCombustibleOpciones } from '../../estadosIniciales/listas/combustible-lista';
import { tipoTransmisionOpciones } from '../../estadosIniciales/listas/transmision-lista';
import { EVENTO_ACTUALIZAR } from '../../enum/eventos/tipo-evento';
import Mensaje from '../alertas/alertas.camposObligatorios';



const FormularioRepuestos = ({
    actualizar,
    guardar,
    repuesto,
    evento,
    setEvento,
    modalActivo,
    setModalActivo
}) => {
    
    const [ datosRepuestos, setDatosRepuestos ] = useState(Object.assign({}, estadoInicialRepuestos))
    const [ mensaje, setMensaje ] = useState(false)
    
    useEffect(() => {
        if(evento === EVENTO_ACTUALIZAR){
            setDatosRepuestos(repuesto)
        } else {
            setDatosRepuestos(Object.assign({}, estadoInicialRepuestos))
        }
    }, [evento])

    const onChangeDatos = e => {
        setDatosRepuestos({
            ...datosRepuestos,
            [e.target.name]:e.target.value
        })
    }

    const onChangePrecioVenta = e => {
        
        setDatosRepuestos({
            ...datosRepuestos,
            precioVenta: {
                ...datosRepuestos.precioVenta,
                [e.target.name]: e.target.value
            }
        })
    }
    
    const onChangeMargen = e => {
        setDatosRepuestos({
            ...datosRepuestos,
            margen: {
                ...datosRepuestos.margen,
                [e.target.name]:e.target.value
            }
        })
    }
    const onChangeCaracteristicas = (e, {name, value}) => {
        setDatosRepuestos({
            ...datosRepuestos,
            caracteristicas: {
                ...datosRepuestos.caracteristicas,
                [name]: value
            }
        })
    }

    const onChangeCaracteristicasC = e => {
        const value = parseInt(e.target.value)
        setDatosRepuestos({
            ...datosRepuestos,
            caracteristicas: {
                ...datosRepuestos.caracteristicas,
                [e.target.name]: value
            }
        })
    }
 
    const buttonActivo = () => {
        setDatosRepuestos({
            ...datosRepuestos,
            activo: !datosRepuestos.activo
        })
    }

    const crearRepuestos = (payload) => {
        if(
            datosRepuestos.modelo.trim() === ''||
            datosRepuestos.pnc.trim() === ''||
            datosRepuestos.referencia.trim() === ''||
            datosRepuestos.descripcion.trim() === ''||
            datosRepuestos.caracteristicas.aireAcondicionado.trim() === ''||
            datosRepuestos.caracteristicas.tipoCombustible.trim() === ''||
            datosRepuestos.caracteristicas.transmision.trim() === ''||
            datosRepuestos.caracteristicas.cilindrada === ''||
            datosRepuestos.precioVenta.publico === ''||
            datosRepuestos.precioVenta.concesionario === ''||
            datosRepuestos.margen.precioVentaConcesionario === ''||
            datosRepuestos.margen.precioVentaPublico === ''
        ){
            setMensaje(true)
            return
        }
        guardar(payload)
    }

        const actualizarRepuestos = (payload) => {
        if(
            datosRepuestos.modelo.trim() === ''||
            datosRepuestos.pnc.trim() === ''||
            datosRepuestos.referencia.trim() === ''||
            datosRepuestos.descripcion.trim() === ''||
            datosRepuestos.caracteristicas.aireAcondicionado.trim() === ''||
            datosRepuestos.caracteristicas.tipoCombustible.trim() === ''||
            datosRepuestos.caracteristicas.transmision.trim() === ''||
            datosRepuestos.caracteristicas.cilindrada === ''||
            datosRepuestos.precioVenta.publico === ''||
            datosRepuestos.precioVenta.concesionario === ''||
            datosRepuestos.margen.precioVentaConcesionario === ''||
            datosRepuestos.margen.precioVentaPublico === ''
        ){
            setMensaje(true)
            return
        }
        actualizar(payload)
    }

    const desactivaModal = () => {
        setMensaje(false)
        setModalActivo(false)
        setEvento('')
    }
    return(
        <Modal
            open={modalActivo}
        >
            <Modal.Header> <Icon name="edit" color="red"/> Formulario Repuestos </Modal.Header>
            <Modal.Content>
                <Form>
                    <h3>Datos</h3>
                    <Form.Group
                        widths="equal"
                    >
                        <Form.Input
                            label="Referencia"
                            name="referencia"
                            value={datosRepuestos.referencia}
                            onChange={onChangeDatos}
                        />
                        <Form.Input
                            label="Descripcion"
                            name="descripcion"
                            value={datosRepuestos.descripcion}
                            onChange={onChangeDatos}
                        />
                        <Form.Input
                            label="PNC"
                            name="pnc"
                            value={datosRepuestos.pnc}
                            onChange={onChangeDatos}
                        />
                        <Form.Input
                            maxLength="3"
                            label="Modelo"
                            name="modelo"
                            value={datosRepuestos.modelo}
                            onChange={onChangeDatos}
                        />
                    </Form.Group>
                    <h3>Caracteristicas</h3>
                    <Form.Group
                        widths="equal"
                    >
                        <Form.Select
                            label="Aire Acondicionado"
                            options={tipoAireAcondicionadoOpciones}
                            name="aireAcondicionado"
                            value={datosRepuestos.caracteristicas.aireAcondicionado}
                            onChange={onChangeCaracteristicas}
                        />
                        <Form.Select
                            label="Tipo Combustible"
                            options={tipoCombustibleOpciones}
                            name="tipoCombustible"
                            value={datosRepuestos.caracteristicas.tipoCombustible}
                            onChange={onChangeCaracteristicas}
                        />
                        <Form.Select
                            label="Transmision"
                            options={tipoTransmisionOpciones}
                            name="transmision"
                            value={datosRepuestos.caracteristicas.transmision}
                            onChange={onChangeCaracteristicas}
                        />
                        <Form.Input
                            type="number"
                            minLength="2"
                            maxLength="2"
                            label="Cilindrada"
                            name="cilindrada"
                            value={datosRepuestos.caracteristicas.cilindrada}
                            onChange={onChangeCaracteristicasC}
                        />
                    </Form.Group>
                    <h3>Precio Venta</h3>
                    <Form.Group
                        widths="equal"
                    >
                        <Form.Input
                            type="number"
                            label="Publico"
                            name="publico"
                            value={datosRepuestos.precioVenta.publico}
                            onChange={onChangePrecioVenta}
                        />
                        <Form.Input
                            type="number"
                            label="Concesionario"
                            name="concesionario"
                            value={datosRepuestos.precioVenta.concesionario}
                            onChange={onChangePrecioVenta}
                        />
                    </Form.Group>
                    <h3>Margen</h3>
                    <Form.Group
                        widths="equal"
                    >
                        <Form.Input
                            label="Precio De Venta Concesionario"
                            name="precioVentaConcesionario"
                            value={datosRepuestos.margen.precioVentaConcesionario}
                            onChange={onChangeMargen}
                        />
                        <Form.Input
                            label="Precio De Venta Publico"
                            name="precioVentaPublico"
                            value={datosRepuestos.margen.precioVentaPublico}
                            onChange={onChangeMargen}
                        />
                    </Form.Group>
                    <h3>Activacion Del Repuesto</h3>
                    <Checkbox 
                        label="Si el repuesto esta desactivado no estará disponible" 
                        onChange={() => buttonActivo()}
                        toggle
                        checked={datosRepuestos.activo}
                    />
                </Form>
                {mensaje ? <Mensaje/> : null}
            </Modal.Content>
                <Modal.Actions>
                    <Button 
                        content="CANCELAR" 
                        icon="times"
                        size='tiny'
                        style={{ borderRadius:25 }}
                        onClick={desactivaModal}
                    />
                    {evento === EVENTO_ACTUALIZAR ?
                    <Button 
                        style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                        size="tiny" 
                        content="ACTUALIZAR" 
                        onClick={() => actualizarRepuestos(datosRepuestos)}
                        icon="save"
                    />
                    :
                    <Button 
                        style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                        size="tiny" 
                        content="GUARDAR" 
                        onClick={() => crearRepuestos(datosRepuestos)}
                        icon="save"
                    />
                    }
                </Modal.Actions>
        </Modal>

    )
}

export default FormularioRepuestos;