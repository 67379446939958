import React from 'react';
import {Modal, Button, Header} from 'semantic-ui-react'

const ModalActivacion = ({
    modalActivacion,
    setModalActivacion,
    activar
}) => {

    return ( 
        <Modal
            size="small"
            open={modalActivacion}
            style={{ borderRadius: 0, paddingTop: 20, paddingBottom: 20 }}
        >
            <Modal.Header 
                style={{ 
                    backgroundColor:'#FFFFFF', 
                    border:'none'}} 
                as="h5">
                <Header>
                Activacion
                </Header>
            </Modal.Header>
            <Modal.Content>
                Para la activacion, es necesario que todos los soportes esten revisados
            </Modal.Content>
            <Modal.Actions style={{ backgroundColor:'#FFFFFF', border:'none', textAlign:'left'}}>
                <Button
                    content="Cancelar"
                    onClick={() => setModalActivacion(false)}
                    style={{
                        padding: 20,
                        borderRadius: 0, 
                        backgroundColor:'#FFFFFF',
                        borderColor: '#000000',
                        borderStyle:'solid',
                        borderWidth:'1px',
                        fontWeight: 'bold',
                        color:'#000000'
                    }}
                    primary
                    size="tiny"
                />
                <Button
                    content="Activar"
                    onClick={ ()=> activar() }
                    style={{
                        padding: 20,
                        borderRadius: 0, 
                        backgroundColor:'#000000',
                        borderColor: '#000000',
                        borderStyle:'solid',
                        borderWidth:'1px',
                        fontWeight: 'bold',
                        color:'#FFFFFF'
                    }}
                    primary
                    size="tiny"
                />
            </Modal.Actions>
        </Modal>
    );

}
 
export default ModalActivacion;