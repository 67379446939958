export const TIPO_CONTENIDO_VERSION = {
    MODELO_VEHICULO: 'Modelo',
    ESPACIO_LIBRE: 'Espacio libre',
    GENERACION_VEHICULO: 'Generacion vehiculo',
    CODIGO_EQUIPAMIENTO: 'Codigo equipamiento',
    VERSION_CODIGO_EQUIPAMIENTO: 'Version codigo equipamiento',
    USO_VEHICULO: 'Uso vehiculo',
    TRACCION_VEHICULO: 'Traccion vehiculo',
    NUMERO_PUERTAS: 'Numero de puertas',
    TRANSMISION:	'Transmision',
    MOTOR: 'Motor',
    CILINDRAJE: 'Cilindraje',
    AIRE_ACONDICIONADO: 'Aire acondicionado',
    PREFIJO_FABRICA: 'Prefijo fabrica'
}