export const EVENTO_CREAR = 'EVENTO_CREAR';
export const EVENTO_ACTUALIZAR = 'EVENTO_ACTUALIZAR';
export const EVENTO_VER_DETALLES = 'EVENTO_VER_DETALLES';
export const EVENTO_EDITAR_DESDE_DETALLES = 'EVENTO_EDITAR_DESDE_DETALLES';
export const EVENTO_RETOMAR = 'EVENTO_RETOMAR';
export const EVENTO_NUEVA_SOLICITUD = 'EVENTO_NUEVA_SOLICITUD';
export const EVENTO_CARRITO_COMPRA = 'EVENTO_CARRITO_COMPRA';
export const EVENTO_REEMPLAZAR_VEHICULO = 'EVENTO_REEMPLAZAR_VEHICULO';
export const VISTA_ACTIVA_FORMULARIO = 'VISTA_ACTIVA_FORMULARIO';
export const VISTA_ACTIVA_PAQUETES = 'VISTA_ACTIVA_PAQUETES';
export const VISTA_ACTIVA_ACTIVACION = 'VISTA_ACTIVA_ACTIVACION';
export const VISTA_ACTIVA_REDENCION = 'VISTA_ACTIVA_REDENCION';
export const VISTA_ACTIVA_SOPORTES = 'VISTA_ACTIVA_SOPORTES';
export const VISTA_ACUERDO_SERVICIOS_PREPAGADOS = 'VISTA_ACUERDO_SERVICIOS_PREPAGADOS';
export const VISTA_SERVICIOS_REDIMIR = 'VISTA_SERVICIOS_REDIMIR';
export const INICIAR_SESION = 'INICIAR_SESION';
export const CONSULTA_REDENCION = 'CONSULTA_REDENCION';