import React from 'react';
import { Table, Button, Checkbox } from 'semantic-ui-react';
import {TIPO_ROL} from '../../enum/tipo-rol'
import moment from 'moment-timezone'

const  ItemSoporte = ({
    actualizar,
    datosSolicitud,
    setDatosSolicitud,
    index,
    item
}) => {
    


    const rol = localStorage.getItem('rol')

    const cambiarRevision = () => {
        let soporte = datosSolicitud.soportes
        soporte[index] = {
            ...soporte[index],
            revisado: !soporte[index].revisado
        }
        setDatosSolicitud({
            ...datosSolicitud,
            soportes:soporte
        })
        actualizar(datosSolicitud)
    }
    return ( 
        <Table.Row>
            <Table.Cell> {item.nombre} </Table.Cell>
            <Table.Cell> {item.tipo} </Table.Cell>
            <Table.Cell> {item.comentarios} </Table.Cell>
            <Table.Cell> {moment(item.fechaCarga).format("DD/MM/YYYY hh:mm a")} </Table.Cell>
            <Table.Cell> 
                <Checkbox
                    disabled={rol === TIPO_ROL.ASESOR}
                    toggle
                    onChange={cambiarRevision}
                    checked={item.revisado}
                />
            </Table.Cell>
            <Table.Cell> 
                <Button
                    icon="eye"
                    target="_blank"
                    href={item.url}
                />
            </Table.Cell>
        </Table.Row>
     );
}
 
export default ItemSoporte;