import { TIPO_ROL} from '../../enum/tipo-rol';

const rolUsuario = localStorage.getItem('rol')

let tipoRoles = []

if (rolUsuario !== TIPO_ROL.ADMINISTRADOR_CONCESIONARIO) {
    tipoRoles = [
        { value: '', text: 'Seleccione un rol',  key: 0},
        { value: TIPO_ROL.ADMINISTRADOR_CONCESIONARIO, text: 'Administrador Concesionario',  key: 1},
        { value: TIPO_ROL.ADMINISTRADOR_SISTEMA, text: 'Administrador Sistema', key: 2},
        { value: TIPO_ROL.ASESOR, text: 'Asesor', key: 3 },
        { value: TIPO_ROL.IMPORTADORA, text: 'Importadora', key: 4}
    ]
} else {
    tipoRoles = [
        { value: '', text: 'Seleccione un rol',  key: 0},
        { value: TIPO_ROL.ASESOR, text: 'Asesor', key: 3 }
    ]
}


export const tipoRolOpciones = tipoRoles;

