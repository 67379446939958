import React from 'react';
import { Grid, Segment, Button, Header, Tab, List } from 'semantic-ui-react';
import currencyFormatter from 'currency-formatter'
import {adquirirPaquete} from '../../../actions/carrito.action'
import {useDispatch, useSelector} from 'react-redux'

const SeleccionPaquete = ({
    paquete
}) => {
    const dispatch = useDispatch()

    const carritoDeCompra = useSelector( state  => state.carrito.carritoDeCompra)

    const AgregarPaquete = payload => {
        const exitePaquete = carritoDeCompra.find((paquete) =>
            {if(paquete._id === payload._id){
                return paquete
            }}
        )
        if(exitePaquete){
            return
        }
        dispatch(adquirirPaquete(payload))
    }  
    
    const panes = [
        {
          menuItem: 'Valor',
          render: () => <Tab.Pane attached={false} style={{ border:'none', boxShadow:'none', backgroundColor:'transparent'}}>
              <Header 
                style={{ 
                    fontSize:40,
                    spacing:'-0.04em',
                    fontWeight:'bold', 
                    fontFamily:'kiaBold', 
                    marginBottom:20, 
                    marginTop:20
                    }}>
                  <Header.Content>
                      {currencyFormatter.format(paquete.precios.prepagado.valorFuturo, {code: 'COP', precision: 0}) }
                  </Header.Content>
                  <Header.Subheader>
                      Valor Normal {currencyFormatter.format(paquete.precios.ordinario.valorFuturo, {code: 'COP', precision: 0})}
                  </Header.Subheader>
                  <Header.Subheader>
                      Ahorro { Math.round((1 - (paquete.precios.prepagado.valorFuturo/paquete.precios.ordinario.valorFuturo)) * 100)} %
                  </Header.Subheader>
              </Header>
          </Tab.Pane>,
        },
        {
          menuItem: 'Detalles',
          render: () => <Tab.Pane 
            attached={false} 
            style={{ 
                border:'none', 
                boxShadow:'none', 
                backgroundColor:'transparent'}}>
                    <Header >
                        <Header.Content>
                            Características
                        </Header.Content>
                    </Header>
                    <List>
                        { paquete.detallesServicios.map( (i, index) => ( 
                        <List.Item key={index} style={{ fontFamily:'kiaMedium'}}>
                            {i.nombre} <strong>({i.cantidad})</strong>
                        </List.Item> 
                        )) }
                    </List>
                </Tab.Pane>,
        }
    ]
    return (
        <Grid.Column stretched>
            <Segment.Group basic="true" style={{ border:'none', borderRadius:0, backgroundColor:'rgba(0,0,0,0.05)' }}>
                <Segment style={{letterSpacing:'1px', fontSize:'0.8rem', paddingLeft:30, paddingRight:30, paddingTop:20, paddingBottom:20, border:'none', borderRadius:0, backgroundColor:'rgba(0,0,0,0.1)',  fontFamily:'kiaBold', textTransform:'uppercase' }}>
                    {paquete.nombre}
                </Segment>
                <Segment style={{  paddingLeft:30, paddingRight:30,  border:'none', borderRadius:0, backgroundColor:'transparent' }}>
                    <Tab style={{ borderBottom: 'none'}} menu={{ secondary: true, pointing: true }} panes={panes} />
                </Segment>

                <Segment style={{ marginBottom: 0, paddingLeft:30, paddingRight:30,  border:'none', borderRadius:0, backgroundColor:'transparent' }}>
                    <Button 
                        basic 
                        fluid 
                        style={{
                        padding: 20,
                        borderRadius: 0, 
                        backgroundColor:'transparent',
                        borderColor: '#000000',
                        borderStyle:'solid',
                        borderWidth:'1px',
                        fontWeight: 'bold'
                        }} 
                        content="Agregar a la solicitud" 
                        onClick={() => AgregarPaquete(paquete)}
                    />
                </Segment>
                {/* <Segment style={{ marginBottom: 0, paddingLeft:30, paddingRight:30,  border:'none', borderRadius:0, backgroundColor:'transparent' }}>
                    <a style={{
                        textDecoration: 'none',
                        borderColor:'#666666',
                        borderBottomStyle:'solid',
                        fontFamily: 'kiaBold',
                        color:'#000000'
                    }}>Mas informacion</a>
                </Segment> */}
            </Segment.Group>
        </Grid.Column>
    )
}

export default SeleccionPaquete