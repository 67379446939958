import React from 'react';
import { EVENTO_ACTUALIZAR } from '../../enum/eventos/tipo-evento'
import { Table, Button } from 'semantic-ui-react';

const ItemTempario = ({
    item,
    setTempario,
    setModalActivo,
    setEvento,
    setModalEliminar,
    setObjetoEliminar
}) => {
    
    const estadoInicialTempario = {
        _id: item._id,
        codigoModelo: item.codigoModelo,
        insumos: item.insumos,
        manoObra: item.manoObra,
        nombre: item.nombre,
        cl: item.cl,
        rutinaMantenimiento: item.rutinaMantenimiento
    }

    const botonActualizar = () => {
        setEvento(EVENTO_ACTUALIZAR)
        setModalActivo(true)
        setTempario(estadoInicialTempario)
    }

    const botonEliminar = () => {
        setObjetoEliminar(item._id)
        setModalEliminar(true)
    }
    return ( 
        <Table.Row>
            <Table.Cell> {item.nombre} </Table.Cell>
            <Table.Cell> {item.cl} </Table.Cell>
            <Table.Cell> {item.codigoModelo} </Table.Cell>
            <Table.Cell> {item.insumos} </Table.Cell>
            <Table.Cell> {item.manoObra} </Table.Cell>
            <Table.Cell> {item.rutinaMantenimiento.kilometros} km </Table.Cell>
            <Table.Cell> {item.rutinaMantenimiento.horas} </Table.Cell>
            <Table.Cell textAlign="center">
                <Button
                    icon="edit"
                    style={{backgroundColor:'#BB162B', color:'#F6F6F6'}}
                    size="tiny"
                    onClick={botonActualizar}
                />
                <Button
                    icon="trash"
                    onClick={botonEliminar}
                    size="tiny"
                />
            </Table.Cell>
        </Table.Row>
     );
}
 
export default ItemTempario;