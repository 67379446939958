export const estadoInicialVariableCalculo = {
    nombre: "",
    descripcion: "",
    precedencia: "",
    valor: {
        numero: "",
        tipo: ""
    },
    activo: false,
    aplicaPara: ""
}