import React, {Fragment} from 'react'
import NavBarPublico from '../../components/navbars/NavBarPublico';
import InicioSesionUsuariosIC from '../../vistas/inicioSesion/InicioSesionUsuariosIC';
import InicioSesionClientesIC from '../../vistas/inicioSesion/InicioSesionClientesIC';
import { AcuerdoClienteIC } from '../../vistas/acuerdo/AcuerdoClienteIC';
import FooterComponent from '../../components/footers/FooterComponent';
import { Route, HashRouter, Switch } from 'react-router-dom';


const VistaPublica = () => {
    return ( 
        <Fragment>
            <HashRouter basename="/">
                <NavBarPublico/>
                <Switch>
                    <Route exact path="/HomePublic" component={InicioSesionClientesIC} />
                    <Route exact path="/kiausers" component={InicioSesionUsuariosIC} />
                    <Route exact path="/acuerdo-cliente/:id" component={AcuerdoClienteIC} />
                    <Route exact component={InicioSesionUsuariosIC} />
                    {/* <Route component={InicioSesionClientesIC} /> */}
                </Switch>
                <FooterComponent/>
            </HashRouter>
        </Fragment>
     );
} 
 
export default VistaPublica;