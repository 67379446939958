import React from 'react';
import {Grid, Segment, Header, Item, Button } from 'semantic-ui-react'
import { VISTA_ACUERDO_SERVICIOS_PREPAGADOS, VISTA_SERVICIOS_REDIMIR } from '../../enum/eventos/tipo-evento';

const DatosVehiculos = ({
    datosVehiculos,
    setEditarVehiculo,
    evento
}) => {
    
    if (!datosVehiculos && evento !== VISTA_SERVICIOS_REDIMIR) {
        return <Segment placeholder>
            <Header as="h5" textAlign="center">
                Aún no se ha agregado un vehículo a la solicitud
            </Header>
            <Button
                content="Agregar vehiculo manualmente"
                style={{
                    padding: 20,
                    borderRadius: 0, 
                    backgroundColor:'#FFFFFF',
                    borderColor: '#000000',
                    borderStyle:'solid',
                    borderWidth:'1px',
                    fontWeight: 'bold',
                    color:'#000000'
                }}
                disabled="true"
                size="tiny"
                onClick={() => setEditarVehiculo(true)}
            />
        </Segment>
    } else if (evento === VISTA_SERVICIOS_REDIMIR && !datosVehiculos) {
        return (
            null
        )
    }
    return(
        <Grid.Column>
                {!datosVehiculos  || !datosVehiculos._id ?
                    <Segment placeholder>
                        <Header as="h5" textAlign="center">
                        Aún no se ha agregado un vehículo a la solicitud, <br></br> Para agregarlo escribe el VIN en campo y clic sobre buscar.
                        </Header>
                        { evento !== VISTA_ACUERDO_SERVICIOS_PREPAGADOS ?
                                <Button
                                content="Agregar vehículo manualmente"
                                style={{
                                    padding: 20,
                                    borderRadius: 0, 
                                    backgroundColor:'#FFFFFF',
                                    borderColor: '#000000',
                                    borderStyle:'solid',
                                    borderWidth:'1px',
                                    fontWeight: 'bold',
                                    color:'#000000'
                                }}
                                size="tiny"
                                onClick={() => setEditarVehiculo(true)}
                            />
                        : null}
                    </Segment>
                    :
                    null
                }
            {datosVehiculos && datosVehiculos.modelo?<Segment basic >
                <Header as="h3">Datos del Vehículo</Header>
                <Grid columns={2} stackable>
                    <Grid.Column>
                        <Item.Content>
                            <small>Placa</small>
                            <Item.Meta>
                               <label style={{fontWeight: "bold"}}> {datosVehiculos.placa} </label> 
                            </Item.Meta>
                        </Item.Content>
                    </Grid.Column>
                    <Grid.Column>
                        <Item.Content>
                            <small>Vin</small>
                            <Item.Meta>
                               <label style={{fontWeight: "bold"}}> {datosVehiculos.vin} </label> 
                            </Item.Meta>
                        </Item.Content>
                    </Grid.Column>
                    <Grid.Column>
                        <Item.Content>
                            <small>Versión</small>
                            <Item.Meta>
                               <label style={{fontWeight: "bold"}}> {datosVehiculos.version} </label> 
                            </Item.Meta>
                        </Item.Content>
                    </Grid.Column>
                    <Grid.Column>
                        <Item.Content>
                            <small>Descripción</small>
                            <Item.Meta>
                               <label style={{fontWeight: "bold"}}> {datosVehiculos.descripcionVersion} </label> 
                            </Item.Meta>
                        </Item.Content>
                    </Grid.Column>
                    <Grid.Column>
                        <Item.Content>
                            <small>Código de Modelo</small>
                            <Item.Meta>
                               <label style={{fontWeight: "bold"}}> {datosVehiculos.modelo.codigo} </label> 
                            </Item.Meta>
                        </Item.Content>
                    </Grid.Column>
                    <Grid.Column>
                        <Item.Content>    
                            <small >Nombre</small>
                            <Item.Meta>
                                <label style={{fontWeight: "bold"}}> {datosVehiculos.modelo.nombre} </label> 
                            </Item.Meta>
                        </Item.Content>
                    </Grid.Column>
                    <Grid.Column>
                        <Item.Content>    
                            <small >Año del modelo</small>
                            <Item.Meta>
                                <label style={{fontWeight: "bold"}}> {datosVehiculos.modelo.anoModelo} </label> 
                            </Item.Meta>
                        </Item.Content>
                    </Grid.Column>                    
                    <Grid.Column>
                        <Item.Content>    
                            <small >Origen</small>
                            <Item.Meta>
                                <label style={{fontWeight: "bold"}}> {datosVehiculos.modelo.origen} </label> 
                            </Item.Meta>
                        </Item.Content>
                    </Grid.Column>
                    <Grid.Column>
                        {evento !== VISTA_ACUERDO_SERVICIOS_PREPAGADOS || evento === VISTA_SERVICIOS_REDIMIR ? <Button
                            content="Editar vehiculo"
                            style={{
                                padding: 20,
                                borderRadius: 0, 
                                backgroundColor:'#FFFFFF',
                                borderColor: '#000000',
                                borderStyle:'solid',
                                borderWidth:'1px',
                                fontWeight: 'bold',
                                color:'#000000'
                            }}
                            onClick={() => setEditarVehiculo(true)}
                            primary
                            size="tiny"
                        />: null }
                    </Grid.Column>
                </Grid>
            </Segment>: null}
        </Grid.Column>
    )
}

export default DatosVehiculos;