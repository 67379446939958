import React, {useState, Fragment, useEffect} from 'react'
import { EVENTO_ACTUALIZAR } from '../../enum/eventos/tipo-evento'
import { Form, Button, Icon, Modal, TextArea, Grid, Checkbox } from 'semantic-ui-react';
import {estadoInicialTerminosCondiciones, estadoArticulosInicial} from '../../estadosIniciales/terminosCondiciones/estadoInicial-terminosCondiciones';
import Mensaje from '../alertas/alertas.camposObligatorios';

const FormularioTerminosCondiciones = ({
    termino,
    evento,
    setEvento,
    modalActivo,
    setModalActivo,
    guardar,
    actualizar
}) => {

    const [ datosTerminosCondiciones, setDatosTerminosCondiciones ] = useState(Object.assign({}, estadoInicialTerminosCondiciones))
    const [ mensaje, setMensaje ] = useState(false)
    const [ articulos, setArticulos ] = useState( Object.assign({}, estadoArticulosInicial))


    useEffect(() => {
        if(evento === EVENTO_ACTUALIZAR){
            setDatosTerminosCondiciones(termino)
        } else {
            setDatosTerminosCondiciones(Object.assign({}, estadoInicialTerminosCondiciones))
        }
    }, [evento])

    const onChangeDatos = e => {
        setDatosTerminosCondiciones({
            ...datosTerminosCondiciones,
            [e.target.name]:e.target.value
        })
    }

    const buttonActivo = () => {
        setDatosTerminosCondiciones({
            ...datosTerminosCondiciones,
            activo: !datosTerminosCondiciones.activo
        })
    }

    const guardarArticulos = () => {
        if(
            articulos.titulo.trim() === '' ||
            articulos.subtitulo.trim() === '' ||
            articulos.contenido.trim() === ''
        ) {
            setMensaje(true)
            return
        }
        setMensaje(false)
        setDatosTerminosCondiciones({
            ...datosTerminosCondiciones,
            articulos: [
                ...datosTerminosCondiciones.articulos,
                {
                    titulo: articulos.titulo,
                    subtitulo: articulos.subtitulo,
                    contenido: articulos.contenido
                }
            ]
        })
        setArticulos(Object.assign({}, estadoArticulosInicial))
    }

    const onChangeArticulos = e => {
        setArticulos({
            ...articulos,
            [e.target.name]:e.target.value
        })
    }
    
    const desactivarModal = () => {
        setModalActivo(false)
        setMensaje(false)
        setEvento('')
        setArticulos(Object.assign({}, estadoArticulosInicial))
    }

    const onChangeArticulosEditar = ( e, index ) => {
        let articulos = datosTerminosCondiciones.articulos;
        articulos[index] = {
            ...articulos[index],
            [e.target.name]: e.target.value
        }
        setDatosTerminosCondiciones({
            ...datosTerminosCondiciones,
            articulos: articulos
        })

    }

    const crearTerminos = (payload) => {
        if(
            datosTerminosCondiciones.subtitulo.trim() === '' ||
            datosTerminosCondiciones.titulo.trim() === '' ||
            datosTerminosCondiciones.version.trim() === '' ||
            datosTerminosCondiciones.vigencia.trim() === ''
        ) {
            setMensaje(true)        
            return
        }
        guardar(payload)
    }

    const actualizarTerminos = (payload) => { 
        if(
            datosTerminosCondiciones.subtitulo.trim() === '' ||
            datosTerminosCondiciones.titulo.trim() === '' ||
            datosTerminosCondiciones.version.trim() === '' ||
            datosTerminosCondiciones.vigencia.trim() === ''
        ) {
            setMensaje(true)
            return
        }
        actualizar(payload)
    }

    const eliminarArticulo = (item) => {
        const index = datosTerminosCondiciones.articulos.indexOf(item)
        datosTerminosCondiciones.articulos.splice(index, 1 )
        if(evento === EVENTO_ACTUALIZAR){
            actualizarTerminos(datosTerminosCondiciones)
        }
    }

    return ( 
        <Modal
            open={modalActivo}
        >
            <Modal.Header> <Icon name="edit" color="red"/>Formulario Terminos & Condiciones</Modal.Header>
            <Modal.Content>
                <Form>
                    <h3>Datos</h3>
                    <Form.Group widths="equal">
                        <Form.Input
                            label="Versión"
                            width="10"
                            name="version"
                            value={datosTerminosCondiciones.version}
                            onChange={onChangeDatos}
                        />
                        <Form.Input
                            label="Vigencia"
                            name="vigencia"
                            value={datosTerminosCondiciones.vigencia}
                            onChange={onChangeDatos}
                        />
                        <Form.Input
                            label="Titulo"
                            name="titulo"
                            value={datosTerminosCondiciones.titulo}
                            onChange={onChangeDatos}
                        />
                        <Form.Input
                            label="Subtitulo"
                            name="subtitulo"
                            value={datosTerminosCondiciones.subtitulo}
                            onChange={onChangeDatos}
                        />
                    </Form.Group>

                    <h3>Articulos</h3>
                    <Form.Group widths="equal">
                        <Form.Input
                            label="Titulo"
                            width="10"
                            name="titulo"
                            value={articulos.titulo}
                            onChange={onChangeArticulos}

                        />
                        <Form.Input
                            label="Subtitulo"
                            width="10"
                            name="subtitulo"
                            value={articulos.subtitulo}
                            onChange={onChangeArticulos}
                        />
                    </Form.Group>
                        <TextArea
                            placeholder="Contenido..."
                            name="contenido"
                            value={articulos.contenido}
                            onChange={onChangeArticulos}
                        />
                </Form>
                <p></p>
                <Grid
                    textAlign="right"
                >
                    <Grid.Column>
                        <Button
                            onClick={() => guardarArticulos()}
                        >
                            <Icon name="plus"/>
                            Agregar Articulo
                        </Button>
                    </Grid.Column>
                </Grid>
                {mensaje ? <Mensaje/> : null}
                {datosTerminosCondiciones.articulos && datosTerminosCondiciones.articulos.length>0 ?  datosTerminosCondiciones.articulos.map((ar, index) => {
                    return(
                        <Fragment key={index} >
                            <Form>
                                <Form.Group widths="equal">
                                    <Form.Input
                                        label="Titulo"
                                        width="10"
                                        name="titulo"
                                        value={ar.titulo}
                                        onChange={(e) => onChangeArticulosEditar(e, index)}

                                    />
                                    <Form.Input
                                        label="Subtitulo"
                                        width="10"
                                        name="subtitulo"
                                        value={ar.subtitulo}
                                        onChange={(e) => onChangeArticulosEditar(e, index)}
                                    />
                                </Form.Group>
                                <TextArea
                                    placeholder="Contenido..."
                                    name="contenido"
                                    value={ar.contenido}
                                    onChange={(e) => onChangeArticulosEditar(e, index)}
                                />
                            <Form.Group>
                               {evento === EVENTO_ACTUALIZAR ?
                               <Button
                                    style={{marginTop: 10, backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                                    size="tiny"
                                    content="ELIMINAR"
                                    floated="right"
                                    onClick={() => eliminarArticulo(ar)}
                                    icon="trash"
                                />
                                :
                                null
                                }   
                            </Form.Group>
                        </Form>
                    </Fragment>
                    )
                }) : null }
                    <Checkbox 
                        label="Si esta desactivado, no estará disponible en la oferta comercial"
                        onChange={() => buttonActivo()}
                        checked={datosTerminosCondiciones.activo}
                        toggle 
                    />
            </Modal.Content>
                <Modal.Actions>
                    <Button 
                        content="CANCELAR" 
                        icon="times"
                        size='tiny'
                        style={{ borderRadius:25 }}
                        onClick={()=> desactivarModal()}
                    />
                    {evento === EVENTO_ACTUALIZAR ?
                    <Button 
                        style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                        size="tiny" 
                        content="ACTUALIZAR"
                        onClick={() => actualizarTerminos(datosTerminosCondiciones)}
                        icon="save"
                    />
                    :
                    <Button 
                        style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                        size="tiny" 
                        content="GUARDAR" 
                        onClick={() => crearTerminos(datosTerminosCondiciones)}
                        icon="save"
                    />
                    }
             
                </Modal.Actions>
        </Modal>
     );
}
 
export default FormularioTerminosCondiciones;