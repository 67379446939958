import React, {useState, useEffect } from 'react';
import { estadoInicialConcesion } from '../../estadosIniciales/concesion/estadoInicial.concesion';
import { Form, Button, Icon, Modal, Checkbox } from 'semantic-ui-react';
import { EVENTO_ACTUALIZAR }  from '../../enum/eventos/tipo-evento'
import {tipoCallePrincipalOpciones} from '../../estadosIniciales/listas/direccion-lista'
import Mensaje from '../alertas/alertas.camposObligatorios';



const FormularioConcesiones = ({
    guardar,
    actualizar,
    concesion,
    evento, 
    modalActivo, 
    setModalActivo,
    setEvento,
}) => {
    
    const [ mensaje, setMensaje ] = useState(false)
    const [ datosConcesion, setDatosConcesion ] = useState(Object.assign({}, estadoInicialConcesion))
    useEffect(() => {
        if(evento === EVENTO_ACTUALIZAR){
            setDatosConcesion(concesion)
        } else {
            setDatosConcesion(Object.assign({}, estadoInicialConcesion))
        }
    }, [evento])

    const onChangeDireccion = ( e, { name, value }) => {
        setDatosConcesion({
            ...datosConcesion,
            direccion: {
                ...datosConcesion.direccion,
                [name]: value
            }
        })
    }
    const onChangeTelefonos = e => {
        setDatosConcesion({
            ...datosConcesion,
            telefonos: {
                ...datosConcesion.telefonos,
                [e.target.name]:e.target.value
            }
        })
    }
    const onChangeDatosConcesion = e => {
        setDatosConcesion({
            ...datosConcesion,
            [e.target.name]:e.target.value
        })
    }
    const onChangeManoDeObra = e => {
        const value = parseInt(e.target.value, 10)
        setDatosConcesion({
            ...datosConcesion,
            manoObra: {
            ...datosConcesion.manoObra,
            [e.target.name] : value
            }
        })
    }
    const buttonActivo =() => {
        setDatosConcesion({
            ...datosConcesion,
            activo: !datosConcesion.activo
        })
    }
    const crearConcesionario = (payload) => {
        if(
            datosConcesion.cl.trim() === '' ||
            datosConcesion.nombre.trim() === '' ||
            datosConcesion.abreviatura.trim() === '' ||
            datosConcesion.grupo.trim() === '' ||
            datosConcesion.tipo.trim() === '' ||
            datosConcesion.telefonos.indicativoCiudad.trim() === '' ||
            datosConcesion.telefonos.numero.trim() === '' ||
            datosConcesion.direccion.callePrincipal.trim() === '' ||
            datosConcesion.direccion.calleSecundaria.trim() === '' ||
            datosConcesion.direccion.numero.trim() === '' ||
            datosConcesion.direccion.barrio.trim() === '' ||
            datosConcesion.direccion.ciudad.trim() === '' ||
            datosConcesion.email.trim() === '' ||
            datosConcesion.tarifaManoDeObra === '' 
        ) {
            setMensaje(true)
            return;
        }
        guardar(payload)
    }

    const desactivarModal = () => {
        setMensaje(false)
        setModalActivo(false)
        setEvento('')
    }

    if ( !datosConcesion ) {
        return null
    }

    
    return(
    
        <Modal
            open={modalActivo}
        >
            <Modal.Header><Icon color="red" name="edit"/>Formulario Concesiones</Modal.Header>
            <Modal.Content>
                <Form>
                    <h3>Identificacion</h3>
                    <Form.Group widths="equal">
                        <Form.Input
                            minLength="5"
                            maxLength="5"
                            label="Codigo de Concesionario (cl)" 
                            width="14"
                            name= 'cl'
                            value= {datosConcesion.cl}
                            onChange= {onChangeDatosConcesion}
                            />
                        <Form.Input 
                            label="Nombre"
                            name="nombre"
                            value= {datosConcesion.nombre}
                            onChange= {onChangeDatosConcesion}
                        />
                        <Form.Input 
                            label="Abreviatura" 
                            width="8"
                            name="abreviatura"
                            value={datosConcesion.abreviatura}
                            onChange={onChangeDatosConcesion}
                        />
                        <Form.Input 
                            label="Grupo"
                            width="13"
                            name="grupo"
                            value={datosConcesion.grupo}
                            onChange={onChangeDatosConcesion}   
                        />
                        <Form.Input 
                            label="Tipo" 
                            width="4"
                            name="tipo"
                            value={datosConcesion.tipo}
                            onChange={onChangeDatosConcesion}
                        />
                    </Form.Group>
                    <h3>Dirección</h3>
                    <Form.Group widths="equal">
                        <Form.Select
                            options={tipoCallePrincipalOpciones}
                            label= 'Calle Principal'
                            value={datosConcesion.direccion.callePrincipal}
                            name="callePrincipal"
                            onChange={onChangeDireccion}
                        ></Form.Select>
                        <Form.Input 
                            label= 'Calle Secundaria'
                            name= 'calleSecundaria'
                            value={datosConcesion.direccion.calleSecundaria}
                            onChange={onChangeDireccion}
                        />
                        <Form.Input 
                            label= 'Numero'
                            name= 'numero'
                            value={datosConcesion.direccion.numero}
                            onChange={onChangeDireccion}
                        />
                        <Form.Input 
                            label= 'Otros'
                            name= 'otros'
                            value= {datosConcesion.direccion.otros}
                            onChange={onChangeDireccion}
                        />
                    </Form.Group>
                    <Form.Group widths="4">
                        <Form.Input 
                            label= 'Barrio'
                            name= 'barrio'
                            value= {datosConcesion.direccion.barrio}
                            onChange={onChangeDireccion}
                        />
                        <Form.Input 
                            label= 'Ciudad'
                            name= 'ciudad'
                            value= {datosConcesion.direccion.ciudad}
                            onChange={onChangeDireccion} 
                        />
                    </Form.Group>
                    <h3>Datos de Contacto</h3>
                    <Form.Group widths="equal">
                        <Form.Input 
                            label="Indicativo de Ciudad" 
                            width="5"
                            name="indicativoCiudad"
                            value= {datosConcesion.telefonos.indicativoCiudad}
                            onChange={onChangeTelefonos}
                        />
                        <Form.Input 
                            label="Numero"
                            width="7"
                            name="numero"
                            value={datosConcesion.telefonos.numero}  
                            onChange={onChangeTelefonos}  
                        />
                        <Form.Input 
                            label="Correo Electrónico"
                            name="email"
                            value={datosConcesion.email}
                            onChange={onChangeDatosConcesion}
                        />
                    </Form.Group>
                    <h3>Mano De Obra</h3>
                    <Form.Group>
                        <Form.Input
                            type="number" 
                            label="Ordinaria"
                            width="4"
                            name="ordinaria"
                            value={datosConcesion.manoObra.ordinaria}
                            onChange={onChangeManoDeObra}
                        />
                        <Form.Input
                            type="number" 
                            label="Tmog"
                            width="4"
                            name="tmog"
                            value={datosConcesion.manoObra.tmog}
                            onChange={onChangeManoDeObra}
                        />
                    </Form.Group>
                    <h3>Activacion Del Concesionario</h3>
                        <Checkbox
                            label="Si el concesionario esta desactivado, no estará disponible"
                            name="activo"
                            onChange={() => buttonActivo()}
                            checked={datosConcesion.activo}
                            toggle 
                        />
                </Form>
                { mensaje ? <Mensaje /> : null}
            </Modal.Content>
                <Modal.Actions>
                    <Button 
                        content="CANCELAR" 
                        icon="times"
                        size='tiny'
                        style={{ borderRadius:25 }}
                        onClick={() => desactivarModal()}
                    />
                    {evento === EVENTO_ACTUALIZAR  ? 
                    <Button 
                        style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                        size="tiny" 
                        content="ACTUALIZAR"
                        onClick={() => actualizar(datosConcesion)}    
                        icon="save"
                    /> 
                    :
                    <Button 
                        style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                        onClick={() => crearConcesionario(datosConcesion)}
                        size="tiny" 
                        content="GUARDAR" 
                        icon="save"
                    /> 
                    }

                </Modal.Actions>
        </Modal>
    )
}

export default FormularioConcesiones;