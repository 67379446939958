import React, {useEffect, useState} from 'react';
import {Modal, Header, Button} from 'semantic-ui-react'
import { estadoInicialRedencion } from '../../estadosIniciales/servicios/estadoInicial-redencion';
import { redemirServicio } from '../../servicios/solicitudAdquisiciones/solicitud.srv';
import DatosServicio from '../../components/tarjetas/datosServicio';
import ModalMensaje from './modalMensaje';
import { consultaConcesionarioPorGrupo } from '../../servicios/concesion/concesiones.srv';
import { VISTA_SERVICIOS_REDIMIR } from '../../enum/eventos/tipo-evento';


const ModalDetallesServicios = ({
    verDetallesServicio,
    setVerDetallesServicio,
    detallesServicio,
    consultar,
    idSolicitud
}) => {

    const [ redencion, setRedencion ] = useState(Object.assign({}, estadoInicialRedencion))
    const [ evento ] = useState(VISTA_SERVICIOS_REDIMIR)
    const [ listaConcesionarios, setListaConcesionarios ] = useState([])
    const [ listaGrupos, setListaGrupos ] = useState([])
    const [ modalMensajeEstatus, setModalMensajeEstatus ] = useState({
        activo: false,
        mensaje: ''
    })

    useEffect(() => {
        if(detallesServicio?.redencion !== undefined && detallesServicio?.redencion?.nombres?.nombres) {
            setRedencion(detallesServicio.redencion)
        } else {
            setRedencion(Object.assign({}, estadoInicialRedencion))
        }
    }, [detallesServicio])

    // Función que llama el servicio de Redención
    const redimir = async() => {
        try {
            const respuesta = await redemirServicio({
                ...redencion,
                idSolicitud: idSolicitud.id,
                idServicio: detallesServicio._id
            })
            if(respuesta.data.estatus) {
                setModalMensajeEstatus({
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion
                })
            } {
                setModalMensajeEstatus({
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion
                })
            }
        } catch (error) {
            setModalMensajeEstatus({
                activo: true,
                mensaje: "Ha ocurrido un problema"
            })
        }
    }

    const consultaConcesiones = async () => {
        try {
            const concesiones = await consultaConcesionarioPorGrupo({})

            if ( concesiones.estatus ) {
                const prepararLista = []
                const prepararGrupos = []
                concesiones.data.map( i => {

                    prepararGrupos.push({
                        key: i.grupo,
                        text: i.grupo,
                        value: i.grupo
                    })

                    return i.concesiones.map( j => {
                       
                        return prepararLista.push({
                            cl: j.cl,
                            grupo: i.grupo,
                            key: `${i.grupo} ${j.nombre} ${j.cl}`,
                            text: `${j.nombre} ${j.cl}`,
                            value: j.nombre
                        })
                    })
                } )
                setListaConcesionarios( prepararLista )
                setListaGrupos( prepararGrupos )
            } else {
                setModalMensajeEstatus({
                    activo: true,
                    mensaje: concesiones.resultadoOperacion
                })
            }
        } catch ( error ) {
            setModalMensajeEstatus({
                activo: true,
                mensaje: 'Error al consultar concesiones'
            })
        }
        
    }


    return ( 
        <Modal
            onClose={() => {setVerDetallesServicio(false); consultar()}}
            open={verDetallesServicio}
            style={{ borderRadius: 0, paddingTop: 20, paddingBottom: 20 }}
        >
            <Modal.Header 
                style={{ 
                    backgroundColor:'#FFFFFF', 
                    border:'none'}} 
                as="h5">
                <Header>
                {detallesServicio?.nombre}
                </Header>
            </Modal.Header>
            <Modal.Content>
                <DatosServicio
                    redimir={redimir}
                    setRedencion={setRedencion}
                    consultaConcesiones={consultaConcesiones}
                    evento={evento}
                    grupos={listaGrupos}
                    concesionarios={listaConcesionarios}
                    redencion={redencion}
                    i={detallesServicio}
                />
            </Modal.Content>
            <Modal.Actions style={{ backgroundColor:'#FFFFFF', border:'none', textAlign:'left'}}>
                <Button 
                    content="Cerrar"
                    style={{
                        padding: 20,
                        borderRadius: 0, 
                        backgroundColor:'#FFFFFF',
                        borderColor: '#000000',
                        borderStyle:'solid',
                        borderWidth:'1px',
                        fontWeight: 'bold',
                        color:'#000000'
                    }}
                    primary
                    onClick={() => {setVerDetallesServicio(false); consultar()}}
                />
            </Modal.Actions>
            <ModalMensaje
                activo={modalMensajeEstatus.activo}
                mensaje={modalMensajeEstatus.mensaje}
                setModalMensajeEstatus={setModalMensajeEstatus}
            />
        </Modal>
    );

}
 
export default ModalDetallesServicios;