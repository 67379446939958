import React from 'react';
import { Modal, Form, Icon, Button, Checkbox, Table, Popup } from 'semantic-ui-react'
import { tipoAireAcondicionadoOpciones } from '../../estadosIniciales/listas/aireAcondicionado-lista';
import { tipoCombustibleOpciones } from '../../estadosIniciales/listas/combustible-lista';
import { tipoTransmisionOpciones } from '../../estadosIniciales/listas/transmision-lista';
import { EVENTO_ACTUALIZAR } from '../../enum/eventos/tipo-evento';





const FormularioOperaciones = ({
    consultar,
    evento,
    actualizar,
    guardar,
    setEvento,
    setDatosOperacion,
    datosOperacion,
    setModalRepuestos,
    modalActivo,
    setModalActivo
}) => {

    const onChangeDatos = (e, {name}) => {
        const value = parseFloat(e.target.value)
        if(name === 'horas'){
            setDatosOperacion({
                ...datosOperacion,
                [e.target.name]: value
            })
        } else {
            setDatosOperacion({
                ...datosOperacion,
                [e.target.name]: e.target.value
            })
        }
        
    }
    const onChangeAplicaPara = (e, {name, value}) => {
        setDatosOperacion({
            ...datosOperacion,
            aplicaPara: {
                ...datosOperacion.aplicaPara,
                [name] : value
            }
        })
    }
    const onChangeAplicaParaC = e => {
        const value = parseInt(e.target.value)
        setDatosOperacion({
            ...datosOperacion,
            aplicaPara: {
                ...datosOperacion.aplicaPara,
                [e.target.name] : value
            }
        })
    }

    const buttonActivo = () => {
        setDatosOperacion({
            ...datosOperacion,
            activo: !datosOperacion.activo
        })
    }

    const sumarCantidad = (i, index) => {

        let repuestos = datosOperacion.repuestos

        repuestos[index].cantidad += 1
        
        setDatosOperacion({
            ...datosOperacion,
            repuestos: repuestos
        })
    }

    const restarCantidad = (i, index) => {
        let repuestos = datosOperacion.repuestos

        if ( !(repuestos[index].cantidad > 1) ) {
            return
        }
        repuestos[index].cantidad -= 1
        
        setDatosOperacion({
            ...datosOperacion,
            repuestos: repuestos
        })
    }

    const guardarOperaciones = () => {
        guardar(datosOperacion)
    }

    const actualizarOperaciones = () => {
        actualizar(datosOperacion)
    }

    const desactivarModal = () => {
        setModalActivo(false)
        setEvento('')
        consultar()
        
    }

    const abrirModalRepuestos = () => {
        setModalRepuestos(true)
    }

    const eliminarRepuestos = (i, index) => {

        let repuesto = datosOperacion.repuestos

        repuesto.splice(index, 1)

        setDatosOperacion({
            ...datosOperacion,
            repuestos: repuesto
        })
    } 


    return ( 
        <Modal 
            open={modalActivo}
        >
            <Modal.Header> <Icon name="edit" color="red"/> Formulario Operaciones </Modal.Header>
            <Modal.Content>
                <Form>
                    <h3>Descripcion</h3>
                    <Form.Group
                        widths="equal"
                    >
                        <Form.Input
                            label="Nombre"
                            name="nombre"
                            value={datosOperacion.nombre}
                            onChange={onChangeDatos}
                        />
                        <Form.Input
                            label="Horas"
                            name="horas"
                            type="number"
                            value={datosOperacion.horas}
                            onChange={onChangeDatos}
                        />
                        <Form.Input
                            label="Codigo"
                            name="codigo"
                            value={datosOperacion.codigo}
                            onChange={onChangeDatos}
                        />
                    </Form.Group>
                    <h3>Aplica Para</h3>
                    <Form.Group
                        widths="equal"
                    >
                        <Form.Select
                            label="Aire Acondicionado"
                            options={tipoAireAcondicionadoOpciones}
                            value={datosOperacion.aplicaPara.aireAcondicionado}
                            name="aireAcondicionado"
                            onChange={onChangeAplicaPara}
                        />
                        <Form.Select
                            label="Tipo Combustible"
                            options={tipoCombustibleOpciones}
                            value={datosOperacion.aplicaPara.tipoCombustible}
                            name="tipoCombustible"
                            onChange={onChangeAplicaPara}
                        />
                        <Form.Select
                            label="Transmision"
                            options={tipoTransmisionOpciones}
                            name="transmision"
                            value={datosOperacion.aplicaPara.transmision}
                            onChange={onChangeAplicaPara}
                        />
                        <Form.Input
                            type="number"
                            minLength="2"
                            maxLength={2}
                            label={ 
                                <Popup 
                                    content='Solo dos digitos' 
                                    trigger={ 
                                        <label>
                                            <Icon name="info circle"/>
                                            Cilindrada
                                        </label>
                                     } />
                            }
                            name="cilindrada"
                            value={datosOperacion.aplicaPara.cilindrada}
                            onChange={onChangeAplicaParaC}
                        />
                    </Form.Group>
                    <h3>Repuestos</h3>
                    <Form.Group>
                        <Button
                            onClick={() => abrirModalRepuestos()}
                            content="Agregar Repuesto"
                            icon="plus"
                        />
                    </Form.Group>
                    <h3>Activacion De Operacion</h3>
                        <Checkbox 
                            label="Si la operacion esta desactivado no estará disponible"
                            checked={datosOperacion.activo} 
                            onChange={() => buttonActivo()}
                            toggle 
                        />
                </Form>
                {datosOperacion !== undefined && datosOperacion.repuestos.length>0 ? 
                 <Table textAlign="center" celled size="small">
                 <Table.Header>
                 <Table.Row>
                     <Table.HeaderCell rowSpan="2">REFERENCIA</Table.HeaderCell>
                     <Table.HeaderCell rowSpan="2">PNC</Table.HeaderCell>
                     <Table.HeaderCell colSpan="2" >PRECIO VENTA</Table.HeaderCell>
                     <Table.HeaderCell rowSpan="2">CANTIDAD</Table.HeaderCell>
                     <Table.HeaderCell rowSpan="2">ACCIONES</Table.HeaderCell>
                 </Table.Row>
                 <Table.Row>
                 <Table.HeaderCell>PUBLICO</Table.HeaderCell>
                 <Table.HeaderCell>CONCESIONARIO</Table.HeaderCell>
                 </Table.Row>
                 </Table.Header>
                 <Table.Body>
                     {datosOperacion.repuestos.map((i, index) =>(
                         <Table.Row key={i._id}>
                             <Table.Cell> {i.referencia} </Table.Cell>
                             <Table.Cell> {i.pnc} </Table.Cell>
                             <Table.Cell> {i.precioVenta.publico} </Table.Cell>
                             <Table.Cell> {i.precioVenta.concesionario} </Table.Cell>
                             <Table.Cell> {i.cantidad} </Table.Cell>
                             <Table.Cell verticalAlign="top">
                                <Button
                                    onClick={() => restarCantidad(i.cantidad, index)}
                                    icon="minus"
                                    size="mini"
                                 />
                                <Button
                                    onClick={() => eliminarRepuestos(i, index)}
                                    icon="trash"    
                                    size="mini" 
                                    toggle
                                 />
                                <Button
                                    icon="plus"
                                    size="mini"
                                    onClick={() => sumarCantidad(i.cantidad, index)}
                                 />
                             </Table.Cell>
                         </Table.Row>
                     ))}
                 </Table.Body>
             </Table>: null}
            </Modal.Content>
                <Modal.Actions>
                    <Button 
                        content="CANCELAR" 
                        icon="times"
                        size='tiny'
                        style={{ borderRadius:25 }}
                        onClick={() => desactivarModal(false)}
                    />
                    {evento === EVENTO_ACTUALIZAR ?
                    <Button 
                        style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                        size="tiny" 
                        content="ACTUALIZAR"
                        onClick={actualizarOperaciones}
                        icon="save"
                    />
                    :
                    <Button 
                        style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                        size="tiny" 
                        content="GUARDAR"
                        onClick={guardarOperaciones}
                        icon="save"
                    />
                    }
                    
                </Modal.Actions>
        </Modal>
     )
}
 
export default FormularioOperaciones;