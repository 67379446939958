import axios from 'axios'
import { variables } from './variables'

export const clienteAxios = axios.create({
    // Local
    baseURL: `${variables.backend}api/v1`,
    // headers: {
    //     'Accept':"*/*",
    //     'Access-Control-Allow-Headers':'Origin, X-Requested-With, Content-Type, Accept, Authorization',
    //     'Access-Control-Allow-Methods':'POST,GET,OPTIONS,PUT,DELETE',
    //     // "Origin": variables.frontend
    // }
    // Desarrollo
    // baseURL: 'https://dev-backend-mkplus.herokuapp.com/api/v1'
    // Produccion
    // baseURL: 'https://backendprepagado.kia.com.co/api/v1'
})

export const cloudAxios = axios.create({
    baseURL: 'http://ec2-3-15-24-169.us-east-2.compute.amazonaws.com:5500/cloud/v1/archivo'
})