import React from 'react';
import { Message } from 'semantic-ui-react';

const NoItems = () => {

    return (
        <Message
            icon='file outline'
            header='No se encontro informacion'
            content='No encontramos informacion con estos criterios de busqueda, o no existe aun informacion.'
        />
    )
}

export default NoItems