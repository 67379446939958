import { clienteAxios } from '../../config/axios';
import tokenAuth from '../../config/tokenAuth';



export const crearActualizarFirma = async (payload: any) => {

    try {
        const respuesta = await clienteAxios.post('/firma-solicitud/crear-actualizar-firma', payload)
        return(respuesta.data)
    } catch (error) {
        console.log('error', error )
        return({
            data: {
                estatus: false,
                resultadoOperacion: "No se pudo registrar la firma."
            }
        })
    }
}

export const consultaFirmasAcuerdo = async (payload: any) => {

    try {
        const respuesta = await clienteAxios.post('/firma-solicitud/consulta-firma', payload)
        return(respuesta.data)
    } catch (error) {
        console.log('error', error )
        return({
            data: {
                estatus: false,
                resultadoOperacion: "No se pudo consultar la firma."
            }
        })
    }
}

