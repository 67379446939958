import React, { Fragment } from 'react';
import { Grid, Container, Image, List, Responsive, Button, Icon } from 'semantic-ui-react';

const FooterComponent = () => {

    return (
        <Fragment>
        <Grid  style={{backgroundColor:"#05141F", marginTop:0, position:'relative'}}>
            <Container>
                <Image
                    style={{
                        marginTop: 60,
                        marginBottom: 20
                        
                    }}
                    src={require('../../../src/assets/img/logos/logo-kia-blanco-fondo-oscuro.png')} 
                    size="small"
                />
                <Grid.Column style={{paddingBottom:25}}>
                    <Responsive>
                        <Container fluid style={{marginBottom: 30}}>
                        <List>
                            <List.Item as='a' href="https://www.kia.com.co/" style={{color:'#FFFFFF',fontWeight:'bold', fontFamily:'kiaBold', padding: 5}}>Kia.com.co</List.Item>
                            <List.Item as='a' href="https://www.kia.com.co/privacidad-y-proteccion-de-datos" style={{color:'#FFFFFF',fontWeight:'bold', fontFamily:'kiaBold', padding: 5}}>Politica de privacidad</List.Item>
                            <List.Item as='a' href="https://www.kia.com.co/mapa-del-sitio" style={{color:'#FFFFFF',fontWeight:'bold', fontFamily:'kiaBold', padding: 5}}>Mapa del sitio</List.Item>
                            <List.Item as='a' href="https://www.kia.com.co/contactenos" style={{color:'#FFFFFF',fontWeight:'bold', fontFamily:'kiaBold', padding: 5}}>Contáctenos</List.Item>
                        </List>
                        </Container>
                        <Container fluid style={{marginBottom:5}}>
                        <List horizontal>
                            <List.Item style={{color:'#FFFFFF',fontWeight:'bold', fontFamily:'kiaBold'}}>
                                <Button
                                    circular
                                    content="f"
                                    style={{
                                        color: '#FFFFFF',
                                        backgroundColor:'transparent',
                                        borderColor: 'transparent',
                                        fontWeight: 'bold'
                                    }}
                                />
                            </List.Item>
                            <List.Item style={{color:'#FFFFFF',fontWeight:'bold', fontFamily:'kiaBold'}}>
                                <Button
                                    circular
                                    style={{
                                        color: '#FFFFFF',
                                        backgroundColor:'transparent',
                                        borderColor: 'transparent',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    <Icon name="twitter" style={{color: "#FFFFFF"}}/>
                                </Button>
                            </List.Item>
                            <List.Item  style={{color:'#FFFFFF',fontWeight:'bold', fontFamily:'kiaBold', padding: 5}}>
                                <Button
                                    circular
                                    style={{
                                        color: '#FFFFFF',
                                        backgroundColor:'transparent',
                                        borderColor: 'transparent',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    <Icon name="instagram" style={{color: "#FFFFFF"}}/>
                                </Button>
                            </List.Item>
                            <List.Item  style={{color:'#FFFFFF',fontWeight:'bold', fontFamily:'kiaBold', padding: 5}}>
                                <Button
                                    circular
                                    style={{
                                        color: '#FFFFFF',
                                        backgroundColor:'transparent',
                                        borderColor: 'transparent',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    <Icon name="youtube" style={{color: "#FFFFFF"}}/>
                                </Button>
                            </List.Item>
                        </List>
                        </Container>
                    </Responsive>
                </Grid.Column>
            </Container>
        </Grid>
    </Fragment>
    //     <Fragment>
    //     <Grid  style={{backgroundColor:"#E4E5E6", marginTop:0, position:'relative'}}>
    //         <Container>
    //             <Grid.Column style={{padding:15}}>
    //                 <Responsive >
    //                     <List bulleted horizontal>
    //                         <List.Item as='a' href="https://www.kia.com/co/main.html" style={styles.linkFooter}>KIA.COM.CO</List.Item>
    //                         <List.Item as='a' href="https://www.kia.com/co/util/privacy.html" style={styles.linkFooter}>POLÍTICA DE PRIVACIDAD</List.Item>
    //                         <List.Item as='a' href="https://www.kia.com/co/util/sitemap.html" style={styles.linkFooter}>MAPA DEL SITIO</List.Item>
    //                         <List.Item as='a' href="https://www.kia.com/co/util/customer-center/contact-us.html" style={styles.linkFooter}>CONTÁCTENOS</List.Item>
    //                     </List>
    //                 </Responsive>
    //                 <Responsive {...Responsive.onlyMobile}>
    //                     <List bulleted>
    //                         <List.Item as='a' href="https://www.kia.com/co/main.html" style={styles.linkFooter}>KIA.COM.CO</List.Item>
    //                         <List.Item as='a' href="https://www.kia.com/co/util/privacy.html" style={styles.linkFooter}>POLÍTICA DE PRIVACIDAD</List.Item>
    //                         <List.Item as='a' href="https://www.kia.com/co/util/sitemap.html" style={styles.linkFooter}>MAPA DEL SITIO</List.Item>
                            
    //                         <List.Item as='a' href="https://www.kia.com/co/util/customer-center/contact-us.html" style={styles.linkFooter}>CONTÁCTENOS</List.Item>
    //                     </List>
    //                 </Responsive>
    //                 <br></br>
    //                 <p style={{fontSize:10}}>TODOS LOS DERECHOS RESERVADOS &copy; METROKIA IMPORTADORA</p>
    //             </Grid.Column>
    //         </Container>
    //     </Grid>
    //     <Grid style={{backgroundColor:"#E4E5E6", marginTop:0}}>
    //         <Grid.Column style={{backgroundColor:'#E4E5E6', height:56}}>
    //             <Container fluid>
    //                 <Image style={{position:'absolute', right:0, top:5, widht:150}} src={require('../../assets/img/logos/lg_footer_kia.png')}></Image>
    //             </Container>
    //         </Grid.Column>
    //     </Grid>
    // </Fragment>
     );


}
 
export default FooterComponent;