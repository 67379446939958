import React, { useState } from 'react';
import { Form, Button, Icon, Modal, Checkbox, Input, Label } from 'semantic-ui-react';
import { operacionesListado } from '../../estadosIniciales/listas/tipoOperacion-lista';

const FormularioServicios = ({
    datosServicio,
    modalActivo,
    guardar,
    setDatosServicio,
    setEvento,
    setModalActivo
}) => {

    const [addVersion, setAddVersion] = useState('')


    const onChangeDatos = (e, {name, value}) => {
        if(name==='cantidad'){
            setDatosServicio({
                ...datosServicio,
                [name]: value
            })
        } else {
            setDatosServicio({
                ...datosServicio,
                [name]: value
            })
        }
    }
    const onChageDatosModelo = e => {
        setDatosServicio({
            ...datosServicio,
            modelo: {
                ...datosServicio.modelo,
                [e.target.name]:e.target.value
            }
        })
    }
    const onChangeAddVersion = (e, {name, value}) => {
        setAddVersion(value)
    }

    const agregarVersion = ( ) => {
        if ( !addVersion ) return;
        const existeVersion = datosServicio.modelo.version.find( (i) => { if (i === addVersion) { return i } })

        if ( existeVersion ) {
            return
        }
        setDatosServicio({
            ...datosServicio,
            modelo: {
                ...datosServicio.modelo,
                version: [...datosServicio.modelo.version, addVersion ]
            }
        })
        const a = ''
        setAddVersion(a)
    }

    const onChageFrecuencia = e => {
        setDatosServicio({
            ...datosServicio,
            frecuencia:{
                ...datosServicio.frecuencia,
                [e.target.name]:e.target.value
            }
        })
    }
    const onChangeVigencia = e => {
        setDatosServicio({
            ...datosServicio,
            vigencia: {
                ...datosServicio.vigencia,
                [e.target.name]:e.target.value
            }
        })
    }
    const onChangeAutorizado = () => {
        setDatosServicio({
            ...datosServicio,
            vigencia: {
                ...datosServicio.vigencia,
                autorizadoPorImportadora: !datosServicio.vigencia.autorizadoPorImportadora
            }
        })
    }
    const buttonActivo = () => {
        setDatosServicio({
            ...datosServicio,
            activo: !datosServicio.activo
        })
    }
    const desactivarModal = () => {
        setEvento('')
        setModalActivo(false)
    }
    const onChangeTarifa = () => {
        setDatosServicio({
            ...datosServicio,
            tarifaManoObraUnificada: !datosServicio.tarifaManoObraUnificada
        })
    }

    const eliminarVersion = ( version ) => {
        const index = datosServicio.modelo.version.indexOf( version )
        let datosServicioTemporales = Object.assign({}, datosServicio );
        datosServicioTemporales.modelo.version.splice(index, 1 )
        setDatosServicio( datosServicioTemporales )
    }
    return(
        <Modal
            open={modalActivo}
        >
            <Modal.Header> <Icon color="red" name="edit"></Icon> Formulario Servicios </Modal.Header>
            <Modal.Content>
                <Form>
                    <h3>Datos del Servicio</h3>
                    <Form.Group>
                        <Form.Input
                            label="Nombre"
                            name="nombre"
                            value={datosServicio.nombre}
                            onChange={onChangeDatos}
                        />
                        <Form.Input
                            label="Descripcion"
                            name="descripcion"
                            value={datosServicio.descripcion}
                            onChange={onChangeDatos}
                        />
                        <Form.Input
                            label="Cantidad"
                            type="Number"
                            name="cantidad"
                            value={datosServicio.cantidad}
                            onChange={onChangeDatos}
                        />
                        <Form.Select
                            label="Tipo"
                            options={operacionesListado}
                            name="tipo"
                            value={datosServicio.tipo}
                            onChange={onChangeDatos}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Field>
                            <label> Tarifa Mano de Obra Unificada </label>
                        <Form.Checkbox
                            style={{marginTop: 10}}
                            toggle
                            checked={datosServicio.tarifaManoObraUnificada}
                            onChange={onChangeTarifa}

                        />
                        </Form.Field>
                    </Form.Group>
                    <h3>Modelo al que Aplica</h3>
                    <Form.Group>
                        <Form.Input
                            label="Codigo del Modelo"
                            name="codigo"
                            value={datosServicio.modelo.codigo}
                            onChange={onChageDatosModelo}
                        />
                        <Form.Input
                            label="Descripcion"
                            name="descripcion"
                            value={datosServicio.modelo.descripcion}
                            onChange={onChageDatosModelo}
                        />
                        <Form.Field>
                            <label>Agregar version</label>
                            <Input
                                label={<Button icon="plus" onClick={ ()=> agregarVersion() } />}
                                labelPosition='right'
                                placeholder='Version'
                                value={addVersion}
                                onChange={onChangeAddVersion}
                            />
                        </Form.Field>
                    </Form.Group>
                    <h3>Versiones</h3>
                    <Form.Group>
                        {datosServicio.modelo.version? datosServicio.modelo.version.map( (i, index) => {
                            return <Label key={index} onClick={ ()=> eliminarVersion( i ) }>
                                {i}
                                <Icon name="delete"/>
                            </Label>
                        } ): null }
                    </Form.Group>
                    <h3>Vigencia</h3>
                    <Form.Group>
                        <Form.Input
                            label="Kilometraje"
                            type="Number"
                            name="kilometraje"
                            value={datosServicio.vigencia.kilometraje}
                            onChange={onChangeVigencia}
                        />
                        <Form.Input
                            label="Meses"
                            type="Number"
                            name="meses"
                            value={datosServicio.vigencia.meses}
                            onChange={onChangeVigencia}
                        />
                        <Form.Field>
                        <label>Autorización</label>
                            <Checkbox
                                toggle
                                label="Si esta activada, las vigencias no seran tenidas en cuenta"
                                style={{marginTop: 10}}
                                checked={datosServicio.vigencia.autorizadoPorImportadora}
                                onChange={onChangeAutorizado}
                            />
                        </Form.Field>
                       
                    </Form.Group>
                    <h3>Frecuencia</h3>
                    <Form.Group>
                        <Form.Input
                            label="Kilometraje"
                            type="Number"
                            name="kilometraje"
                            value={datosServicio.frecuencia.kilometraje}
                            onChange={onChageFrecuencia}
                        />
                        <Form.Input
                            label="Meses"
                            type="Number"
                            name="meses"
                            value={datosServicio.frecuencia.meses}
                            onChange={onChageFrecuencia}
                        />
                    </Form.Group>
                    <h3>Activacion del Servicio</h3>
                    <Form.Checkbox
                        toggle
                        checked={datosServicio.activo}
                        label="Si el servicio no está activo, no estará disponible en la oferta comercial"
                        onChange={buttonActivo}
                    />
                </Form>

            </Modal.Content>
                <Modal.Actions>
                    <Button   
                        content="CANCELAR" 
                        onClick={desactivarModal}
                        icon="times"
                        size='tiny'
                        style={{ borderRadius:25 }}
                    />
                    <Button 
                        style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                        size="tiny" 
                        content="GUARDAR"
                        onClick={() => guardar(datosServicio)}
                        icon="save"
                    /> 
                </Modal.Actions>
        </Modal>
    )
}

export default FormularioServicios;