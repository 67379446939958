import React, {useState, useEffect, Fragment} from 'react';
import { Modal, Button, Header } from 'semantic-ui-react';
import { credencialesInvalidasAceptar } from '../../actions/usuario.action';
import { useDispatch } from 'react-redux';
import {EVENTO_RETOMAR, EVENTO_NUEVA_SOLICITUD, EVENTO_REEMPLAZAR_VEHICULO, INICIAR_SESION, CONSULTA_REDENCION} from '../../enum/eventos/tipo-evento'
import {Link} from 'react-router-dom';

const ModalMensaje = ({
    id,
    evento, 
    activo, 
    objeto,
    mensaje, 
    setModalMensajeEstatus, 
    buscar,
    reemplazar,
    eventoCallback
}) => {

    const [ estadoModal, setEstadoModal ] = useState(false)

    const dispatch = useDispatch()
    useEffect(() => {
        if(activo){
            setEstadoModal(true)
        }
    }, [activo])

    const desactivarModal = () => {
        setEstadoModal(false)
        setModalMensajeEstatus({
            activo: false,
            mensaje: ''
        })
    }

    const desactivarModalInicioSesion = () => {
        setEstadoModal(false)
        dispatch(credencialesInvalidasAceptar())
        setModalMensajeEstatus({
            activo: false,
            mensaje: ''
        })

    }

    const buscarPorId = () => {
        buscar()
        desactivarModal()
    }

    const botonCallBack = () => {
        if(evento === EVENTO_RETOMAR){
            return(
                <Fragment>
                    <Button
                        style={{
                            padding: 20,
                            borderRadius: 0, 
                            backgroundColor:'#FFFFFF',
                            borderColor: '#000000',
                            borderStyle:'solid',
                            borderWidth:'1px',
                            fontWeight: 'bold',
                            color:'#000000'
                        }}
                        size="tiny"
                        content="Cancelar"
                        onClick={() => desactivarModal()}
                    />
                    <Button 
                        style={{
                            padding: 20,
                            borderRadius: 0, 
                            backgroundColor:'#000000',
                            borderColor: '#000000',
                            borderStyle:'solid',
                            borderWidth:'1px',
                            fontWeight: 'bold',
                            color:'#FFFFFF'
                        }}
                        size="tiny" 
                        content="Retormar solicitud"
                        onClick={buscarPorId}
                    />
                </Fragment>
            )
        } else if (evento === EVENTO_NUEVA_SOLICITUD){
            return(
                <Fragment>
                <Button
                    style={{
                        padding: 20,
                        borderRadius: 0, 
                        backgroundColor:'#FFFFFF',
                        borderColor: '#000000',
                        borderStyle:'solid',
                        borderWidth:'1px',
                        fontWeight: 'bold',
                        color:'#000000'
                    }}
                    size="tiny"
                    content="Aceptar"
                    onClick={() => desactivarModal()}
                />
                <Button
                    style={{
                        padding: 20,
                        borderRadius: 0, 
                        backgroundColor:'#000000',
                        borderColor: '#000000',
                        borderStyle:'solid',
                        borderWidth:'1px',
                        fontWeight: 'bold',
                        color:'#FFFFFF'
                    }}
                    size="tiny">
                    <Link 
                        to={'/clientes'} 
                        style={{textDecoration:"none", color:'white', fontWeight: "bold"}}
                    > Crear cliente</Link>
                </Button>
                </Fragment>
            )
        } else if( eventoCallback === EVENTO_REEMPLAZAR_VEHICULO) {
            return(    
                <Fragment>        
                <Button
                    style={{
                        padding: 20,
                        borderRadius: 0, 
                        backgroundColor:'#FFFFFF',
                        borderColor: '#000000',
                        borderStyle:'solid',
                        borderWidth:'1px',
                        fontWeight: 'bold',
                        color:'#000000'
                    }}
                    size="tiny"
                    content="Cancelar"
                    onClick={() => desactivarModal()}
                />
                <Button 
                    style={{
                        padding: 20,
                        borderRadius: 0, 
                        backgroundColor:'#000000',
                        borderColor: '#000000',
                        borderStyle:'solid',
                        borderWidth:'1px',
                        fontWeight: 'bold',
                        color:'#FFFFFF'
                    }}
                    size="tiny" 
                    content="Aceptar"
                    onClick={() => reemplazar(objeto)}
                />
                </Fragment>
            )
        } else if (evento === INICIAR_SESION) {
            return(            
                <Button 
                    style={{
                        padding: 20,
                        borderRadius: 0, 
                        backgroundColor:'#000000',
                        borderColor: '#000000',
                        borderStyle:'solid',
                        borderWidth:'1px',
                        fontWeight: 'bold',
                        color:'#FFFFFF'
                    }}
                    size="tiny" 
                    content="Aceptar"
                    onClick={() => desactivarModalInicioSesion()}
                />
            )

        } else if(evento === CONSULTA_REDENCION) {
            return(
                <Fragment>
                    <Button
                        style={{
                            padding: 22,
                            borderRadius: 0, 
                            backgroundColor:'#FFFFFF',
                            borderColor: '#000000',
                            borderStyle:'solid',
                            borderWidth:'1px',
                            fontWeight: 'bold',
                            color:'#000000'
                        }}
                        size="tiny"
                        content="Cancelar"
                        onClick={() => desactivarModal()}
                    />
                    <Link 
                        style={{
                            padding: 20,
                            borderRadius: 0, 
                            backgroundColor:'#000000',
                            borderColor: '#000000',
                            borderStyle:'solid',
                            borderWidth:'1px',
                            fontWeight: 'bold',
                            color:'#FFFFFF'
                        }}
                        to={`/serviciosRedimir/${id}`}
                        size="tiny"
                    > Ver </Link>
                </Fragment>            )
        }  else {
            return (
                <Button 
                    style={{
                        padding: 20,
                        borderRadius: 0, 
                        backgroundColor:'#000000',
                        borderColor: '#000000',
                        borderStyle:'solid',
                        borderWidth:'1px',
                        fontWeight: 'bold',
                        color:'#FFFFFF'
                    }}
                    size="tiny" 
                    content="Aceptar"
                    onClick={() => desactivarModal()}
                />
            )
        }
    }
    
    return(
        <Modal
            size="tiny"
            open={estadoModal}
            style={{ borderRadius: 0, paddingTop: 20, paddingBottom: 20 }}
        >
            <Modal.Header 
                style={{ 
                    backgroundColor:'#FFFFFF', 
                    border:'none'}} 
                as="h5">
                <Header>
                    Información 
                </Header>
            </Modal.Header>
            <Modal.Content>
                <p>{mensaje}</p>
            </Modal.Content>
            <Modal.Actions style={{ backgroundColor:'#FFFFFF', border:'none', textAlign:'left'}}>
                {botonCallBack()}
            </Modal.Actions>
        </Modal>
    )
}

export default ModalMensaje;