import { LOGIN_EXITOSO, CERRAR_SESION, LOGIN_ERROR, LOGIN_ERROR_ACEPTAR} from "../tipos/usuarios"

const initialState = {
    token: localStorage.getItem('token'),
    mensajeActivo: null,
    mensaje: null,
    usuario: null
}

export default function (state = initialState, action) {
    switch(action.type) {
        case LOGIN_EXITOSO:
            return {
                ...state,
                token: localStorage.getItem('token'),
                mensajeActivo: false,
                usuario: action.payload
            }
        case LOGIN_ERROR:
            return {
                mensajeActivo: true,
                mensaje: action.payload
            }
        case LOGIN_ERROR_ACEPTAR: 
            return {
                mensajeActivo: null,
                mensaje: null
            }
        case CERRAR_SESION: 
            return {
                ...state,
                token: localStorage.clear()
            }
    default:
        return state
    }
    
}