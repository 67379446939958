import React from 'react';
import { EVENTO_ACTUALIZAR } from '../../enum/eventos/tipo-evento'
import { Table, Button } from 'semantic-ui-react';
import moment from 'moment-timezone'
import currencyFormatter from 'currency-formatter'

const ItemRepuesto = ({
    setModalEliminar,
    setObjetoEliminar,
    setEvento,
    setModalActivo,
    setRepuesto,
    item
}) => {

    const datosInicialRepuesto = {
        _id: item._id,
        modelo: item.modelo,
        referencia: item.referencia,
        descripcion: item.descripcion,
        pnc: item.pnc,
        activo: item.activo,
        caracteristicas: item.caracteristicas,
        precioVenta: item.precioVenta,
        margen: item.margen,
        precioAnterior: {
            publico: 0,
            concesionario: 0,
            fecha: ''
        } 
    }

    const botonActualizar = () => {
        setRepuesto(datosInicialRepuesto)
        setEvento(EVENTO_ACTUALIZAR)
        setModalActivo(true)
    }

    const botonEliminar = (id) => {
        setObjetoEliminar(id)
        setModalEliminar({
            activo: true
        })
    }
    return ( 
        <Table.Row>
            <Table.Cell> {item.modelo} </Table.Cell>
            <Table.Cell> {item.referencia} </Table.Cell>
            <Table.Cell> {item.descripcion} </Table.Cell>
            <Table.Cell> {item.pnc} </Table.Cell>

            <Table.Cell> {item.caracteristicas.aireAcondicionado} </Table.Cell>
            <Table.Cell> {item.caracteristicas.cilindrada} </Table.Cell>
            <Table.Cell> {item.caracteristicas.tipoCombustible} </Table.Cell>
            <Table.Cell> {item.caracteristicas.transmision} </Table.Cell>

            <Table.Cell> {currencyFormatter.format(item.precioVenta.publico, {code: 'COP', precision: 0}) } </Table.Cell>
            <Table.Cell> {currencyFormatter.format(item.precioVenta.concesionario, {code: 'COP', precision: 0})} </Table.Cell>
            <Table.Cell> {currencyFormatter.format(item.precioAnterior.publico, {code: 'COP', precision: 0})} </Table.Cell>
            <Table.Cell> {currencyFormatter.format(item.precioAnterior.concesionario, {code: 'COP', precision: 0})} </Table.Cell>
            <Table.Cell> { 
                moment(item.precioAnterior.fecha).isValid()
                ? moment(item.precioAnterior.fecha).format("DD/MM/YYYY hh:mm a")
                : 'N/A'
            }   </Table.Cell>

            <Table.Cell>
                <Button
                    icon="edit"
                    style={{backgroundColor:'#BB162B', color:'#F6F6F6'}}
                    size="tiny"
                    onClick={botonActualizar}
                />
                <Button
                    icon="trash"
                    size="tiny"
                    onClick={() => botonEliminar(item._id)}
                />
            </Table.Cell>
        </Table.Row>
     );
}
 
export default ItemRepuesto;