import React, {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import { Grid, Container, Header, Table, Segment} from 'semantic-ui-react'
import { VISTA_ACUERDO_SERVICIOS_PREPAGADOS, VISTA_SERVICIOS_REDIMIR } from '../../enum/eventos/tipo-evento';
import { consultaSolicitudesId } from '../../servicios/solicitudAdquisiciones/solicitud.srv';
import DatosVehiculos from '../../components/tarjetas/datosVehiculo';
import DatosCliente from '../../components/tarjetas/datosCliente';
import ItemServicio from '../../components/itemsListados/item-servicio';
import ModalDetallesServicio from '../../components/modales/modalDetallesServicios';
import Preloader from '../../components/utilidades/preloader';


const ServiciosRedimirIC = () => {

    const evento = VISTA_SERVICIOS_REDIMIR
    const [ id ] = useState(useParams())
    const [ solicitud, setSolicitud ] = useState([])
    const [ cargandoLoader, setCargandoLoader ] = useState(true)
    const [ datosVehiculo, setDatosVehiculo ] = useState({})
    const [ datosCliente, setDatosCliente ] = useState({})
    const [ detallesServicio, setDetallesServicio ] = useState({})
    const [ verDetallesServicios, setVerDetallesServicios ] = useState(false)


    useEffect(() => {
        consultar()
    }, [id])
    
    const consultar = async() => {
        try {
            setCargandoLoader(true);
            const respuesta = await consultaSolicitudesId(id)
            if (respuesta) {
                setSolicitud(respuesta.data.data.datosSolicitud); 
                setDatosVehiculo(respuesta.data.data.datosVehiculo);
                setDatosCliente(respuesta.data.data.datosCliente);
                setCargandoLoader(false);
            }
        } catch (error) {
            
        }
    }

    if(cargandoLoader){
        return <Preloader/>
    }

    return (
        <Grid style={{marginTop:100, marginBottom:50, marginLeft:50, marginRight:50, minHeight:'70vh'}} container>
        <Container fluid>
            <Header 
                as="h1"
                size="huge"
                style={{ 
                    fontSize:50,
                    spacing:'-0.04em',
                    fontWeight:'bold', 
                    fontFamily:'kiaBold', 
                    marginBottom:20, 
                    marginTop:30 }}> 

                    <Header.Content>
                        Servicios a redimir  
                    </Header.Content>
            </Header>
            <Grid columns={2} stretched>
                <DatosVehiculos
                    evento={VISTA_ACUERDO_SERVICIOS_PREPAGADOS}
                    datosVehiculos={datosVehiculo}
                />
                <DatosCliente
                    evento={VISTA_ACUERDO_SERVICIOS_PREPAGADOS}
                    datosCliente={datosCliente}
                />
            </Grid>
            {solicitud.paquetes.length > 0 ?
            <Container fluid style={{overflowX: 'scroll', marginTop: 15}}>
                <Table textAlign="center" celled structured >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell rowSpan="2">NOMBRE</Table.HeaderCell>
                            <Table.HeaderCell rowSpan="2">DESCRIPCION</Table.HeaderCell>
                            <Table.HeaderCell rowSpan="2">TIPO</Table.HeaderCell>
                            <Table.HeaderCell colSpan="2">FRECUENCIA</Table.HeaderCell>
                            <Table.HeaderCell colSpan="2">VIGENCIA</Table.HeaderCell>
                            <Table.HeaderCell colSpan="2"> REDENCION </Table.HeaderCell>
                            <Table.HeaderCell rowSpan="2"> ACCIONES </Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell> KILOMETRAJE</Table.HeaderCell>
                            <Table.HeaderCell> MESES </Table.HeaderCell>
                            <Table.HeaderCell> KILOMETRAJE </Table.HeaderCell>
                            <Table.HeaderCell> MESES </Table.HeaderCell>
                            <Table.HeaderCell> ESTADO </Table.HeaderCell>
                            <Table.HeaderCell> FECHA DE REDENCION</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {solicitud.paquetes.map( (i) => {
                            return i.detallesServicios.map( (j) => {
                                return (
                                <ItemServicio
                                    setVerDetallesServicio={setVerDetallesServicios}
                                    setDetallesServicio={setDetallesServicio}
                                    idSolicitud={id}
                                    evento={evento}
                                    key={j._id}
                                    item={j}
                                />
                                ) 
                            } )
                        } )}
                    </Table.Body>
                </Table>
                </Container>
                :
                <Segment placeholder>
                    <Header as="h5" textAlign="center">
                       No hay servicios para redimir
                    </Header>
                </Segment>
            }
            <ModalDetallesServicio
                setVerDetallesServicio={setVerDetallesServicios}
                idSolicitud={id}
                consultar={consultar}
                verDetallesServicio={verDetallesServicios}
                detallesServicio={detallesServicio}
            />   
        </Container>
    </Grid>
    );

}
 
export default ServiciosRedimirIC;