import moment from "moment"

export const estadoInicialSolicitud = {
    solicitud: {
        concesionario: '',
        numero: '',
        vin: '',
        placa: '',
        transaccion: ''
    },
    concesionario: {
        nombre: '',
        grupo: '',
        cl: ''
    },
    cliente: '',
    vehiculo: '',
    paquetes: '',
    creado: '',
    valor: {
        valorTotal: '',
        iva: '',
        comision: ''
    },
    estado: '',
    actualizado: '',
    resumenEstados: {
        estado: '',
        fecha: '',
        usuario: ''
    },
    soportes: [],
    notificaciones: {
        solicitud_trajeta : {
            enviado: false,
            fecha: ''
        },
        bienvenida: {
            enviado: false,
            fecha: ''
        }
    }

}

export const estadoInicialCreacion = {
    identificacion: {
        numero: '',
        tipo: ''
    },
    concesionario: ''
}

export const estadoInicialSoporte = {
    nombre: '',
    tipo: '',
    url: '',
    extension: '',
    revisado: false,
    comentarios: '',
    fechaCarga: ''
}

export const estadoInicialFiltro = {
    solicitud: {
        concesionario: [],
        numero: '',
        vin: ''
    },
    fecha:{
        desde: moment().subtract(1, 'month').format('yyyy-MM-DD'),
        hasta: moment().add(1,'day').format('yyyy-MM-DD')
    },
    estado: '',
    limite: 10,
    salto: 0

}
	