import { clienteAxios } from '../../config/axios';
import tokenAuth from '../../config/tokenAuth';


const usuarioAutenticado = () => {
    const token = localStorage.getItem('token')
    if(token){
        tokenAuth(token)
    }
}

export const crearVersion = async (payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/version/crear', payload)
        return(respuesta.data)
    } catch (error) {
        return {
            estatus: false,
            resultadoOperacion: 'Ocurrio un error'
        }
    }
}

export const consultaVersion = async (payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/version/consulta', payload)
        return(respuesta.data)
    } catch (error) {
        return {
            estatus: false,
            resultadoOperacion: 'Ocurrio un error'
        }
    }
}

export const eliminarVersion = async (payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/version/eliminar', payload)
        
        return(respuesta.data)
    } catch (error) {
        return {
            estatus: false,
            resultadoOperacion: 'Ocurrio un error'
        }
    }
}

export const actualizarVersion = async (payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/version/actualizar', payload)

        return(respuesta.data)
    } catch (error) {
        return {
            estatus: false,
            resultadoOperacion: 'Ocurrio un error'
        }
    }
}
