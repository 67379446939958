import React, {useState, Fragment, useEffect} from 'react'
import { Modal, Form, Table, Button, Icon, Container  } from 'semantic-ui-react';
import { tipoAireAcondicionadoOpciones } from '../../estadosIniciales/listas/aireAcondicionado-lista';
import { tipoCombustibleOpciones } from '../../estadosIniciales/listas/combustible-lista';
import { tipoTransmisionOpciones } from '../../estadosIniciales/listas/transmision-lista';
import {estadoInicialFiltro} from '../../estadosIniciales/operaciones/estadoInicial.operacion'
import {consultaOperaciones} from '../../servicios/operaciones/operaciones.srv'

const ConsultaModalOperaciones = ({
    actualizar, 
    datosServicio,
    modalOperaciones,
    setDatosServicio,
    setModalOperaciones
}) => {

    const [ datosFiltrado, setDatosFiltrado ] = useState(Object.assign({}, estadoInicialFiltro))
    const [ operaciones, setOperaciones ] = useState([])


    const consultar = async() => {
        const respuesta = await consultaOperaciones(datosFiltrado)
        if(respuesta.data){
            setOperaciones(respuesta.data.operaciones)
        }
    }

    const onChangeDatos = e => {
        setDatosFiltrado({
            ...datosFiltrado,
            [e.target.name]:e.target.value
        })
    }
    const onChangeAplicaPara = (e, {name, value}) => {
        const valorNumerico = parseFloat(value)
        if(name==="cilindrada"){
            setDatosFiltrado({
                ...datosFiltrado,
                aplicaPara: {
                    ...datosFiltrado.aplicaPara,
                    [name]:valorNumerico
                }
            })
        } else {
            setDatosFiltrado({
                ...datosFiltrado,
                aplicaPara: {
                    ...datosFiltrado.aplicaPara,
                    [name]:value
                }
            })
        }
    }

    useEffect(() => {
        consultar(datosFiltrado)
    }, [datosFiltrado])

    const elegirOperacion = (id) => {
        let conjunto = datosServicio.operaciones;
        conjunto.push(id)
        setDatosServicio({
            ...datosServicio,
            operaciones:conjunto
        })
        actualizar(datosServicio)
        setModalOperaciones(false)
    }

    return(
        <Modal
            open={modalOperaciones}
        >
            <Modal.Header> <Icon name="cog"/> Elegir Operaciones </Modal.Header>
            <Modal.Content>
                <Form> 
                    <Form.Group widths="equal">
                        <Form.Input
                            label="Codigo"
                            value={datosFiltrado.codigo}
                            name="codigo"
                            onChange={onChangeDatos}
                        />
                        <Form.Input
                            label="Nombre"
                            value={datosFiltrado.nombre}
                            name="nombre"
                            onChange={onChangeDatos}
                        />
                        <Form.Input
                            type="Number"
                            label="Cilindrada"
                            name="cilindrada"
                            value={datosFiltrado.aplicaPara.cilindrada}
                            onChange={onChangeAplicaPara}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Select
                            clearable
                            label="Aire Acondicionado"
                            name="aireAcondicionado"
                            options={tipoAireAcondicionadoOpciones}
                            value={datosFiltrado.aplicaPara.aireAcondicionado}
                            onChange={onChangeAplicaPara}
                        />
                        <Form.Select
                            clearable
                            label="Tipo Combustible"
                            name="tipoCombustible"
                            options={tipoCombustibleOpciones}
                            value={datosFiltrado.aplicaPara.tipoCombustible}
                            onChange={onChangeAplicaPara}
                        />
                        <Form.Select
                            clearable
                            label="Transmision"
                            name="transmision"
                            options={tipoTransmisionOpciones}
                            value={datosFiltrado.aplicaPara.transmision}
                            onChange={onChangeAplicaPara}
                        />
                    </Form.Group>
                </Form>
                    <Fragment>
                        <Container fluid style={{overflowX: 'scroll'}}>
                        <Table size="small" singleLine celled textAlign="center">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell rowSpan="2">NOMBRE</Table.HeaderCell>
                                    <Table.HeaderCell rowSpan="2">CODIGO</Table.HeaderCell>
                                    <Table.HeaderCell rowSpan="2">HORAS</Table.HeaderCell>
                                    <Table.HeaderCell colSpan="4">APLICA PARA</Table.HeaderCell>
                                    <Table.HeaderCell rowSpan="2">ACCIONES</Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell>AIRE</Table.HeaderCell>
                                    <Table.HeaderCell>COMBUSTIBLE</Table.HeaderCell>
                                    <Table.HeaderCell>TRANSMISION</Table.HeaderCell>
                                    <Table.HeaderCell>CILINDRADA</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {operaciones !== undefined && operaciones.length > 0 ? operaciones.map(i => (
                                    <Fragment key={i._id}>
                                        <Table.Row>
                                            <Table.Cell> {i.nombre} </Table.Cell>
                                            <Table.Cell> {i.codigo} </Table.Cell>
                                            <Table.Cell> {i.horas} </Table.Cell>
                                            <Table.Cell> {i.aplicaPara.aireAcondicionado} </Table.Cell>
                                            <Table.Cell> {i.aplicaPara.tipoCombustible} </Table.Cell>
                                            <Table.Cell> {i.aplicaPara.transmision} </Table.Cell>
                                            <Table.Cell> {i.aplicaPara.cilindrada} </Table.Cell>
                                            <Table.Cell>
                                                <Button
                                                    icon="check"
                                                    size="tiny"
                                                    onClick={() => elegirOperacion(i._id)}
                                                    toggle
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    </Fragment>
                                )): null}
                            </Table.Body>
                        </Table>
                        </Container>
                    </Fragment>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="CANCELAR"
                    icon="times"
                    size="mini"
                    onClick={() => setModalOperaciones(false)}
                    style={{borderRadius: 25}}
                />
            </Modal.Actions>
        </Modal>
    )
}

export default ConsultaModalOperaciones;