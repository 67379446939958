import React, {useState, useEffect} from 'react';
import { Modal, Form, Button, Header} from 'semantic-ui-react';
import { estadoInicialClientes } from '../../estadosIniciales/clientes/estadoInicial-clientes';
import { tipoCallePrincipalOpciones } from '../../estadosIniciales/listas/direccion-lista.js';
import { tipoIdentificacionOpciones }  from '../../estadosIniciales/listas/identificacion-lista'
import Mensaje from '../alertas/alertas.camposObligatorios';




const ModalEdicionCliente = ({
    cliente,
    editarCliente,
    ActualizarCliente,
    setEditarCliente
}) => {


    const [ datosCliente, setDatosCliente ]  = useState(Object.assign({}, estadoInicialClientes))
    const [ mensaje, setMensaje ] = useState(false)

    useEffect(() => {
        if(cliente){
            setDatosCliente(cliente)
        }
    }, [cliente])


    const onChangeEmail = e => {
        setDatosCliente({
            ...datosCliente,
            [e.target.name] : e.target.value
        })
    }
    const onChangeIdentificacion = (e, {name, value}) => {
        setDatosCliente({
            ...datosCliente,
            identificacion:{
                ...datosCliente.identificacion,
                [name] : value
            }
            
        })
    }
    const onChangeDireccion = (e, {name, value}) => {
        setDatosCliente({
            ...datosCliente,
            direccion: {
                ...datosCliente.direccion,
                [name] : value
            }
            
        })
    }
    const onChangeNombre = e => {
        setDatosCliente({
            ...datosCliente,
            nombre: {
                ...datosCliente.nombre,
                [e.target.name] : e.target.value
            }
            
        })
    }
    const onChangeTelefonos = e => {
        setDatosCliente({
            ...datosCliente,
            telefonos: {
                ...datosCliente.telefonos,
                [e.target.name] : e.target.value
            }
        })
    }

    const actualizar = () => {
        if(
            datosCliente.email.trim() === '' ||
            datosCliente.nombre.nombres.trim() === ''||
            datosCliente.nombre.apellidos.trim() === ''||
            datosCliente.identificacion.numero.trim() === ''||
            datosCliente.identificacion.tipo.trim() === ''||
            datosCliente.direccion.callePrincipal.trim() === ''||
            datosCliente.direccion.calleSecundaria.trim() === ''||
            datosCliente.direccion.numero.trim() === ''||
            datosCliente.direccion.barrio.trim() === ''||
            datosCliente.direccion.ciudad.trim() === ''||
            datosCliente.direccion.departamento.trim() === ''||
            datosCliente.telefonos.celular.trim() === ''
        ) {
            setMensaje(true)
            return
        } else {
            setMensaje(false)
            ActualizarCliente(datosCliente)
        }
    }

    return(
        <Modal
            open={editarCliente}
            style={{ borderRadius: 0, paddingTop: 20, paddingBottom: 20 }}
        >
        <Modal.Header 
            style={{ 
                backgroundColor:'#FFFFFF', 
                border:'none'}} > 
                <Header>
                    Editar Cliente 
                </Header>
        </Modal.Header>
        <Modal.Content>
            <Form>
                <h3>Nombres</h3>
                <Form.Group widths="equal">
                    <Form.Input 
                        label= 'Nombres'
                        name="nombres"
                        value={datosCliente.nombre.nombres} 
                        onChange={onChangeNombre}    
                    />
                    <Form.Input 
                        label= 'Apellidos' 
                        name="apellidos"
                        value={datosCliente.nombre.apellidos} 
                        onChange={onChangeNombre}
                    />
                </Form.Group>
                <h3>Identificación</h3>
                <Form.Group widths="equal">
                    <Form.Select 
                        options={tipoIdentificacionOpciones} 
                        label= 'Tipo de Documento'
                        name='tipo' 
                        onChange={onChangeIdentificacion}
                        value={datosCliente.identificacion.tipo}    
                    />
                    <Form.Input 
                        label= 'Numero'
                        name= 'numero'
                        value= {datosCliente.identificacion.numero}
                        onChange={onChangeIdentificacion}
                    />
                </Form.Group>
                <h3>Datos de Contactos</h3>
                <Form.Group widths="equal">
                    <Form.Input 
                        label='Celular'
                        name='celular'
                        value={datosCliente.telefonos.celular}
                        onChange={onChangeTelefonos}
                    />
                    <Form.Input 
                        label= 'fijo'
                        name="fijo"
                        value={datosCliente.telefonos.fijo}
                        onChange={onChangeTelefonos}
                    />
                    <Form.Input 
                        label= 'Correo Electrónico'
                        name="email"
                        value={datosCliente.email}
                        onChange={onChangeEmail} 
                    />
                    <Form.Input 
                        label= 'Otros'
                        name= 'otros'
                        value= {datosCliente.telefonos.otros}
                        onChange={onChangeTelefonos} 
                    />   
                </Form.Group>
                <h3>Dirección</h3>
                <Form.Group widths="equal">
                    <Form.Select
                        options={tipoCallePrincipalOpciones}
                        label='Calle Principal'
                        value={datosCliente.direccion.callePrincipal}
                        name="callePrincipal"
                        onChange={onChangeDireccion}
                    />
                    <Form.Input 
                        label= 'Calle secundaria'
                        name= 'calleSecundaria'
                        value={datosCliente.direccion.calleSecundaria}
                        onChange={onChangeDireccion}
                    />
                    <Form.Input 
                        label='Numero'
                        name='numero'
                        value={datosCliente.direccion.numero}
                        onChange={onChangeDireccion}
                    />
                    <Form.Input 
                        label='Otros'
                        name='otros'
                        value={datosCliente.direccion.otros}
                        onChange={onChangeDireccion}
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Input 
                        label= 'Departamento'
                        name= 'departamento'
                        value= {datosCliente.direccion.departamento}
                        onChange= {onChangeDireccion}
                    />
                    <Form.Input 
                        label= 'Ciudad'
                        name= 'ciudad'
                        value= {datosCliente.direccion.ciudad}
                        onChange={onChangeDireccion} 
                    />
                    <Form.Input 
                        label= 'Barrio'
                        name= 'barrio'
                        value= {datosCliente.direccion.barrio}
                        onChange={onChangeDireccion} 
                    />
                </Form.Group>
            </Form>
            { mensaje ? <Mensaje /> : null}
        </Modal.Content>
        <Modal.Actions 
            style={{ 
                backgroundColor:'#FFFFFF', 
                border:'none', 
                textAlign:'left'}}>
                <Button
                    style={{
                        padding: 20,
                        borderRadius: 0, 
                        backgroundColor:'#FFFFFF',
                        borderColor: '#000000',
                        borderStyle:'solid',
                        borderWidth:'1px',
                        fontWeight: 'bold',
                        color:'#000000'
                    }}
                    size="tiny" 
                    content="Cancelar"
                    onClick={() => setEditarCliente(false)}
                />
                <Button
                    style={{
                        padding: 20,
                        borderRadius: 0, 
                        backgroundColor:'#000000',
                        borderColor: '#000000',
                        borderStyle:'solid',
                        borderWidth:'1px',
                        fontWeight: 'bold',
                        color:'#FFFFFF'
                    }}
                    size="tiny" 
                    content="Actualizar"
                    onClick={actualizar}
                />
        </Modal.Actions>
        </Modal>
    )
}

export default ModalEdicionCliente;