import React, {useState} from 'react'
import { estadoInicialFiltro } from '../../estadosIniciales/servicios/estadoInicial-servicio';
import { operacionesListado } from '../../estadosIniciales/listas/tipoOperacion-lista';
import {Grid, Segment, Button, Form, Popup, Icon} from 'semantic-ui-react'

const FiltroServicios = ({setFiltro}) => {

    const [ datosFiltrado, setDatosFiltrado ] = useState(Object.assign({}, estadoInicialFiltro))

    const onChangeDatosFiltrado = (e, {name, value}) => {
        setDatosFiltrado({
            ...datosFiltrado,
            [name]:value
        })
    }
    const onChangeDatosFiltradoModelo = e => {
        setDatosFiltrado({
            ...datosFiltrado,
            modelo:{
                ...datosFiltrado.modelo,
                [e.target.name]:e.target.value
            }
        })
    }
    const buttonActivo = () => {
        setDatosFiltrado({
            ...datosFiltrado,
            activo: !datosFiltrado.activo
        })
    }

    const buscarServicios = () => {
        setFiltro(datosFiltrado)
    }

    return ( 
        <Grid style={{ marginTop: 5, marginBottom: 10}}>
            <Grid.Column>
                <Segment
                    size="mini"
                >
                    <Form>
                        <h3>Filtrar Por</h3>
                        <Form.Group
                            widths="equal"
                        >
                            <Form.Input
                                label="Modelo"
                                placeholder="Codigo del modelo"
                                name="codigo"
                                value={datosFiltrado.modelo.codigo}
                                onChange={onChangeDatosFiltradoModelo}
                            />
                            <Form.Input
                                label={
                                    <Popup
                                        content="Si desea filtrar por varias versiones, separelas con una coma"
                                        trigger={
                                            <label>
                                                Versiones
                                                <Icon style={{marginLeft: 2}} name="info circle"/>
                                            </label>
                                        }
                                    />
                                }
                                placeholder="Version del modelo"
                                name="version"
                                value={datosFiltrado.modelo.version}
                                onChange={onChangeDatosFiltradoModelo}
                            />
                            <Form.Input
                                label="Nombre"
                                placeholder="Nombre"
                                name="nombre"
                                value={datosFiltrado.nombre}
                                onChange={onChangeDatosFiltrado}
                            />
                            <Form.Input
                                label="Descripcion"
                                placeholder="Descripcion"
                                name="descripcion"
                                value={datosFiltrado.descripcion}
                                onChange={onChangeDatosFiltrado}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Select
                                clearable
                                width="4"
                                label="Tipo"
                                placeholder="Tipo"
                                name="tipo"
                                options={operacionesListado}
                                value={datosFiltrado.tipo}
                                onChange={onChangeDatosFiltrado}
                            />
                            <Form.Field>
                                <label>Estado de Servicios</label>
                                <Form.Checkbox
                                    checked={datosFiltrado.activo}
                                    toggle
                                    style={{marginBottom: 5, marginTop: 5, fontWeight: "bold"}}
                                    label={datosFiltrado.activo ? "Activos" : "Inactivos"}
                                    name="activo"
                                    onChange={() => buttonActivo()}
                                />
                            </Form.Field>
                        </Form.Group>
                            <span>
                                <Button
                                    style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                                    size="tiny"
                                    icon="search"
                                    content="BUSCAR"
                                    onClick={() => buscarServicios()}
                                />
                            </span>
                    </Form>
                </Segment>
            </Grid.Column>
        </Grid>
     );
}
 
export default FiltroServicios;