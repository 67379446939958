import React, { Fragment, useState } from 'react';
import {
        Image, 
        Icon, 
        Dropdown,
        Responsive,
        Grid,
        Label
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'  
import { cerrarSesion } from '../../servicios/usuarios/autenticacionUsuario.srv';
import { TIPO_ROL } from '../../enum/tipo-rol';
import ModalEliminar from '../modales/modalEliminar';
import { obtenerPaqueteEliminar, eliminarPaquete } from '../../actions/carrito.action';
import { EVENTO_CARRITO_COMPRA } from '../../enum/eventos/tipo-evento';
import CurrencyFormatter from 'currency-formatter';

const NavBarComponent = () => {
    const [ verAdministrador, setVerAdministrador] = useState(false)
    const [ verMenuResponsive, setVerMenuResponsive ] = useState(false)
    const dispatch = useDispatch()
    const carritoDeCompra = useSelector( state => state.carrito.carritoDeCompra)
    const [ modalEliminar, setModalEliminar ] = useState(false)
    const evento = EVENTO_CARRITO_COMPRA
    const rol = localStorage.getItem('rol')


    const onCerrarSesion = () => {
        dispatch(cerrarSesion())

    }
    const paqueteAEliminar = (id) => {
        setModalEliminar(true)
        dispatch(obtenerPaqueteEliminar(id))
    }
    const EliminarPaquete = () => {
        dispatch(eliminarPaquete())
    }
    const modalEliminarContenido = {
        titulo: 'Eliminar Paquete del Carrito',
        mensaje: `¿Esta seguro que quiere eliminar el paquete?`
    }
    

    return ( 
        <Fragment>
            <Responsive minWidth={Responsive.onlyTablet.minWidth} >
                <Grid>
                    <Grid.Row 
                        columns={3} 
                        style={{ 
                            marginTop: 10,
                            marginBottom: 10,
                            padding:20,
                            borderBottomStyle:'solid', 
                            borderBottomColor:'#999999', 
                            borderBottomWidth:'0.5px'}} stretched>

                        <Grid.Column verticalAlign="middle">
                        
                            <Grid.Row>
                                <Link 
                                    to={{pathname:'/Home'}}
                                    style={{
                                        marginRight:40,
                                        marginLeft:40,
                                        textDecoration: 'none',
                                        borderColor:'#666666',
                                        fontFamily: 'kiaBold',
                                        color:'#000000'
                                }}>Principal</Link>

                                <Link 
                                    to={{pathname:'/quotation'}}
                                    style={{
                                        marginRight:40,
                                        textDecoration: 'none',
                                        borderColor:'#666666',
                                        fontFamily: 'kiaBold',
                                        color:'#000000'
                                }}>Paquetes</Link>

                                <Link 
                                    to={'/nuevaSolicitud'}
                                    style={{
                                        marginRight:40,
                                        textDecoration: 'none',
                                        borderColor:'#666666',
                                        fontFamily: 'kiaBold',
                                        color:'#000000'
                                }}>Solicitud</Link>

                            </Grid.Row>

                        </Grid.Column>
                        <Grid.Column textAlign="center" verticalAlign="middle" >
                            <Image style={{ width: 70, margin:'auto'}} src={ require('../../assets/img/logos/logo-kia-oscuro-fondo-blanco.png')}  />
                        </Grid.Column>
                        <Grid.Column verticalAlign="middle">

                            <Grid.Row textAlign="right" style={{ textAlign:'right'}}>
                                <Link 
                                    to={'/redention'}
                                    style={{
                                    marginLeft:40,
                                    textDecoration: 'none',
                                    borderColor:'#666666',
                                    fontFamily: 'kiaBold',
                                    color:'#000000'
                                }}>Redención</Link>

                                <Link 
                                    onClick={()=> setVerAdministrador( !verAdministrador)}
                                    style={{
                                        marginLeft:40,
                                        marginRight: 40,
                                        textDecoration: 'none',
                                        borderColor:'#666666',
                                        fontFamily: 'kiaBold',
                                        color:'#000000'
                                }}>Administración</Link>

                                
                                    <Dropdown 
                                        icon={<Icon.Group>
                                                <Icon size="large" name="cart"/>
                                                <Label color="red" circular> {carritoDeCompra.length} </Label>
                                            </Icon.Group>} 
                                    item simple>
                                        
                                        <Dropdown.Menu direction="left">
                                            {carritoDeCompra.map((paquete, index) => (
                                                <Dropdown.Item key={index} onClick={() => paqueteAEliminar(paquete._id)}>
                                                    <p style={{
                                                        fontFamily: 'kiaBold',
                                                        fontSize: 16,
                                                        letterSpacing:'0.005em'
                                                    }}>{paquete.nombre}</p>
                                                    <p style={{
                                                        marginTop: '-20px',
                                                        fontFamily: 'kiaMedium',
                                                        fontSize: 12,
                                                        letterSpacing:'0.005em',
                                                        color: '#666666'
                                                    }}>
                                                        {CurrencyFormatter.format(paquete.precios.prepagado.valorFuturo, { code:'COP', precision: 0 })}
                                                    </p>
                                                </Dropdown.Item>
                                            ))}
                                            <Dropdown.Divider></Dropdown.Divider>
                                            {carritoDeCompra.length === 0 ? <Dropdown.Item> 
                                                Aun no hay items en su carrito de compra
                                            </Dropdown.Item>: null}
                                            <Dropdown.Item> 
                                                <Link 
                                                    style={{
                                                        textDecoration: 'none',
                                                        borderColor:'#666666',
                                                        fontFamily: 'kiaBold',
                                                        color:'#000000'
                                                    }}
                                                    primary="true"
                                                    size="tiny"
                                                    to={'/nuevaSolicitud'}
                                                    >
                                                    Ir a Solicitud 
                                                    <Icon name="chevron right" />
                                                </Link>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                            </Grid.Row>
                        </Grid.Column>

                    </Grid.Row>
                    { verAdministrador ? <Grid.Row style={{ padding:20, marginBootom: 10 }}>
                        <Grid.Column verticalAlign="middle">
                            <Grid.Row>
                                <Link 
                                    to={'/listaSolicitudes'}
                                    style={{
                                        marginRight:40,
                                        marginLeft:40,
                                        textDecoration: 'none',
                                        borderColor:'#666666',
                                        fontFamily: 'kiaBold',
                                        color:'#000000'
                                }}>Solicitudes</Link>
                                <Link 
                                    to={'/models'}
                                    style={{
                                        marginRight:40,
                                        marginLeft:40,
                                        textDecoration: 'none',
                                        borderColor:'#666666',
                                        fontFamily: 'kiaBold',
                                        color:'#000000'
                                }}>Modelos</Link>
                                <Link 
                                    to={'/users'}
                                    style={{
                                        marginRight:40,
                                        marginLeft:40,
                                        textDecoration: 'none',
                                        borderColor:'#666666',
                                        fontFamily: 'kiaBold',
                                        color:'#000000'
                                }}>Usuarios</Link>
                                
                                <Link 
                                    to={'/temparioNormal'}
                                    style={{
                                        marginRight:40,
                                        marginLeft:40,
                                        textDecoration: 'none',
                                        borderColor:'#666666',
                                        fontFamily: 'kiaBold',
                                        color:'#000000'
                                }}>Tempario ordinario</Link>
                                <Link 
                                    to={'/reports'}
                                    style={{
                                        marginRight:40,
                                        marginLeft:40,
                                        textDecoration: 'none',
                                        borderColor:'#666666',
                                        fontFamily: 'kiaBold',
                                        color:'#000000'
                                }}>Reportes</Link>
                                <Dropdown item simple style={{
                                        marginRight:40,
                                        marginLeft:40,
                                        textDecoration: 'none',
                                        borderColor:'#666666',
                                        fontFamily: 'kiaBold',
                                        color:'#000000'}} text="Configuraciones">
                                    <Dropdown.Menu>
                                        {rol === TIPO_ROL.ADMINISTRADOR_CONCESIONARIO || rol === TIPO_ROL.ASESOR ? null :<Dropdown.Item > 
                                            <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/models'}>Modelos</Link>
                                        </Dropdown.Item>}
                                        {/* {rol === TIPO_ROL.ADMINISTRADOR_CONCESIONARIO || rol === TIPO_ROL.ASESOR ? null :<Dropdown.Item>
                                            <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/vehiculos'}>Vehiculos</Link>
                                        </Dropdown.Item>} */}
                                        {/* <Dropdown.Item > 
                                            <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/reports'}>Reportes</Link>
                                        </Dropdown.Item> */}
                                        {rol === TIPO_ROL.ADMINISTRADOR_CONCESIONARIO || rol === TIPO_ROL.ASESOR ? null :<Dropdown.Item>
                                            <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/parts'}>Repuestos</Link>
                                        </Dropdown.Item>}
                                        <Dropdown.Divider />
                                        <Dropdown.Header></Dropdown.Header>
                                        {/* <Dropdown.Item>
                                            <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/listaSolicitudes'}><Icon name="clipboard"/> Solicitudes</Link>
                                        </Dropdown.Item> */}
                                        {/* {rol === TIPO_ROL.ASESOR ? null :<Dropdown.Item>
                                            <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/users'}><Icon name="users"/> USUARIOS</Link>
                                        </Dropdown.Item>} */}

                                        <Dropdown.Item>
                                        <i className='dropdown icon' />
                                        <span className='text'>Prepagado</span>
                                        <Dropdown.Menu>
                                            {rol === TIPO_ROL.IMPORTADORA || rol === TIPO_ROL.ADMINISTRADOR_SISTEMA ? <Dropdown.Item>
                                                <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/versiones'}>Versiones</Link>
                                            </Dropdown.Item> : null}
                                            {rol === TIPO_ROL.ADMINISTRADOR_CONCESIONARIO || rol === TIPO_ROL.ASESOR ? null :<Dropdown.Item>
                                                <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/dealers'}>Distribuidores</Link>
                                            </Dropdown.Item>}
                                            {rol === TIPO_ROL.ASESOR ? null :<Dropdown.Item>
                                                <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/temparioNormal'}>Tempario Ordinario</Link>
                                            </Dropdown.Item>}
                                            {rol === TIPO_ROL.ADMINISTRADOR_CONCESIONARIO || rol === TIPO_ROL.ASESOR ? null :<Dropdown.Item>
                                                <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/temparioPrepagado'}>Tempario Prepagado</Link>
                                            </Dropdown.Item>}
                                            <Dropdown.Item>
                                                <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/paquetes'}>Paquetes</Link>
                                            </Dropdown.Item>
                                            {rol === TIPO_ROL.ADMINISTRADOR_CONCESIONARIO || rol === TIPO_ROL.ASESOR ? null :<Dropdown.Item>
                                                <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/servicios'}>Servicios</Link>
                                            </Dropdown.Item>}
                                            {rol === TIPO_ROL.ADMINISTRADOR_CONCESIONARIO || rol === TIPO_ROL.ASESOR ? null :<Dropdown.Item>
                                                <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/operations'}>Operaciones</Link>
                                            </Dropdown.Item>}
                                            
                                            {/* {rol === TIPO_ROL.ADMINISTRADOR_CONCESIONARIO || rol === TIPO_ROL.ASESOR ? null :<Dropdown.Item>
                                                <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/uploadReport'}><Icon name='table'/> Carga reporte</Link>
                                            </Dropdown.Item>} */}
                                        </Dropdown.Menu>
                                        </Dropdown.Item>
                                        {rol === TIPO_ROL.ADMINISTRADOR_CONCESIONARIO || rol === TIPO_ROL.ASESOR ? null :<Dropdown.Item>
                                            <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/variables'}>Variables</Link>
                                        </Dropdown.Item>}
                                        {rol === TIPO_ROL.ADMINISTRADOR_CONCESIONARIO || rol === TIPO_ROL.ASESOR ? null :<Dropdown.Item>
                                            <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/terminosCondiciones'}>Terminos <br></br>& condiciones</Link>
                                        </Dropdown.Item>}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Link
                                    onClick={onCerrarSesion}
                                    to={null}
                                    style={{
                                        marginLeft:40,
                                        marginRight:40,
                                        textDecoration: 'none',
                                        borderColor:'#666666',
                                        fontFamily: 'kiaBold',
                                        color:'#000000'
                                }}>Cerrar sesión</Link>
                            </Grid.Row>
                        </Grid.Column>
                    </Grid.Row>: null }
                </Grid>
                
                {/* <Menu fixed="top" style={{marginTop:30, minHeight:'30px !important', borderBottom:'4px solid #BB162B', height:60, fontFamily:'KiaBold', fontSize:16, color:'#B9072F', textTransform:'uppercase'}} >
                    <Container>
                        <Menu.Item as="header"  >
                            <Image style={{maxWidht:70}} src={ require('../../assets/img/logos/lg_kia.png')}  />
                        </Menu.Item>
                        <Menu.Item  style={{textTransform:'uppercase'}}>
                            <Link style={{textDecoration:'none', color:'#333333'}} to={{pathname:'/Home'}}>PRINCIPAL</Link>
                        </Menu.Item>
                        <Menu.Item style={{textTransform:'uppercase'}}>
                            <Link style={{textDecoration:'none', color:'#333333'}} to={{pathname:'/quotation'}}>Modelos</Link>
                        </Menu.Item>
                        <Menu.Item style={{textTransform:'uppercase'}}>
                            <Link style={{textDecoration:'none', color:'#333333'}} to={'/nuevaSolicitud'}>Nueva Solicitud</Link>
                        </Menu.Item>
                        <Menu.Item style={{textTransform:'uppercase'}}>
                            <Link style={{textDecoration:'none', color:'#333333'}} to={'/redention'}>Redimir</Link>
                        </Menu.Item>
                        {carritoDeCompra.length > 0 ? <Menu.Menu stackable="true" position='right' style={{fontSize:11, fontFamily:'KiaBold', color:'#999999'}}>
                            <Dropdown icon={<Icon.Group>
                                <Icon size="large" name="cart"/>
                                <Label color="red" circular> {carritoDeCompra.length} </Label>
                            </Icon.Group>} item simple>
                                
                                <Dropdown.Menu>
                                    {carritoDeCompra.map(paquete => (
                                        <Dropdown.Item>
                                            {paquete.nombre}
                                            <Button
                                                style={{
                                                    marginLeft: 13,
                                                    marginTop:20,
                                                    backgroundColor: 'transparent',
                                                    borderColor: '#000000'
                                                }}
                                                icon="times"
                                                size="mini"
                                                onClick={() => paqueteAEliminar(paquete._id)}
                                            />
                                        </Dropdown.Item>
                                    ))}
                                    <Dropdown.Divider></Dropdown.Divider>
                                    <Dropdown.Item> 
                                        <strong> Si accede a una solicitud, estos paquetes </strong> <br></br> 
                                        <strong> serán agregados automaticamente</strong>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Menu.Menu>: null}
                    
                    </Container>
                </Menu> */}


                {/* <Menu fixed="top" style={{height:30, backgroundColor:'#F6F6F6'}}>
                    <Container>
                        <Menu.Menu stackable="true" position='right' style={{fontSize:11, fontFamily:'KiaBold', color:'#999999'}}>
                            <Menu.Item style={{textTransform:'uppercase'}}> {nombreUsuario} </Menu.Item>
                            <Dropdown item simple text="ADMINISTRACIÓN">
                                <Dropdown.Menu>
                                    {rol === TIPO_ROL.ADMINISTRADOR_CONCESIONARIO || rol === TIPO_ROL.ASESOR ? null :<Dropdown.Item> 
                                        <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/models'}><Icon name='car'/> MODELOS</Link>
                                    </Dropdown.Item>}
                                    {rol === TIPO_ROL.ADMINISTRADOR_CONCESIONARIO || rol === TIPO_ROL.ASESOR ? null :<Dropdown.Item>
                                        <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/vehiculos'}><Icon name="car"/>VEHICULOS</Link>
                                    </Dropdown.Item>}
                                    <Dropdown.Item> 
                                        <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/reports'}><Icon name='chart line'/> REPORTES</Link>
                                    </Dropdown.Item>
                                    {rol === TIPO_ROL.ADMINISTRADOR_CONCESIONARIO || rol === TIPO_ROL.ASESOR ? null :<Dropdown.Item>
                                        <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/parts'}><Icon name='cart'/> REPUESTOS</Link>
                                    </Dropdown.Item>}
                                    <Dropdown.Divider />
                                    <Dropdown.Header></Dropdown.Header>
                                    <Dropdown.Item>
                                        <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/listaSolicitudes'}><Icon name="clipboard"/> SOLICITUDES</Link>
                                    </Dropdown.Item>
                                    {rol === TIPO_ROL.ASESOR ? null :<Dropdown.Item>
                                        <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/users'}><Icon name="users"/> USUARIOS</Link>
                                    </Dropdown.Item>}

                                    <Dropdown.Item>
                                    <i className='dropdown icon' />
                                    <span className='text'>PREPAGADO</span>
                                    <Dropdown.Menu>
                                        {rol === TIPO_ROL.IMPORTADORA || rol === TIPO_ROL.ADMINISTRADOR_SISTEMA ? <Dropdown.Item>
                                            <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/versiones'}><Icon name='barcode'/> VERSIONES</Link>
                                        </Dropdown.Item> : null}
                                        {rol === TIPO_ROL.ADMINISTRADOR_CONCESIONARIO || rol === TIPO_ROL.ASESOR ? null :<Dropdown.Item>
                                            <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/dealers'}><Icon name='building'/> DISTRIBUIDORES</Link>
                                        </Dropdown.Item>}
                                        {rol === TIPO_ROL.ASESOR ? null :<Dropdown.Item>
                                            <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/temparioNormal'}><Icon name='clock outline'/> TEMPARIO</Link>
                                        </Dropdown.Item>}
                                        {rol === TIPO_ROL.ADMINISTRADOR_CONCESIONARIO || rol === TIPO_ROL.ASESOR ? null :<Dropdown.Item>
                                            <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/temparioPrepagado'}><Icon name='clock outline'/> TEMPARIO PRE</Link>
                                        </Dropdown.Item>}
                                        <Dropdown.Item>
                                            <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/paquetes'}><Icon name='road'/> PAQUETES</Link>
                                        </Dropdown.Item>
                                        {rol === TIPO_ROL.ADMINISTRADOR_CONCESIONARIO || rol === TIPO_ROL.ASESOR ? null :<Dropdown.Item>
                                            <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/servicios'}><Icon name='cogs'/> SERVICIOS</Link>
                                        </Dropdown.Item>}
                                        {rol === TIPO_ROL.ADMINISTRADOR_CONCESIONARIO || rol === TIPO_ROL.ASESOR ? null :<Dropdown.Item>
                                            <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/operations'}><Icon name='currency'/>OPERACIONES</Link>
                                        </Dropdown.Item>}
                                        
                                        {rol === TIPO_ROL.ADMINISTRADOR_CONCESIONARIO || rol === TIPO_ROL.ASESOR ? null :<Dropdown.Item>
                                            <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/uploadReport'}><Icon name='table'/> CARGAR REPORTE RETAIL</Link>
                                        </Dropdown.Item>}
                                    </Dropdown.Menu>
                                    </Dropdown.Item>
                                    {rol === TIPO_ROL.ADMINISTRADOR_CONCESIONARIO || rol === TIPO_ROL.ASESOR ? null :<Dropdown.Item>
                                        <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/variables'}><Icon name='database'/> VARIABLES</Link>
                                    </Dropdown.Item>}
                                    {rol === TIPO_ROL.ADMINISTRADOR_CONCESIONARIO || rol === TIPO_ROL.ASESOR ? null :<Dropdown.Item>
                                        <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/terminosCondiciones'}><Icon name='privacy'/>TERMINOS <br></br>& CONDICIONES</Link>
                                    </Dropdown.Item>}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Menu.Item onClick={onCerrarSesion} icon="log out"></Menu.Item>
                        </Menu.Menu>
                    </Container>
                </Menu> */}
                
            </Responsive>


            <Responsive {...Responsive.onlyMobile}>
                <Grid 
                    textAlign="center"
                    verticalAlign='middle'
                    
                    style={{ 
                        marginTop: 5,
                        marginBottom: 5,
                        padding:10,
                        borderBottomStyle:'solid', 
                        borderBottomColor:'#999999', 
                        borderBottomWidth:'0.5px'}} stretched>

                        <Grid.Column width={4}></Grid.Column>

                        <Grid.Column width={8}>
                            <Image style={{ width: 70, margin:'auto'}} src={ require('../../assets/img/logos/lg_kia.png')}  />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Icon onClick={()=> setVerMenuResponsive( !verMenuResponsive)} name="bars" size='large'/>
                        </Grid.Column>
                </Grid>

                { verMenuResponsive ? <Grid style={{ marginBottom: 20 }}>
                    <Grid.Row>
                        <Link 
                            to={{pathname:'/Home'}}
                            style={{
                                marginRight:40,
                                marginLeft:40,
                                textDecoration: 'none',
                                borderColor:'#666666',
                                // borderBottomStyle:'solid',
                                fontFamily: 'kiaBold',
                                color:'#000000'
                        }}>Principal</Link>
                    </Grid.Row>
                    <Grid.Row>
                        <Link 
                            to={{pathname:'/quotation'}}
                            style={{
                                marginRight:40,
                                marginLeft:40,
                                textDecoration: 'none',
                                borderColor:'#666666',
                                // borderBottomStyle:'solid',
                                fontFamily: 'kiaBold',
                                color:'#000000'
                        }}>Paquetes</Link>
                    </Grid.Row>
                    <Grid.Row>
                        <Link 
                            to={'/nuevaSolicitud'}
                            style={{
                                marginRight:40,
                                marginLeft:40,
                                textDecoration: 'none',
                                borderColor:'#666666',
                                // borderBottomStyle:'solid',
                                fontFamily: 'kiaBold',
                                color:'#000000'
                        }}>Solicitud</Link>
                    </Grid.Row>

                    <Grid.Row>
                        <Link 
                            to={'/redention'}
                            style={{
                            marginLeft:40,
                            textDecoration: 'none',
                            borderColor:'#666666',
                            // borderBottomStyle:'solid',
                            fontFamily: 'kiaBold',
                            color:'#000000'
                        }}>Redencion</Link>
                    </Grid.Row>

                    <Grid.Row>
                        <Link
                            to
                            onClick={()=> setVerAdministrador( !verAdministrador)}
                            style={{
                                marginLeft:40,
                                textDecoration: 'none',
                                borderColor:'#666666',
                                fontFamily: 'kiaBold',
                                color:'#000000'
                        }}>Administracion
                        </Link>
                    </Grid.Row>
                    <Grid.Row>
                        <Link
                            onClick={onCerrarSesion}
                            style={{
                                marginLeft:40,
                                marginRight:40,
                                textDecoration: 'none',
                                borderColor:'#666666',
                                fontFamily: 'kiaBold',
                                color:'#000000'
                        }}>Cerrar sesion</Link>
                    </Grid.Row>
                </Grid> : null }
            </Responsive>
            {/* <Dimmer style={{}}  page inverted>
                    <Image centered style={{ width:"80vw"}} src={logoKPlus} />
                    
                    <Header style={{color:"#454545"}} as='h2' image>
                        <Header.Content style={{color:"#AEAEAE"}}>
                            Mantenimiento <span style={{color:'#bb162b'}}> K </span> Plus
                        </Header.Content>
                    </Header>
                    
                    <List style={{textTransform:'uppercase', textDecoration:'none', width:"80vw", color:"#666666", fontFamily:"KiaBold", textAlign:"left"}} size="massive" divided verticalAlign="middle">
                        <List.Item to="/Home" as={Link} >
                            <List.Content floated="right">
                                <Icon name="chevron circle right" />
                            </List.Content>
                            <List.Icon name="home" />
                            <List.Content style={{color:"#666666"}}>Principal</List.Content>
                        </List.Item>
                        <List.Item as={Link} to={"/quotation"} >
                            <List.Content floated="right">
                                <Icon name="chevron circle right" />
                            </List.Content>
                            <List.Icon name="calculator" />
                            <List.Content style={{color:"#666666"}}>Cotizador</List.Content>
                        </List.Item>
                        <List.Item as={Link} to={'/orders'} >
                            <List.Content floated="right">
                                <Icon name="chevron circle right" />
                            </List.Content>
                            <List.Icon name="box" />
                            <List.Content style={{color:"#666666"}}>Paquetes</List.Content>
                        </List.Item>
                        <List.Item as={Link} to={'/form'} >
                            <List.Content floated="right">
                                <Icon name="chevron circle right" />
                            </List.Content>
                            <List.Icon name="wpforms"/>
                            <List.Content style={{color:"#666666"}}>Formulario</List.Content>
                        </List.Item>
                        <List.Item as={Link} to={'/redention'} >
                            <List.Content floated="right">
                                <Icon name="chevron circle right" />
                            </List.Content>
                            <List.Icon name="redo" />
                            <List.Content style={{color:"#666666"}}>Redimir</List.Content>
                            
                        </List.Item>
                    </List>
                    <List style={{textTransform:'uppercase', width:"80vw", color:"#666666", fontFamily:"KiaBold", textAlign:"left"}} size="massive" divided verticalAlign="middle">
                        
                        
                        <List.Item >
                            <List.Content floated="right">
                                <Icon name="chevron circle right" />
                            </List.Content>
                            <List.Icon name="sign-out" />
                            <List.Content>Cerrar Sesión</List.Content>
                        </List.Item>
                    </List>
                    <Button icon="times" style={{borderRadius:25, backgroundColor:"#bb162b", color:"#f6f6f6"}} size="large" />
            </Dimmer> */}

            <ModalEliminar
                evento={evento}
                modalEliminarActivo={modalEliminar}
                contenido={modalEliminarContenido}
                eliminarPaquete={EliminarPaquete}
                setModalEliminar={setModalEliminar}
            />
        </Fragment>
     );
}
 
export default NavBarComponent;