export const estadoInicialRepuestos = {
    modelo: '',
    pnc: '',
    referencia: '',
    descripcion: '',
    activo: true,
    caracteristicas: {
        aireAcondicionado: '',
        cilindrada: '',
        tipoCombustible: '',
        transmision: ''
    },
    precioVenta: {
        publico: '',
        concesionario: ''
    },
    margen: {
        precioVentaConcesionario: '',
        precioVentaPublico: ''
    },
    precioAnterior: {
        publico: '',
        concesionario: '',
        fecha: ''
    }
}


export const estadoInicialFiltro = {
    activo: true,
    modelo: '',
    pnc: '',
    referencia: '',
    descripcion: '',
    salto:0,
    limite: 20
}