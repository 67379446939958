export const estadoInicialModelos = {
    activo: true,
    factorAjusteManoObra: '',
    versiones: [],
    modelo: {
        codigo: '',
        nombre: '',
        origen: [],
        color: '',
        anoModelo: ''
    },
    imagenes: {
        primera: {
            extension: '',
            nombre: '',
            relacionAspecto: '',
            tamano: '',
            url: ''
        },
        segunda: {
            extension: '',
            nombre: '',
            relacionAspecto: '',
            tamano: '',
            url: ''
        },
        tercera: {
            extension: '',
            nombre: '',
            relacionAspecto: '',
            tamano: '',
            url: ''
        },
        cuarta: {
            extension: '',
            nombre: '',
            relacionAspecto: '',
            tamano: '',
            url: ''
        }
    }
}

export const estadoInicialVersion = {
    codigo: '',
    descripcion: ''
}

