import React from 'react';
import { Grid, Image, Segment, Header, Icon} from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const SeleccionModelos = ({
    modelo,
}) => {

    return ( 
        <Grid.Column key={modelo.imagenes.primera.url}>
            <Segment basic>
                <Image size="huge" src={modelo.imagenes.primera.url} alt="Imagen vehiculo"/>
                <Header as="h2">
                    <Header.Content>
                        {modelo.modelo.nombre}
                        <Header.Subheader>
                            Paquetes Prepagado
                        </Header.Subheader>
                    </Header.Content>
                </Header>
                <Link
                    style={{ 
                        backgroundColor:'transparent', 
                        padding:0, 
                        fontWeight:'bold', 
                        fontFamily:'kiaBold',
                        marginRight: 20,
                        color:'#000000', 
                        hover:{ borderBottom: 'solid', borderColor:'red' } }}
                    to={{pathname:`/vistaModelos/${modelo._id}`}}> 
                    <strong>Ver</strong><Icon name="chevron right" />
                </Link>
            </Segment>
        </Grid.Column>
     );
}
 
export default SeleccionModelos;