import React, { Fragment } from 'react';
import { EVENTO_ACTUALIZAR, EVENTO_VER_DETALLES, EVENTO_EDITAR_DESDE_DETALLES } from '../../enum/eventos/tipo-evento'
import { Table, Button } from 'semantic-ui-react';

const ItemOperaciones = ({
    setModalRepuestos,
    setModalEliminar,
    setObjetoEliminar,
    setEvento,
    setModalActivo,
    setOperacion,
    eliminarOperacion,
    evento,
    index,
    item
}) => {
    const botonActualizar = () => {
        setModalActivo(true)
        setEvento(EVENTO_ACTUALIZAR)
        setOperacion(item)
    }

    const botonEliminar = () => {
        setObjetoEliminar(item._id)
        setModalEliminar({
            activo: true
        })
    }

    const verRepuestos = () => {
        setEvento(EVENTO_VER_DETALLES)
        setOperacion(item)
        setModalRepuestos(true)
    }

    
    return ( 
        <Table.Row>
            <Table.Cell> {item.nombre} </Table.Cell>
            <Table.Cell> {item.codigo} </Table.Cell>
            <Table.Cell> {item.horas} </Table.Cell>
            <Table.Cell> {item.aplicaPara.aireAcondicionado} </Table.Cell>
            <Table.Cell> {item.aplicaPara.tipoCombustible} </Table.Cell>
            <Table.Cell> {item.aplicaPara.transmision} </Table.Cell>
            <Table.Cell> {item.aplicaPara.cilindrada} </Table.Cell>
            <Table.Cell singleLine={true}>
                {evento === EVENTO_EDITAR_DESDE_DETALLES ?
                <Button
                    icon="trash"
                    onClick={() => eliminarOperacion(index)}
                    size="tiny"
                />
                :
                <Fragment>
                <Button
                icon="car"
                // content="Repuestos"
                onClick={verRepuestos}
                size="tiny"
                />
                <Button
                    icon="trash"
                    size="tiny"
                    onClick={botonEliminar}
                />
                <Button
                    icon="edit"
                    onClick={botonActualizar }
                    style={{backgroundColor:'#BB162B', color:'#F6F6F6'}}
                    size="tiny"
                />
                </Fragment>
                }

            </Table.Cell>
        </Table.Row>
    )
}

export default ItemOperaciones;