import React from 'react';
import { Form, Button, Icon, Modal } from 'semantic-ui-react';
import {tipoPaquete} from '../../estadosIniciales/listas/paquete-listas';



const FormularioPaquetes = ({
    modalActivo,
    datosPaquete,
    guardar,
    setDatosPaquete,
    setModalActivo,
    setEvento
}) => {



    const onChangeDatosPaquete = (e, {name, value}) => {
        setDatosPaquete({
            ...datosPaquete,
            [name]:value
        })
    }
    const onChangeDatosModelo = e => {
        setDatosPaquete({
            ...datosPaquete,
            modelo:{
                ...datosPaquete.modelo,
                [e.target.name]:e.target.value
            }
        })
    }
    const desactivarModal = () => {
        setModalActivo(false)
        setEvento('')
    }

    const guardarPaquetes = () => {
        guardar(datosPaquete)
    }

    return(
            <Modal
                open={modalActivo}
            >
                <Modal.Header><Icon color="red" name="edit"/>Formulario Paquetes</Modal.Header>
                <Modal.Content>
                    <Form>
                        <h3>Información Del Paquete</h3>
                        <Form.Group widths="equal">
                            <Form.Input
                                label="Nombre"
                                name="nombre"
                                value={datosPaquete.nombre}
                                onChange={onChangeDatosPaquete}
                            />
                            <Form.Input
                                label="Descripcion"
                                name="descripcion"
                                value={datosPaquete.descripcion}
                                onChange={onChangeDatosPaquete}
                            />
                            <Form.Input
                                label="Codigo"
                                name="codigoPaquete"
                                value={datosPaquete.codigoPaquete}
                                onChange={onChangeDatosPaquete}
                            />
                            <Form.Select
                                label="tipo"
                                name="tipoPaquete"
                                options={tipoPaquete}
                                value={datosPaquete.tipoPaquete}
                                onChange={onChangeDatosPaquete}
                            />
                        </Form.Group>
                        <h3>Modelo Al Que Aplica</h3>
                        <Form.Group widths="equal">
                            <Form.Input
                                label="Codigo"
                                value={datosPaquete.modelo.codigo}
                                name="codigo"
                                width="7"
                                onChange={onChangeDatosModelo}
                            />
                            <Form.Input
                                label="Descripcion"
                                value={datosPaquete.modelo.descripcion}
                                name="descripcion"
                                onChange={onChangeDatosModelo}
                            />
                            <Form.Input
                                name="version"
                                value={datosPaquete.modelo.version}
                                label="Versión"
                                onChange={onChangeDatosModelo}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Content>
                    <Modal.Actions>
                        <Button 
                            content="CANCELAR" 
                            icon="times"
                            size='tiny'
                            onClick={desactivarModal}
                            style={{ borderRadius:25 }}
                        />
                        <Button 
                            style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                            size="tiny" 
                            content="GUARDAR" 
                            onClick={guardarPaquetes}
                            icon="save"
                        />
                    </Modal.Actions>
            </Modal>

    )
}

export default FormularioPaquetes;