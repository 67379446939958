import { clienteAxios } from '../../config/axios';
import tokenAuth from '../../config/tokenAuth';

const usuarioAutenticado = () => {
    const token = localStorage.getItem('token')
    if(token){
        tokenAuth(token)
    }
}

export const consultarModelos = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/modelo/consulta', payload)
        return(respuesta.data)
    } catch (error) {
        return({
            data: {
                estatus: false,
                resultadoOperacion: "Error al consultar modelos"
            }
        })
    }
}

export const crearModelos = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/modelo/crear', payload)
        return(respuesta)
    } catch (error) {
        return({
            data: {
                estatus: false,
                resultadoOperacion: "Datos invalidos"
            }
        })
    }
}

export const actualizarModelos = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/modelo/actualizar', payload)
        return(respuesta)
    } catch (error) {
        return({
            data:{
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}

export const eliminarModelo = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/modelo/eliminar', payload)
        return(respuesta)
    } catch (error) {
        return({
            data:{
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}

export const consultaModeloId = async ( payload ) => {
    usuarioAutenticado()
    try {

        const respuesta = await clienteAxios.post('/modelo/consultaId', payload )
        return respuesta

    } catch ( error ) {
        return({
            data:{
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema al consultar"
            }
        })
    }
}