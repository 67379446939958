import React, {useState, Fragment} from 'react';
import Mensaje from '../alertas/alertas.camposObligatorios';
import { EVENTO_ACTUALIZAR } from '../../enum/eventos/tipo-evento';
import { Form, Button, Grid, Container } from 'semantic-ui-react';

const FormularioVehiculos = ({
    datosVehiculos,
    evento,
    guardar,
    actualizar,
    setDatosVehiculos,
    setModalEliminar,
    setObjetoEliminar,
}) => {


    const [ mensaje, setMensaje ] = useState(false)
    

    const onChangeModelo = e => {
        setDatosVehiculos({
            ...datosVehiculos,
            modelo: {
                ...datosVehiculos.modelo,
                [e.target.name] : e.target.value
            }
            
        })
    }
    const onChangeDatos = e => {
        setDatosVehiculos({
            ...datosVehiculos,
            [e.target.name] : e.target.value
        })
    }


    const guardarVehiculo = () => {
        if(
            datosVehiculos.vin.trim() === ''||
            datosVehiculos.version.trim === ''||
            datosVehiculos.placa.trim === ''||
            datosVehiculos.descripcionVersion === ''||
            datosVehiculos.modelo.nombre.trim() === ''||
            datosVehiculos.modelo.codigo.trim() === ''||
            datosVehiculos.modelo.origen.trim() === ''||
            datosVehiculos.modelo.color.trim() === ''||
            datosVehiculos.modelo.anoModelo.trim() === ''
        ){
            setMensaje(true)
            return
        } else {
            setMensaje(false)
            guardar()
        }

    }

    const actualizarVehiculo = () => {
        if(
            datosVehiculos.vin.trim() === ''||
            datosVehiculos.version.trim === ''||
            datosVehiculos.placa.trim === ''||
            datosVehiculos.descripcionVersion === ''||
            datosVehiculos.modelo.nombre.trim() === ''||
            datosVehiculos.modelo.codigo.trim() === ''||
            datosVehiculos.modelo.origen.trim() === ''||
            datosVehiculos.modelo.color.trim() === ''||
            datosVehiculos.modelo.anoModelo.trim() === ''
        ){
            setMensaje(true)
            return
        } else {
            setMensaje(false)
            actualizar()
        }

    }

    const botonEliminar = () => {
        setObjetoEliminar(datosVehiculos._id)
        setModalEliminar({
            activo: true
        })
    }
    return ( 
        <Grid style={{marginTop: 10}}>
            <Container fluid>
                <Form>
                    <h3>Datos</h3>  
                    <Form.Group widths="equal">
                        <Form.Input
                            label="Vin"
                            name="vin"
                            value={datosVehiculos.vin}
                            onChange={onChangeDatos}
                        />
                        <Form.Input
                            label="Versión"
                            name="version"
                            value={datosVehiculos.version}
                            onChange={onChangeDatos}
                        />
                        <Form.Input
                            label="Placa"
                            name="placa"
                            value={datosVehiculos.placa}
                            onChange={onChangeDatos}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Input
                            label="Descripción De Versión"
                            name="descripcionVersion"
                            value={datosVehiculos.descripcionVersion}
                            onChange={onChangeDatos}
                        />
                    </Form.Group>
                    <h3>Modelo</h3>
                    <Form.Group widths="equal">
                        <Form.Input
                            label="Nombre"
                            name="nombre"
                            value={datosVehiculos.modelo.nombre}
                            onChange={onChangeModelo}
                        />
                        <Form.Input
                            label="Codigo"
                            name="codigo"
                            value={datosVehiculos.modelo.codigo}
                            onChange={onChangeModelo}
                        />
                        <Form.Input
                            label="Origen"
                            name="origen"
                            value={datosVehiculos.modelo.origen}
                            onChange={onChangeModelo}
                        />
                        <Form.Input
                            label="Color"
                            name="color"
                            value={datosVehiculos.modelo.color}
                            onChange={onChangeModelo}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Input
                            label="Año Del Modelo"
                            name="anoModelo"
                            value={datosVehiculos.modelo.anoModelo}
                            onChange={onChangeModelo}
                        />
                    </Form.Group>
                    {mensaje ? <Mensaje/> : null}
                    {evento === EVENTO_ACTUALIZAR ?
                    <Fragment>
                    <Button 
                        style={{
                            padding: 20,
                            borderRadius: 0, 
                            backgroundColor:'#000000',
                            borderColor: '#000000',
                            borderStyle:'solid',
                            borderWidth:'1px',
                            fontWeight: 'bold',
                            color:'#FFFFFF'
                        }}
                        primary
                        size="tiny" 
                        content="Actualizar"
                        onClick={actualizarVehiculo}
                    />
                    <Button 
                        style={{
                            padding: 20,
                            borderRadius: 0, 
                            backgroundColor:'#FFFFFF',
                            borderColor: '#000000',
                            borderStyle:'solid',
                            borderWidth:'1px',
                            fontWeight: 'bold',
                            color:'#000000'
                        }}
                        size="tiny" 
                        content="Eliminar"
                        onClick={botonEliminar}
                        icon="trash"
                    />
                    </Fragment>
                    :
                    <Button
                        style={{
                            padding: 20,
                            borderRadius: 0, 
                            backgroundColor:'#000000',
                            borderColor: '#000000',
                            borderStyle:'solid',
                            borderWidth:'1px',
                            fontWeight: 'bold',
                            color:'#FFFFFF'
                        }}
                        primary
                        size="tiny" 
                        content="Guardar" 
                        onClick={guardarVehiculo}
                    />
                    }
                </Form>
                </Container>
        </Grid>
     );
}
 
export default FormularioVehiculos;