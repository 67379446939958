import React, {useState, useEffect, Fragment} from 'react'
import {Grid, Header, Container, Button, Table, Pagination, Modal, Loader} from 'semantic-ui-react'
import { EVENTO_CREAR } from '../../enum/eventos/tipo-evento'
import { consultarTemparios, crearTemparios, eliminarTemparios, actualizarTemparios } from '../../servicios/temparios/temparios.srv';
import {estadoInicialFiltro} from '../../estadosIniciales/tempario/estadoInicial-tempario';
import FormularioTemparios from '../../components/formularios/formulario.temparios';
import ItemTempario from '../../components/itemsListados/item-tempario';
import ModalMensaje from '../../components/modales/modalMensaje';
import ModalEliminar from '../../components/modales/modalEliminar';
import FiltroTempario from '../../components/filtros/filtro.tempario';
import Preloader from '../../components/utilidades/preloader';
import NoItems from '../../components/utilidades/noItems';


const TemparioNormal = () => {

    const modalEliminarContenido = {
        titulo: 'Eliminar Tempario',
        mensaje: '¿Esta seguro que quiere eliminar este tempario?'
    }
    const [ modalEliminar, setModalEliminar ] = useState(false)
    const [ temparioEliminar, setTemparioEliminar ] = useState('')
    const [ modalMensajeEstatus, setModalMensajeEstatus ] = useState({
        activo: false,
        mensaje: ''
    })
    const [ evento, setEvento ] = useState('')
    const [ cargandoLoader, setCargandoLoader ] = useState(true)
    const [ cargando, setCargando ] = useState(false)
    const [ modalActivo, setModalActivo] = useState(false)
    const [ temparios, setTemparios] = useState([])
    const [ tempario, setTempario] = useState({})

    
    //PETICION A LA LISTA   
    const [ filtro, setFiltro ] = useState(Object.assign({}, estadoInicialFiltro))
    
    const [ paginacion, setPaginacion ] = useState({
        paginasTotales: 1,
        paginaSeleccionada: 1
    })

    const onChangePaginador = (e, {activePage}) => {
        setPaginacion({
            ...paginacion,
            paginaSeleccionada:activePage
        })
        setFiltro({
            ...filtro,
            salto: (activePage - 1) * 10 
        })
    }
    const consultar = async(filtro) => {
        const respuesta = await consultarTemparios(filtro)
        if(respuesta.data.estatus){
            setTemparios(respuesta.data.data.temparios)
            setPaginacion({
                ...paginacion,
                paginasTotales: Math.ceil(respuesta.data.data.total / 10)
            })
            setCargandoLoader(false)
        } else  {
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
            setCargandoLoader(false)
        }

    }
    useEffect(() => {

        const consultar = async(filtro) => {
            const respuesta = await consultarTemparios(filtro)
            if(respuesta.data.estatus){
                setTemparios(respuesta.data.data.temparios)
                this.setPaginacion({
                    ...paginacion,
                    paginasTotales: Math.ceil(respuesta.data.data.total / 10)
                })
                setCargandoLoader(false)
            } else  {
                this.setModalMensajeEstatus({
                    ...modalMensajeEstatus,
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion
                })
                setCargandoLoader(false)
            }
    
        }

        if(filtro){
            consultar(filtro)
        }
    }, [filtro])
    //CRUD
    //CREAR
    const CrearTempario = async(payload) => {
        const respuesta = await crearTemparios(payload)
        if(!respuesta.data.estatus){
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
            consultar(filtro)
        } else {
            setModalActivo(false)
            setEvento('')
            consultar(filtro)
        }
    }
    //ACTUALIZAR
    const ActualizarTempario = async(payload) => {
        const respuesta = await actualizarTemparios(payload)
        if(!respuesta.data.estatus){
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
            consultar(filtro)
        } else {
            setModalActivo(false)
            setEvento('')
            consultar(filtro)
        }
    }
    //ELIMINAR
    const EliminarTempario = async(id) => {
        const respuesta = await eliminarTemparios({id})
        if(respuesta.data.estatus){
            setTimeout(() => {
                setModalMensajeEstatus({
                    ...modalMensajeEstatus,
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion
                })
                setCargando(false)
            }, 500);
            consultar(filtro)
        } else{
            setTimeout(() => {
                setModalMensajeEstatus({
                    ...modalMensajeEstatus,
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion
                })
                setCargando(false)
            }, 500);
            consultar(filtro)
        }
    }

    if(cargandoLoader){
        return <Preloader/>
    }

    return ( 
        <Grid style={{marginTop:100, marginBottom:50, marginLeft:50, marginRight:50, minHeight:'70vh'}}>
        <Container fluid>
                <Header 
                    size="huge" 
                    as="h1" 
                    style={{ 
                        fontSize:50,
                        spacing:'-0.04em',
                        fontWeight:'400', 
                        fontFamily:'kiaBold', 
                        marginBottom:20, 
                        marginTop:30 }}>
                    Temparios
                    <span>
                    <Button 
                        size="mini"
                        onClick={() => { setEvento(EVENTO_CREAR); setModalActivo(true)}}
                        content="Nuevo tempario"
                        style={{
                            padding: 20,
                            borderRadius: 0, 
                            backgroundColor:'#FFFFFF',
                            borderColor: '#000000',
                            borderStyle:'solid',
                            borderWidth:'1px',
                            fontWeight: 'bold',
                            color:'#000000'
                        }}
                        primary
                        floated='right'
                    />
                    </span>
                </Header>
            <FiltroTempario
                setFiltro={setFiltro}
            />
            <FormularioTemparios
                modalActivo={modalActivo}
                evento={evento}
                tempario={tempario}
                setModalActivo={setModalActivo}
                setEvento={setEvento}
                guardar={(payload) => CrearTempario(payload)}
                actualizar={(payload) => ActualizarTempario(payload)}
            />  
            { temparios !== undefined && temparios.length > 0 ?
            <Fragment>
            <Table celled padded textAlign="center" verticalAlign="top">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>NOMBRE</Table.HeaderCell>
                        <Table.HeaderCell>Cl</Table.HeaderCell>
                        <Table.HeaderCell>MODELO</Table.HeaderCell>
                        <Table.HeaderCell>INSUMOS</Table.HeaderCell>
                        <Table.HeaderCell>MANO DE OBRA</Table.HeaderCell>
                        <Table.HeaderCell>RUTINA</Table.HeaderCell>
                        <Table.HeaderCell>HORAS</Table.HeaderCell>
                        <Table.HeaderCell>ACCIONES</Table.HeaderCell>
                    </Table.Row>
                    
                </Table.Header>
                <Table.Body>
                    {temparios.map(i => (
                        <ItemTempario
                            setModalEliminar={setModalEliminar}
                            setObjetoEliminar={setTemparioEliminar}
                            setModalActivo={setModalActivo}
                            setEvento={setEvento}
                            setTempario={setTempario}
                            item={i}
                            key={i._id}
                        />
                    ))}
                </Table.Body> 
            </Table>
            <Pagination 
                ellipsisItem={null}
                firstItem={null}
                lastItem={null}
                totalPages={paginacion.paginasTotales}
                activePage={paginacion.paginaSeleccionada}
                onPageChange={onChangePaginador}
            />
            </Fragment>: <NoItems/> }
            <ModalMensaje
                activo={modalMensajeEstatus.activo}
                mensaje={modalMensajeEstatus.mensaje}
                setModalMensajeEstatus={setModalMensajeEstatus}
            />
            <ModalEliminar
                modalEliminarActivo={modalEliminar}
                objetoEliminar={temparioEliminar}
                contenido={modalEliminarContenido}
                eliminar={(value) => EliminarTempario(value)}
                setObjetoEliminar={setTemparioEliminar}
                setModalEliminar={setModalEliminar}
                setCargando={setCargando}
            />
        </Container>
        {
        cargando 
            ? 
        <Modal open>
        <Loader size="large"  active/>
        </Modal>
            : 
        null
        }
        </Grid>
     );
}
 
export default TemparioNormal;