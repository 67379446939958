import React from 'react';
import { EVENTO_ACTUALIZAR } from '../../enum/eventos/tipo-evento'
import { Table, Checkbox, Button } from 'semantic-ui-react';

const ItemConcesionario = ({
    concesiones,
    setModalActivo,
    setObjetoEliminar,
    setModalEliminar,
    setEvento,
    setConcesion
}) => {
    const datosInicialConcesion = {
        _id: concesiones._id,
        cl: concesiones.cl,
        nombre: concesiones.nombre,
        abreviatura: concesiones.abreviatura,
        grupo: concesiones.grupo,
        tipo: concesiones.tipo,
        email: concesiones.email,
        manoObra: {
            ordinaria: concesiones.manoObra.ordinaria,
            tmog: concesiones.manoObra.tmog
        },
        activo: concesiones.activo,
        direccion: {
            callePrincipal: concesiones.direccion.callePrincipal,
            calleSecundaria: concesiones.direccion.calleSecundaria,
            numero: concesiones.direccion.numero,
            otros: concesiones.direccion.otros,
            barrio: concesiones.direccion.barrio,
            ciudad: concesiones.direccion.ciudad,
        },
        telefonos: {
            indicativoCiudad: concesiones.telefonos.indicativoCiudad,
            numero: concesiones.telefonos.numero,
        }
    }

    
    const botonActualizar = () => {
        setModalActivo(true)
        setConcesion(datosInicialConcesion)
        setEvento(EVENTO_ACTUALIZAR)
    }

    const botonEliminar = (id) => {
        setObjetoEliminar(id)
        setModalEliminar({
            activo: true
        }) 
    }

    return ( 
        <Table.Row>
            <Table.Cell>{concesiones.cl}</Table.Cell>
            <Table.Cell>{concesiones.nombre}</Table.Cell>
            <Table.Cell>{concesiones.abreviatura}</Table.Cell>
            <Table.Cell>
                {concesiones.direccion.callePrincipal} {''}
                {concesiones.direccion.calleSecundaria} {''}
                {concesiones.direccion.numero} {''}
                {concesiones.direccion.otros}
            </Table.Cell>
            <Table.Cell>{concesiones.direccion.ciudad}</Table.Cell>
            <Table.Cell>{concesiones.grupo}</Table.Cell>
            <Table.Cell>
                <Checkbox
                    toggle
                    disabled
                    checked={concesiones.activo}
                />
            </Table.Cell>
            <Table.Cell textAlign="center">
                <Button
                    content="EDITAR"
                    icon="edit"
                    style={{backgroundColor:'#BB162B', color:'#F6F6F6', borderRadius:25}}
                    size="tiny"
                    onClick={botonActualizar}
                />
                <Button
                    content="ELIMINAR"
                    icon="trash"
                    style={{borderRadius:25}}
                    size="tiny"
                    onClick={() => botonEliminar(concesiones._id)}
                />
            </Table.Cell>
        </Table.Row>
     );
}
 
export default ItemConcesionario;