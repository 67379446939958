import {clienteAxios} from '../../config/axios';
import tokenAuth from '../../config/tokenAuth';


const usuarioAutenticado = () => {
    const token = localStorage.getItem('token')
    if(token){
        tokenAuth(token)
    }
}

export const crearUsuario = async (payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/usuario/crear', payload)
        return respuesta.data
    } catch ( error ) {
        return {
            estatus: false,
            resultadoOperacion: "Ocurrio un problema"
        }
    }
    

}

export const eliminarUsuario = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/usuario/eliminar', payload)
        return respuesta.data
    } catch (error) {
        return {
            estatus: false,
            resultadoOperacion: "Ocurrio un problema"
        }
    }
}

export const actualizarUsuario = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/usuario/actualizar', payload)
        return(respuesta.data)
    } catch ( error ) {
        return {
            estatus: false,
            resultadoOperacion: "Ocurrio un problema"
        }
    }
}

export const consultaUsuario = async (payload) => {
    usuarioAutenticado();
    try {
        const respuesta = await clienteAxios.post('/usuario/consulta', payload)
        return(respuesta.data)
    } catch ( error ) {
        return {
            estatus: false,
            resultadoOperacion: "Ocurrio un problema"
        }
    }

}


