import React, { useState } from "react";
import { Table, Button } from "semantic-ui-react";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { TIPO_ROL } from "../../enum/tipo-rol";
import { eliminarSolicitudServicio } from "../../servicios/solicitudAdquisiciones/solicitud.srv";
import ModalEliminar from "../modales/modalEliminar";

const ItemSolicitud = ({ item, onEliminarSolicitud }) => {
  const rol = localStorage.getItem("rol");

  const [modalEliminarActivo, setModalEliminarActivo] = useState(false);
  const [cargando, setCargando] = useState(false);

  const abrirModalEliminar = () => {
    setModalEliminarActivo(true);
  };

  const eliminarSolicitud = async () => {
    setCargando(true);
    const respuesta = await eliminarSolicitudServicio({ id: item._id });
    if (respuesta.data.estatus) {
      onEliminarSolicitud(item._id);
      setCargando(false);
      setModalEliminarActivo(false);
    } else {
      setCargando(false);
    }
  };

  return (
    <>
      <Table.Row>
        <Table.Cell>
          {moment(item.creado).format("DD/MM/YYYY hh:mm a")}
        </Table.Cell>
        <Table.Cell>
          {moment(item.actualizado).format("DD/MM/YYYY hh:mm a")}
        </Table.Cell>
        <Table.Cell>{item.solicitud.numero}</Table.Cell>
        <Table.Cell>{item.solicitud.concesionario}</Table.Cell>
        <Table.Cell>{item.solicitud.vin}</Table.Cell>
        <Table.Cell>{item.detallesCliente.identificacion.tipo}</Table.Cell>
        <Table.Cell>{item.detallesCliente.identificacion.numero}</Table.Cell>
        <Table.Cell>{item.estado}</Table.Cell>
        <Table.Cell>
          {rol === TIPO_ROL.ADMINISTRADOR_SISTEMA ? (
            <>
              <Button
                style={{
                  padding: 10,
                  borderRadius: 0,
                  backgroundColor: "#FFFFFF",
                  borderColor: "#000000",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  fontWeight: "bold",
                  color: "#000000",
                }}
                size="tiny"
              >
                <Link
                  style={{ textDecoration: "none", color: "#000000" }}
                  to={`/detallesSolicitud/${item._id}`}
                  target="_blank"
                >
                  Ver
                </Link>
              </Button>
              <Button
                style={{
                  padding: 10,
                  borderRadius: 0,
                  backgroundColor: "#FFFFFF",
                  borderColor: "#000000",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  fontWeight: "bold",
                  color: "#000000",
                }}
                size="tiny"
                onClick={abrirModalEliminar}
              >
                Eliminar Solicitud
              </Button>
            </>
          ) : (
            <Button
              style={{
                padding: 10,
                borderRadius: 0,
                backgroundColor: "#FFFFFF",
                borderColor: "#000000",
                borderStyle: "solid",
                borderWidth: "1px",
                fontWeight: "bold",
                color: "#000000",
              }}
              size="tiny"
            >
              <Link
                style={{ textDecoration: "none", color: "#000000" }}
                to={`/detallesSolicitud/${item._id}`}
                target="_blank"
              >
                Ver
              </Link>
            </Button>
          )}
        </Table.Cell>
      </Table.Row>

      <ModalEliminar
        setCargando={setCargando}
        setModalEliminar={setModalEliminarActivo}
        modalEliminarActivo={modalEliminarActivo}
        eliminarPaquete={eliminarSolicitud}
        contenido={{
          titulo: "Confirmar Eliminación",
          mensaje: "¿Estás seguro de que deseas eliminar esta solicitud?",
        }}
      />
    </>
  );
};

export default ItemSolicitud;
