import React, {useState} from 'react';
import { Grid, Segment, Form, Button, Header } from 'semantic-ui-react'
import { consultaSolicitudRedencion } from '../../servicios/solicitudAdquisiciones/solicitud.srv';
import ModalRedencion from '../../components/modales/modalRedencion';


const RedimirIC = () => {

    const [ modalMensajeEstatus, setModalMensajeEstatus ] = useState({
        activo: false,
        mensaje: '',
        servicios: false,
        vehiculo: {}
    })

    const [ datos, setDatos ] = useState({
        vin: '',
        kilometraje: ''
    })

    const onChangeDatos = e => {
        setDatos({
            ...datos,
            [e.target.name]:e.target.value
        })
    }

    const consultarRedencion = async() => {
        setModalMensajeEstatus({
            activo: false,
            mensaje: '',
            servicios: false,
            vehiculo: {}
        })

        if (!datos.vin) {
            setModalMensajeEstatus({
                activo: true,
                mensaje: 'Por favor completa el VIN o la placa del vehículo para continuar.',
                servicios: false,
                vehiculo: {}
            })
            return;
        }
        if (!datos.kilometraje) {
            setModalMensajeEstatus({
                activo: true,
                mensaje: 'Por favor completa el kilometraje del vehículo para continuar.',
                servicios: false,
                vehiculo: {}
            })
            return;
        }
        try {
            const respuesta = await consultaSolicitudRedencion(datos)
            console.log('Consultar redención: ', respuesta.data );
            if( respuesta.data.estatus ) {
                setModalMensajeEstatus({
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion,
                    servicios: true,
                    vehiculo: respuesta.data.data.vehiculo
                })
            } else {
                setModalMensajeEstatus({
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion,
                    servicios: false,
                    vehiculo: {}
                })
            }
        } catch (error) {
            setModalMensajeEstatus({
                activo: true,
                mensaje: "Lo sentimos, no se pudo consultar la información.",
                servicios: false,
                vehiculo: {}
            })
        }

    }

    return ( 
        <Grid  textAlign='left' centered verticalAlign="middle">
            <Grid.Row width={16} style={{minHeight:"82vh"}}>
                <Grid.Column style={{maxWidth:450}}>
                    <Form>
                        <Segment basic>
                            <Header as="h1">
                                Redención de <br></br>
                                servicios prepagados
                            </Header>
                        <Form.Input
                            label="Kilometraje"
                            name="kilometraje"
                            placeholder="Kilometraje actual"
                            value={datos.kilometraje}
                            onChange={onChangeDatos}
                        />
                        <Form.Input
                            label="Vin ó Placa"
                            name="vin"
                            placeholder="VIN"
                            value={datos.vin}
                            onChange={onChangeDatos}
                        />
                        <Button 
                            style={{
                                padding: 20,
                                borderRadius: 0, 
                                backgroundColor:'#000000',
                                borderColor: '#000000',
                                borderStyle:'solid',
                                borderWidth:'1px',
                                fontWeight: 'bold',
                                color:'#FFFFFF'
                            }}
                            primary
                            size="tiny"
                            content="Buscar"
                            onClick={() => consultarRedencion()}
                        />
                        </Segment>
                    </Form>
                </Grid.Column>
            </Grid.Row>
            <ModalRedencion 
                activo={modalMensajeEstatus.activo}
                mensaje={modalMensajeEstatus.mensaje}
                servicios={modalMensajeEstatus.servicios}
                vehiculo={modalMensajeEstatus.vehiculo}
                setModalMensajeEstatus={setModalMensajeEstatus}
            />
        </Grid>
     );
}
 
export default RedimirIC;