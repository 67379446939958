import React, {useState} from 'react'
import {Modal, Form, Button, Header} from 'semantic-ui-react'
import {estadoInicialSoporte} from '../../estadosIniciales/solicitudes/estadoInicial-solicitud'
import { InputFile } from 'semantic-ui-react-input-file'
import { cargarArchivos } from '../../servicios/cargaArchivos/cargaSoporte.srv';
import { cargarArchivoS3 } from '../../servicios/cargar-s3';
import {soporteLista} from '../../estadosIniciales/listas/soporte-lista';
import moment from 'moment-timezone'


const ModalAgregarSoporte = ({
    actualizar,
    datosSolicitud,
    setDatosSolicitud,
    setModalMensajeEstatus,
    modalAgregarSoporte,
    setModalAgregarSoporte
}) => {

    const [ soporte, setSoporte ] = useState(Object.assign({}, estadoInicialSoporte))
    const [ archivoSeleccionado, setArchivoSeleccionado ] = useState(null)
    const [ cargando, setCargando ] = useState(false)


    const actualizarSolicitud = async() => {
        if(archivoSeleccionado) {
            setCargando(true)
            const formData = new FormData()
            formData.append('codigo-cl', archivoSeleccionado)
            try {
                // const respuesta = await cargarArchivos(formData)
                const respuesta = await cargarArchivoS3( archivoSeleccionado, archivoSeleccionado.name );
                let conjuntoSoporte = datosSolicitud.soportes
                conjuntoSoporte.push({
                    ...soporte,
                    nombre: respuesta.key,
                    url: respuesta.location,
                    extension: respuesta.location,
                    fechaCarga: moment().format()
                })
                setDatosSolicitud({
                    ...datosSolicitud,
                    soportes:conjuntoSoporte
                })
                actualizar(datosSolicitud)

                setCargando(false)
            } catch (error) {
                setCargando(false)
                setModalMensajeEstatus({
                    activo: true,
                    mensaje: 'No se ha podido subir el archivo'
                })
            }
        }
    }
    const subirArchivo = (e) => {
        setArchivoSeleccionado(e.target.files[0])
    }


    const onChagenDatosSoporte = (e, {name, value}) => {
        setSoporte({
            ...soporte,
            [name]:value
        })
    }
    return ( 
        <Modal
            open={modalAgregarSoporte}
            style={{ borderRadius: 0, paddingTop: 20, paddingBottom: 20 }}
        >
            <Modal.Header 
                style={{ 
                    backgroundColor:'#FFFFFF', 
                    border:'none'}} 
                as="h5">
                <Header>
                Agregar soporte
                </Header>
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Select
                        label="Tipo"
                        name="tipo"
                        value={soporte.tipo}
                        options={soporteLista}
                        onChange={onChagenDatosSoporte}
                    />
                    <Form.TextArea
                        label="Comentarios"
                        name="comentarios"
                        placeholder="Comentarios sobre el soporte."
                        value={soporte.comentarios}
                        onChange={onChagenDatosSoporte} />
                    <InputFile
                        button={{label: archivoSeleccionado?.name? archivoSeleccionado.name:'Seleccionar archivo' }}
                        input={{
                            id:'input-upload-id1',
                            onChange: subirArchivo
                        }}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions style={{ backgroundColor:'#FFFFFF', border:'none', textAlign:'left'}}>
                    <Button
                        style={{
                            padding: 20,
                            borderRadius: 0, 
                            backgroundColor:'#FFFFFF',
                            borderColor: '#000000',
                            borderStyle:'solid',
                            borderWidth:'1px',
                            fontWeight: 'bold',
                            color:'#000000'
                        }}
                        primary
                        size="tiny" 
                        content="Cancelar"
                        onClick={() => setModalAgregarSoporte(false)}
                    />
                    <Button 
                        style={{
                            padding: 20,
                            borderRadius: 0, 
                            backgroundColor:'#000000',
                            borderColor: '#000000',
                            borderStyle:'solid',
                            borderWidth:'1px',
                            fontWeight: 'bold',
                            color:'#FFFFFF'
                        }}
                        primary
                        size="tiny" 
                        loading={cargando}
                        content="Cargar soporte"
                        onClick={actualizarSolicitud}
                    />
            </Modal.Actions>
        </Modal>
     );

}
 
export default ModalAgregarSoporte;