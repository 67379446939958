import React from 'react'
import {Menu, Container} from 'semantic-ui-react';
import { 
    VISTA_ACTIVA_ACTIVACION,
    VISTA_ACTIVA_FORMULARIO, 
    VISTA_ACTIVA_PAQUETES, 
    VISTA_ACTIVA_REDENCION,
    VISTA_ACTIVA_SOPORTES,
} from '../../enum/eventos/tipo-evento'

const NavBarSolicitud = ({
    vistaActiva,
    setVistaActiva
}) => {

    return ( 
        <Menu pointing secondary style={{marginTop:25, marginBottom:30, minHeight:'30px !important', height:45, fontFamily:'KiaBold', fontSize:15}}>
            <Container>
                <Menu.Item
                    active={vistaActiva !== VISTA_ACTIVA_PAQUETES && vistaActiva !== VISTA_ACTIVA_ACTIVACION && vistaActiva !== VISTA_ACTIVA_REDENCION && vistaActiva !== VISTA_ACTIVA_SOPORTES} 
                    name="Datos de la solicitud"
                    onClick={() => setVistaActiva(VISTA_ACTIVA_FORMULARIO)}
                />
                <Menu.Item
                    name="Paquetes"
                    active={vistaActiva === VISTA_ACTIVA_PAQUETES}
                    onClick={() => setVistaActiva(VISTA_ACTIVA_PAQUETES)}
                />
                <Menu.Item
                    name="Soportes"
                    active={vistaActiva === VISTA_ACTIVA_SOPORTES}
                    onClick={() => setVistaActiva(VISTA_ACTIVA_SOPORTES)}
                />
                <Menu.Item
                    name="Activación"
                    active={vistaActiva === VISTA_ACTIVA_ACTIVACION}
                    onClick={() => setVistaActiva(VISTA_ACTIVA_ACTIVACION)}
                />
                <Menu.Item
                    name="Redención"
                    active={vistaActiva === VISTA_ACTIVA_REDENCION}
                    onClick={() => setVistaActiva(VISTA_ACTIVA_REDENCION)}
                />
            </Container>
        </Menu>
     );
}
 
export default NavBarSolicitud;