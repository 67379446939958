import {clienteAxios} from '../../config/axios';
import tokenAuth from '../../config/tokenAuth';

const usuarioAutenticado = () => {
    const token = localStorage.getItem('token')
    if(token){
        tokenAuth(token)
    }
}

export const crearCliente = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/clientes/crear', payload)
        return(respuesta)
    } catch (error) {
        return({
            data:{
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}

export const eliminarCliente = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/clientes/eliminar', payload)
        return(respuesta)
    } catch (error) {
        return({
            data:{
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}

export const actualizarCliente = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/clientes/actualizar', payload)
        return(respuesta)
    } catch (error) {
        return({
            data:{
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}

export const consultaCliente = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/clientes/consulta', payload)
        return(respuesta)
    } catch (error) {
        return({
            data:{
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}

export const consultaIdCliente = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/clientes/consultaId', payload)
        return(respuesta)
    } catch (error) {
        return({
            data:{
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}